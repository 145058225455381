import { action, computed, makeObservable, observable } from "mobx";
import Group from "./Group";
import Storage from "./Storage";

class Groups {
  protected map: Map<string, Group>;
  public loading: boolean;

  public constructor(public storage: Storage) {
    this.map = new Map<string, Group>();
    this.loading = false;

    makeObservable<this, "map">(this, {
      map: observable,
      loading: observable,
      items: computed,
      names: computed,
      add: action.bound,
      set: action.bound,
      delete: action.bound,
    });
  }

  public get items(): Group[] {
    return Array.from(this.map.values()).sort((a, b) => a.name.localeCompare(b.name));
  }

  public get names(): string[] {
    return this.items.map((group) => group.name).sort();
  }

  public add(group: Group): void {
    this.map.set(group.name, group);
  }

  public set(groups: Group[]) {
    this.map = new Map(groups.map((group) => [group.name, group]));
  }

  public get(groupName: string): Group | undefined {
    return this.map.get(groupName);
  }

  public delete(group: Group): void {
    this.map.delete(group.name);
  }
}

export default Groups;
