import { observer } from "mobx-react";
import React, { useState } from "react";
import Content from "../components/Content";
import { ContentBrowserView } from "../components/ContentBrowserViewToggle";
import Header from "../components/ContentHeader";
import ErrorMessage from "../components/ErrorMessage";
import GroupListContent from "../components/GroupListContent";
import GroupListMenu from "../components/GroupListMenu";
import GroupPanel from "../components/GroupPanel";
import MenuPlaceholder from "../components/MenuPlaceholder";
import Search from "../components/Search";
import ServerNotFound from "../components/ServerNotFound";
import useContextMenu from "../hooks/useContextMenu";
import usePreference from "../hooks/usePreference";
import useQuery from "../hooks/useQuery";
import useServerParam from "../hooks/useServerParam";
import useStorageList from "../hooks/useStorageList";
import { Commands } from "../state/commands/Provider";
import { IGetGroupsCommand } from "../state/commands/types/GetGroupsCommand";
import Group from "../state/Group";
import { Preferences } from "../state/Preferences";

export interface GroupListPageProps {}

const GroupListPage: React.FC<GroupListPageProps> = () => {
  const serverName = useServerParam();
  const storageList = useStorageList();
  const storage = storageList.find(serverName);

  const [menu, onContextMenu] = useContextMenu(storage ? GroupListMenu : MenuPlaceholder, {
    props: { groups: storage?.groups },
  });

  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [rightPanelOpened] = usePreference<boolean>(Preferences.RightPanelOpened, true);
  const [view] = usePreference<ContentBrowserView>(Preferences.ContentView, ContentBrowserView.Grid);

  async function fetchGroups() {
    const command = storage?.commands.get<IGetGroupsCommand>(Commands.GetGroups);
    if (await command?.allowed()) {
      return await command!.execute({ groups: storage!.groups });
    }
  }

  const { error } = useQuery(fetchGroups, {
    keys: [storage],
  });

  return (
    <>
      <Header>
        <Search disabled />
      </Header>

      <Content column={selectedGroup && rightPanelOpened ? "" : "2 / 4"} onContextMenu={onContextMenu}>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {storage ? (
          <GroupListContent
            groups={storage.groups}
            view={view}
            selectedGroup={selectedGroup}
            onSelect={setSelectedGroup}
          />
        ) : (
          <ServerNotFound serverName={serverName} />
        )}
      </Content>

      {selectedGroup && <GroupPanel group={selectedGroup} />}
      {menu}
    </>
  );
};

export default observer(GroupListPage);
