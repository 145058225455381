import { observer } from "mobx-react";
import React, { useMemo } from "react";
import styled from "styled-components";
import useContextMenu from "../hooks/useContextMenu";
import useSelectionReset from "../hooks/useSelectionReset";
import useUserTable from "../hooks/useUserTable";
import Group from "../state/Group";
import User from "../state/User";
import GroupMenu from "./GroupMenu";
import GroupUserMenu from "./GroupUserMenu";
import TableColumnResizing from "./TableColumnResizing";
import { TableDiv, TableDivBody, TableDivHead, TableDivRow } from "./TableDiv";
import TableSorter from "./TableSorter";
import TableSorterHeader from "./TableSorterHeader";
import UserTableCell from "./UserTableCell";
import VirtualizedTable, { VirtualizedTableRowComponentProps } from "./VirtualizedTable";

export interface GroupUserTableProps {
  group: Group;
  selectedUser?: User | null;
  onSelect?(group: User | null): void;
}

interface GroupUserTableExtraCellProps {}

interface GroupUserTableRowExtraProps {
  group: Group;
}

const GroupUserTable: React.FC<GroupUserTableProps> = ({ group, selectedUser, onSelect }) => {
  const rows = group.users;
  const selectedRows = useMemo(() => (selectedUser ? [selectedUser] : []), [selectedUser]);
  const [menu, onContextMenu] = useContextMenu(GroupMenu, { props: { group } });

  const rowProps: GroupUserTableRowExtraProps = useMemo(
    () => ({
      group,
    }),
    [group]
  );

  const resetSelection = useSelectionReset(onSelect);
  const { columns, sorting, direction, sortRows, onHeaderSelect } = useUserTable();
  return (
    <TableSorter<User> rows={rows} sorting={sorting} direction={direction} sort={sortRows}>
      {({ rows }) => (
        <TableColumnResizing columns={columns} headerComponent={GroupUserHeader}>
          {({ columns, headerComponent }) => (
            <>
              <VirtualizedTable<User, GroupUserTableExtraCellProps, GroupUserTableRowExtraProps>
                columns={columns}
                rows={rows}
                selectedRows={selectedRows}
                rowHeight={35}
                tableComponent={TableDiv}
                bodyComponent={TableDivBody}
                headComponent={TableDivHead}
                headTableComponent={TableDiv}
                rowComponent={GroupUserRow}
                rowProps={rowProps}
                headerComponent={headerComponent}
                cellComponent={UserTableCell}
                emptyRows={0}
                onSelect={onSelect}
                onClick={resetSelection}
                onContextMenu={onContextMenu}
                onHeaderSelect={onHeaderSelect}
              />
              {menu}
            </>
          )}
        </TableColumnResizing>
      )}
    </TableSorter>
  );
};

const GroupUserHeader = styled(TableSorterHeader)`
  padding-left: ${({ column }) => (column.name === "name" ? "35px" : "10px")};
`;

let GroupUserRow: React.FC<VirtualizedTableRowComponentProps<User> & GroupUserTableRowExtraProps> = ({
  row: user,
  group,
  children,
  ...props
}) => {
  const [menu, onContextMenu] = useContextMenu(GroupUserMenu, { props: { group, user } });
  return (
    <StyledGroupUserRow {...props} row={user} onContextMenu={onContextMenu} highlighted={Boolean(menu)}>
      {children}
      {menu}
    </StyledGroupUserRow>
  );
};
GroupUserRow = observer(GroupUserRow);

const StyledGroupUserRow = styled(TableDivRow)`
  svg {
    fill: ${({ theme, selected }) => (selected ? theme.colors.selectedItemForeground : theme.colors.foreground)};
    flex: 0 0 auto;
  }
`;

export default observer(GroupUserTable);
