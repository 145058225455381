import * as React from "react";
import styled, { css } from "styled-components";
import { createMeasurer, measureCellTextHeight } from "./GridCanvasMeasurer";
import Picture, { PictureProps, PictureSource } from "./Picture";
import { DefaultTheme, ThemedComponentProps } from "./Theme";

interface GridCellProps {
  name: string;
  thumbnail?: PictureSource;
  fallbackImages?: Array<PictureSource | undefined>;
  bordered: boolean;
  selected: boolean;
  highlighted: boolean;
  cellHeight: number;
  actualHeight: number;
  scaling?: boolean;
  className?: string;
  children?: React.ReactNode;
}

function GridCell(
  {
    bordered,
    thumbnail,
    fallbackImages,
    name,
    cellHeight,
    actualHeight,
    selected,
    highlighted,
    scaling = false,
    className,
    children,
    ...props
  }: GridCellProps,
  ref: any
) {
  return (
    <GridCellContainer
      {...props}
      aria-selected={selected}
      ref={ref}
      className={className}
      cellHeight={actualHeight}
      selected={selected}
      highlighted={highlighted}
    >
      <GridCellBorder visible={bordered} cellHeight={actualHeight}>
        <GridCellImageContainer cellHeight={cellHeight}>
          <GridCellImage src={thumbnail} scaling={scaling} fallbacks={fallbackImages} selected={selected} />
          {children}
        </GridCellImageContainer>
        <GridItemName bordered={bordered} name={name} />
      </GridCellBorder>
    </GridCellContainer>
  );
}

export const GridCellAligner = styled.div<{ cellWidth: number }>`
  flex: 0 0 ${({ cellWidth }) => cellWidth}px;
  width: ${({ cellWidth }) => cellWidth}px;
`;

const GridCellContainer = styled.div<{
  cellHeight: number;
  selected: boolean;
  highlighted: boolean;
}>`
  position: relative;
  border-radius: 5px;
  box-sizing: border-box;
  background: ${({ theme, selected, highlighted }) =>
    selected ? theme.colors.selectedItemBackground : highlighted ? theme.colors.hoverItemBackground : "inherit"};

  color: ${({ theme, selected }) => (selected ? theme.colors.selectedItemForeground : theme.colors.foreground)};

  &:hover {
    background: ${({ theme, selected }) =>
      selected ? theme.colors.selectedItemBackground : theme.colors.hoverItemBackground};
  }
`;
GridCellContainer.defaultProps = {
  theme: DefaultTheme,
};

export const GridCellBorderThickness = 1;
const GridCellBorder = styled.div<{
  cellHeight: number;
  visible: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  text-decoration: none;
  cursor: default;
  border: ${GridCellBorderThickness}px solid ${({ theme, visible }) => (visible ? theme.colors.border : "transparent")};
  border-radius: 5px;
  box-sizing: border-box;
`;
GridCellBorder.defaultProps = {
  theme: DefaultTheme,
};

const GridCellImageContainer = styled.div<{
  cellHeight: number;
}>`
  box-sizing: border-box;
  width: auto;
  min-height: ${({ cellHeight }) => cellHeight - GridCellBorderThickness * 2}px;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  user-select: none;
`;

const GridCellImage = styled(
  ({
    scaling,
    selected,
    theme,
    ...props
  }: ThemedComponentProps & PictureProps & { scaling: boolean; selected: boolean }) => <Picture {...props} />
).attrs({
  draggable: false,
})`
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  ${({ scaling }) =>
    scaling &&
    css`
      width: 100%;
      height: 100%;
    `};

  fill: ${({ theme, selected }) => (selected ? theme.colors.selectedItemForeground : theme.colors.foreground)};
`;
GridCellImage.defaultProps = {
  theme: DefaultTheme,
};

interface GridCellNameProps {
  bordered: boolean;
  name: string;
}

export const GridCellNameHeight = 30;
export const GridCellNamePadding = 5;
export const GridCellNameLineHeight = 1.5;
export const GridCellNameFontSize = 13;

const GridItemName: React.FC<GridCellNameProps> = ({ name, ...props }) => {
  return (
    <StyledGridItemName name={name} title={name} {...props}>
      {name}
    </StyledGridItemName>
  );
};

const StyledGridItemName = styled.div<GridCellNameProps>`
  font-size: ${GridCellNameFontSize}px;
  word-break: break-all;
  flex: 1 0 ${GridCellNameHeight}px;
  line-height: ${GridCellNameLineHeight};
  padding: ${GridCellNamePadding}px;
  box-sizing: border-box;
  border-top: ${GridCellBorderThickness}px solid
    ${({ theme, bordered }) => (bordered ? theme.colors.border : "transparent")};
`;
StyledGridItemName.defaultProps = {
  theme: DefaultTheme,
};

const measurer = process.env.NODE_ENV === "test" ? null : createMeasurer();

export interface GridCellMeasureOptions {
  width: number;
  height: number;
  nameHeight?: number;
  paddingTop?: number;
  paddingRight?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  namePaddingTop?: number;
  namePaddingBottom?: number;
  namePaddingLeft?: number;
  namePaddingRight?: number;
  borderThicknessTop?: number;
  borderThicknessBottom?: number;
  borderThicknessLeft?: number;
  borderThicknessRight?: number;
  borderThicknessNameTop?: number;
  borderThicknessNameBottom?: number;
  lineHeight?: number;
  fontSize?: number;
}

export function measureGridCellHeight(
  name: string,
  {
    width,
    height,
    paddingTop = 0,
    paddingBottom = 0,
    paddingLeft = 0,
    paddingRight = 0,
    nameHeight = GridCellNameHeight,
    namePaddingTop = GridCellNamePadding,
    namePaddingBottom = GridCellNamePadding,
    namePaddingLeft = GridCellNamePadding,
    namePaddingRight = GridCellNamePadding,
    borderThicknessTop = GridCellBorderThickness,
    borderThicknessBottom = GridCellBorderThickness,
    borderThicknessLeft = GridCellBorderThickness,
    borderThicknessRight = GridCellBorderThickness,
    borderThicknessNameTop = 0,
    borderThicknessNameBottom = 0,
    lineHeight = GridCellNameLineHeight,
    fontSize = GridCellNameFontSize,
  }: GridCellMeasureOptions
) {
  if (!measurer) {
    return height;
  }

  const maxNameWidth =
    width -
    paddingLeft -
    paddingRight -
    borderThicknessLeft -
    borderThicknessRight -
    namePaddingLeft -
    namePaddingRight;

  const maxNameHeight =
    nameHeight - namePaddingTop - namePaddingBottom - borderThicknessNameTop - borderThicknessNameBottom;
  const calculatedNameHeight = measureCellTextHeight(measurer, maxNameHeight, name, maxNameWidth, lineHeight, fontSize);

  const calculatedHeight =
    paddingTop +
    height +
    paddingBottom +
    calculatedNameHeight +
    borderThicknessNameTop +
    borderThicknessNameBottom +
    namePaddingTop +
    namePaddingBottom;

  return Math.max(height, calculatedHeight);
}

export default React.memo(React.forwardRef(GridCell));
