export default class BusyContext {
  private runningTasks: number = 0;
  public get busy(): boolean {
    return this.runningTasks > 0;
  }

  public async run<T extends any>(fn: (...args: any[]) => Promise<T>) {
    this.runningTasks += 1;
    try {
      return await fn();
    } finally {
      this.runningTasks -= 1;
    }
  }
}
