import { observer, useLocalObservable } from "mobx-react";
import React, { useEffect, useMemo, useRef } from "react";
import useDialog from "../hooks/useDialog";
import useGridSelectionFocus from "../hooks/useGridSelectionFocus";
import useContextMenu from "../hooks/useContextMenu";
import useSelectionReset from "../hooks/useSelectionReset";
import Group from "../state/Group";
import User from "../state/User";
import { ReactComponent as NewUserIcon } from "../static/new-user.svg";
import Grid, { GridCellProps } from "./Grid";
import GridCell, { GridCellAligner } from "./GridCell";
import GroupAddUserDialog from "./GroupAddUserDialog";
import GroupUserMenu from "./GroupUserMenu";
import UserGridCell from "./UserGridCell";

export interface GroupUserGridProps {
  group: Group;
  selectedUser?: User | null;
  canAddUsers?: boolean;
  width?: number;
  height?: number;
  onSelect?(user: User | null): void;
}

type GroupUser = { type: "group-user"; group: Group; user: User };
type NewGroupUser = { type: "new-group-user"; group: Group };
type GroupUserGridItemType = GroupUser | NewGroupUser;

const GroupUserGrid: React.FC<GroupUserGridProps> = ({ group, canAddUsers, selectedUser, width, height, onSelect }) => {
  const grid = useRef<Grid<GroupUserGridItemType, GroupUserGridProps>>(null);
  const state = useLocalObservable(() => {
    return {
      canAddUsers,
      group,
      get cells() {
        const cells: GroupUserGridItemType[] = [];
        if (this.canAddUsers) {
          cells.push({ type: "new-group-user", group: this.group });
        }
        return cells.concat(this.group.users.map((user) => ({ type: "group-user", group: this.group, user })));
      },

      update(canAddUsers: boolean | undefined, group: Group): void {
        this.canAddUsers = canAddUsers;
        this.group = group;
      },
    };
  });
  useEffect(() => {
    state.update(canAddUsers, group);
  }, [state, canAddUsers, group]);

  const selectedUserIndex = useMemo(() => {
    return state.cells.findIndex((cell) => cell.type === "group-user" && cell.user === selectedUser);
  }, [state.cells, selectedUser]);

  useGridSelectionFocus(grid.current, selectedUserIndex);

  const cellProps = useMemo(
    (): GroupUserGridProps => ({
      group,
      selectedUser,
      onSelect,
    }),
    [group, selectedUser, onSelect]
  );

  const resetSelection = useSelectionReset(onSelect);
  return (
    <Grid
      ref={grid}
      cells={state.cells}
      cellWidth={150}
      cellHeight={150}
      cellRenderer={GroupUserGridCell}
      cellProps={cellProps}
      gap={20}
      width={width}
      height={height}
      onClick={resetSelection}
    />
  );
};

const GroupUserGridCell: React.FC<GridCellProps<GroupUserGridItemType, GroupUserGridProps>> = ({ data, ...props }) => {
  if (data.type === "group-user") {
    return <GroupUserCell data={data} {...props} />;
  }
  if (data.type === "new-group-user") {
    return <NewGroupUserCell data={data} {...props} />;
  }
  return null;
};

const GroupUserCell: React.FC<GridCellProps<GroupUser, GroupUserGridProps>> = ({
  group,
  data: { user },
  selectedUser,
  onSelect,
  ...props
}) => {
  const [menu, onContextMenu] = useContextMenu(GroupUserMenu, { props: { group, user } });
  return (
    <UserGridCell
      {...props}
      data={user}
      selectedUser={selectedUser}
      menu={menu}
      onSelect={onSelect}
      onContextMenu={onContextMenu}
    />
  );
};

const NewGroupUserCell: React.FC<GridCellProps<NewGroupUser, GroupUserGridProps>> = ({
  group,
  style,
  width,
  height,
}) => {
  const groupUserDialog = useDialog(GroupAddUserDialog, { group, suggestions: group.storage.users.items });

  function addGroupUser() {
    groupUserDialog.show();
  }

  return (
    <GridCellAligner style={style} cellWidth={width} onClick={addGroupUser}>
      <GridCell
        name={"Add Group User"}
        thumbnail={NewUserIcon}
        bordered={false}
        selected={false}
        highlighted={false}
        cellHeight={150}
        actualHeight={height}
      />
    </GridCellAligner>
  );
};

export default observer(GroupUserGrid);
