import { List2ResponsePathEntry, PathType, StatusType } from "@omniverse/api/data";
import { fromAsyncIter } from "../../../util/Array";
import { isFolder } from "../../../util/Path";
import { NucleusConnectionPool } from "./Nucleus";

export interface ListRecursivelyOptions {
  /**
   * Specifies if InvalidPath errors should be thrown.
   */
  silent?: boolean;
}

export async function* listRecursively(
  connectionPool: NucleusConnectionPool,
  path: string,
  { silent }: ListRecursivelyOptions = {}
): AsyncGenerator<Required<List2ResponsePathEntry>> {
  const connection = await connectionPool.get();
  const results = await connection.list2({ path, show_hidden: true });

  const folders: string[] = [];
  for await (const item of results) {
    if (item.status === StatusType.Done) {
      break;
    }

    if (item.status !== StatusType.OK) {
      if (item.status === StatusType.InvalidPath && silent) {
        break;
      }
      throw new ListEntriesError(`Failed to list ${path} -- ${item.status}`, item);
    }

    for (const path of item.entries!) {
      yield path as Required<List2ResponsePathEntry>;
      if (path.path_type === PathType.Folder || path.path_type === PathType.Mount) {
        folders.push(path.path!);
      }
    }
  }

  for (const folder of folders) {
    yield* listRecursively(connectionPool, folder, { silent });
  }
}

export type CompareResult = {
  source: string[];
  sourceEntries: Set<string>;
  destination: string[];
  destinationEntries: Set<string>;
  intersection: Set<string>;
};

export async function comparePaths(
  connectionPool: NucleusConnectionPool,
  source: string,
  destination: string
): Promise<CompareResult> {
  const resolveSource = isFolder(source)
    ? fromAsyncIter(listRecursively(connectionPool, source, { silent: true })).then((entries) =>
        entries.map((entry) => entry.path)
      )
    : new Promise<string[]>((resolve) => resolve([source]));

  const resolveDestination = isFolder(destination)
    ? fromAsyncIter(listRecursively(connectionPool, destination, { silent: true })).then((entries) =>
        entries.map((entry) => entry.path)
      )
    : new Promise<string[]>((resolve) => resolve([destination]));

  const [sourcePaths, destinationPaths] = await Promise.all([resolveSource, resolveDestination]);

  const sourceEntries = new Set<string>(sourcePaths.map((path) => path.slice(source.length)));
  const destinationEntries = new Set<string>(destinationPaths.map((path) => path.slice(destination.length)));
  const intersection: Set<string> = new Set(
    Array.from(destinationEntries.values()).filter((entry) => sourceEntries.has(entry))
  );

  return {
    source: sourcePaths,
    sourceEntries,
    destination: destinationPaths,
    destinationEntries,
    intersection,
  };
}

class ListEntriesError extends Error {
  public readonly result: { status: StatusType };

  constructor(message: string, result: { status: StatusType }) {
    super();
    this.result = result;
    this.message = message;
  }
}
