import { Connection } from "@omniverse/api";
import { StatusType } from "@omniverse/api/data";
import { getParent, join } from "../../../util/Path";
import { CopyError } from "../../../util/PathErrors";
import Path, { PathType } from "../../Path";
import { Commands } from "../Provider";
import { ICopyCommandAllowedArguments, ICopyCommandArguments } from "../types/CopyCommand";
import { NucleusCommand } from "./index";
import { comparePaths, CompareResult } from "./util";

export default class NucleusCopyCommand extends NucleusCommand<ICopyCommandArguments, ICopyCommandAllowedArguments> {
  name = Commands.Copy;

  public async allowed({ source, destination }: ICopyCommandAllowedArguments): Promise<boolean> {
    const canCopy = source && destination ? !source.includes(destination) : true;
    const canPaste = destination ? destination.canWrite() : true;
    return canCopy && canPaste;
  }

  public async execute({ source, destination }: ICopyCommandArguments): Promise<void> {
    return this.provider.busyContext.run(async () => {
      console.log(`[${this.provider.name}] Starting a copy for ${source.length} elements...`);

      const connection = await this.provider.getConnection();
      for (const path of source) {
        console.log(`[${this.provider.name}] Copy ${path.path} to ${destination.path}.`);

        if (getParent(path.path) === destination.path) {
          continue;
        }

        const to =
          path.type === PathType.Folder ? join(destination.path, path.name, "/") : join(destination.path, path.name);

        let comparedPaths: CompareResult | null = null;

        if (this.provider.supportsVersioning) {
          comparedPaths = await comparePaths(this.provider.connectionPool, path.path, to);

          const checkpointTasks = [];
          for (const entry of comparedPaths.source) {
            if (!entry.endsWith("/")) {
              checkpointTasks.push(this.createCheckpointBeforeCopy(connection, entry));
            }
          }
          await Promise.all(checkpointTasks);
        }

        const transactionIdResult = await connection.getTransactionId();
        if (transactionIdResult.status !== StatusType.OK) {
          throw new CopyError(transactionIdResult);
        }

        const transactionId = transactionIdResult.transaction_id!.toString();
        const result = await connection.copy({ uri: path.path, to, transaction_id: transactionId });
        if (result.status !== StatusType.OK) {
          throw new CopyError(result);
        }

        if (this.provider.supportsVersioning && comparedPaths) {
          const checkpointTasks = [];
          for (const copiedPath of comparedPaths.source) {
            if (copiedPath.endsWith("/")) continue;

            const destinationEntry = copiedPath.slice(path.path.length);
            const destinationPath = join(to, destinationEntry);
            const replacement = comparedPaths.intersection.has(destinationEntry);
            checkpointTasks.push(this.createCheckpointAfterCopy(connection, copiedPath, destinationPath, replacement));
          }
          await Promise.all(checkpointTasks);
        }

        const child = new Path(
          to,
          path.type,
          path.storage,
          path.dateCreated,
          path.dateModified,
          this.provider.session.username,
          this.provider.session.username,
          path.size,
          path.mounted
        );
        child.permissions = path.permissions;
        destination.add(child);
      }

      await destination.load();
    });
  }

  private async createCheckpointBeforeCopy(connection: Connection, file: string): Promise<void> {
    console.log(`[${this.provider.name}] Create checkpoint before copy from ${file}"`);

    const stat = await connection.stat2({ path: { path: file } });
    if (stat.status !== StatusType.OK) {
      throw new Error(`Error fetching stats for checkpoint file -- ${stat.status}`);
    }

    if (!stat.checkpointed) {
      await connection.checkpointVersion({
        path: { path: file },
        message: `Backup before copy from ${file}`,
      });
    }
  }

  private async createCheckpointAfterCopy(
    connection: Connection,
    source: string,
    destination: string,
    replacement: boolean
  ): Promise<void> {
    const message = replacement ? `Replaced by ${source}` : `Copied from ${source}`;
    const result = await connection.checkpointVersion({
      path: { path: destination },
      message,
    });

    if (![StatusType.OK, StatusType.AlreadyExists].includes(result.status)) {
      throw new Error(`Error in checkpoint creation for ${destination} -- ${result.status}`);
    }
  }
}
