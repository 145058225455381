import { observer } from "mobx-react";
import React from "react";
import useDialogForm from "../hooks/useDialogForm";
import { Commands } from "../state/commands/Provider";
import { IDeleteGroupCommand } from "../state/commands/types/DeleteGroupCommand";
import Group from "../state/Group";
import { ReactComponent as GroupSVG } from "../static/group.svg";
import ConfirmationDialog from "./ConfirmationDialog";
import ImportantText from "./ImportantText";

export interface GroupDeleteConfirmationProps {
  group: Group;
}

const GroupDeleteConfirmation: React.FC<GroupDeleteConfirmationProps> = ({ group }) => {
  const form = useDialogForm({
    fields: {},
    onSubmit: deleteGroup,
  });

  async function deleteGroup() {
    const command = group.storage.commands.get<IDeleteGroupCommand>(Commands.DeleteGroup);
    if (command) {
      return command.execute({ group });
    }
  }

  return (
    <ConfirmationDialog
      title={"Delete Group"}
      kind={"danger"}
      image={GroupSVG}
      onConfirm={form.submit}
      onCancel={form.cancel}
    >
      Do you really want to delete <ImportantText>{group.name}</ImportantText> group?
    </ConfirmationDialog>
  );
};

export default observer(GroupDeleteConfirmation);
