import { observer, useLocalObservable } from "mobx-react";
import React, { useMemo } from "react";
import styled from "styled-components";
import useContextMenu from "../hooks/useContextMenu";
import useSelectionReset from "../hooks/useSelectionReset";
import useUserTable from "../hooks/useUserTable";
import User from "../state/User";
import Users from "../state/Users";
import TableColumnResizing from "./TableColumnResizing";
import { TableDiv, TableDivBody, TableDivHead, TableDivRow } from "./TableDiv";
import TableSorter from "./TableSorter";
import TableSorterHeader from "./TableSorterHeader";
import UserListMenu from "./UserListMenu";
import UserCell from "./UserTableCell";
import VirtualizedTable from "./VirtualizedTable";

export interface UserTableProps {
  users: Users;
  selectedUser?: User | null;
  onSelect?(user: User | null): void;
}

const UserTable: React.FC<UserTableProps> = ({ users, selectedUser, onSelect }) => {
  const { rows } = useLocalObservable(() => {
    return {
      get rows() {
        return [...users.items];
      },
    };
  });

  const selectedRows = useMemo(() => (selectedUser ? [selectedUser] : []), [selectedUser]);
  const [menu, onContextMenu] = useContextMenu(UserListMenu, { props: { users } });
  const resetSelection = useSelectionReset(onSelect);

  const { columns, sorting, direction, sortRows, onHeaderSelect } = useUserTable();
  return (
    <TableSorter<User> rows={rows} sorting={sorting} direction={direction} sort={sortRows}>
      {({ rows }) => (
        <TableColumnResizing columns={columns} headerComponent={UserHeader}>
          {({ columns, headerComponent }) => (
            <>
              <VirtualizedTable
                columns={columns}
                rows={rows}
                selectedRows={selectedRows}
                rowHeight={35}
                tableComponent={TableDiv}
                bodyComponent={TableDivBody}
                headComponent={TableDivHead}
                headTableComponent={TableDiv}
                headerComponent={headerComponent}
                rowComponent={UserRow}
                cellComponent={UserCell}
                emptyRows={0}
                onClick={resetSelection}
                onSelect={onSelect}
                onContextMenu={onContextMenu}
                onHeaderSelect={onHeaderSelect}
              />
              {menu}
            </>
          )}
        </TableColumnResizing>
      )}
    </TableSorter>
  );
};

const UserHeader = styled(TableSorterHeader)`
  padding-left: ${({ column }) => (column.name === "name" ? "35px" : "10px")};
`;

const UserRow = styled(TableDivRow)`
  svg {
    fill: ${({ theme, selected }) => (selected ? theme.colors.selectedItemForeground : theme.colors.foreground)};
    flex: 0 0 auto;
  }
`;

export default observer(UserTable);
