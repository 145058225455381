import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useAppLocation from "../hooks/useAppLocation";
import useAppNavigate from "../hooks/useAppNavigate";
import { Commands } from "../state/commands/Provider";
import { IGetGroupsCommand } from "../state/commands/types/GetGroupsCommand";
import Group from "../state/Group";
import Groups from "../state/Groups";
import Storage from "../state/Storage";
import Users from "../state/Users";
import Tree, { TreeItemEventData } from "./Tree";
import UserManagementTreeItem from "./UserManagementTreeItem";

export interface UserManagementTreeProps {
  className?: string;
  storage: Storage;
  width?: number;
  height?: number;
}

export type UserManagementGroups = { type: "groups"; groups: Groups; toggled: boolean };
export type UserManagementGroup = { type: "group"; group: Group };
export type UserManagementUsers = { type: "users"; users: Users };
export type UserManagementItemType = UserManagementGroups | UserManagementGroup | UserManagementUsers;

const UserManagementTree: React.FC<UserManagementTreeProps> = ({ className, storage, width, height }) => {
  const location = useAppLocation();
  const navigate = useAppNavigate();
  const [showGroups, setShowGroups] = useState(true);

  useEffect(() => {
    async function fetchGroups() {
      const command = storage?.commands.get<IGetGroupsCommand>(Commands.GetGroups);
      if (await command?.allowed()) {
        return await command!.execute({ groups: storage!.groups });
      }
    }

    if (showGroups) {
      fetchGroups().catch((error) => console.error(error));
    }
  }, [storage, showGroups]);

  const groupsItem: UserManagementGroups = useMemo(
    () => ({
      type: "groups",
      groups: storage.groups,
      toggled: showGroups,
    }),
    [storage.groups, showGroups]
  );

  const usersItem: UserManagementUsers = useMemo(
    () => ({
      type: "users",
      users: storage.users,
    }),
    [storage.users]
  );

  const groups: UserManagementGroup[] = storage.groups.items.map((group) => ({
    type: "group",
    group,
  }));

  const items: UserManagementItemType[] = useMemo(() => {
    let items: UserManagementItemType[] = [];
    items.push(groupsItem);

    if (showGroups) {
      items = items.concat(groups);
    }

    items.push(usersItem);
    return items;
  }, [showGroups, groups, groupsItem, usersItem]);

  const selectedItem = useMemo(() => {
    if (location.pathname === `/groups/${storage.name}`) {
      return groupsItem;
    }
    if (location.pathname === `/users/${storage.name}`) {
      return usersItem;
    }
    return groups.find((group) => location.pathname === `/groups/${storage.name}/${group.group.name}`);
  }, [location, groupsItem, usersItem, groups, storage]);

  const handleSelect = useCallback(
    (e: React.MouseEvent & TreeItemEventData<UserManagementItemType>) => {
      if (e.item.type === "groups") {
        navigate(`/groups/${storage.name}`);
      } else if (e.item.type === "users") {
        navigate(`/users/${storage.name}`);
      } else if (e.item.type === "group") {
        navigate(`/groups/${storage.name}/${e.item.group.name}`);
      }
    },
    [navigate, storage]
  );

  const handleToggle = useCallback((e: React.MouseEvent & TreeItemEventData<UserManagementItemType>) => {
    if (e.item.type === "groups") {
      setShowGroups((value) => !value);
    }
  }, []);

  return (
    <Tree
      className={className}
      items={items}
      itemComponent={UserManagementTreeItem}
      selectedItem={selectedItem}
      width={width}
      height={height}
      onToggle={handleToggle}
      onSelect={handleSelect}
    />
  );
};

export default observer(UserManagementTree);
