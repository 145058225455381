import JSONSerializer from "./json";
import { SerializerName } from "./index";

export default class OmniJSONSerializer extends JSONSerializer {
  static [SerializerName] = "omni_json";

  async serialize(data) {
    this.validate(data);
    return super.serialize(data);
  }

  validate(data) {
    if (data instanceof Array) {
      for (const item of data) {
        this.validate(item);
      }
    } else if (typeof data === "object") {
      for (const item of Object.values(data)) {
        this.validate(item);
      }
    } else if (typeof data === "string") {
      if (data.includes("\0")) {
        throw new Error(`String ${data} contains the terminate symbol.`);
      }
    }
  }
}
