import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { StyledMenu } from "../components/Menu";

export interface UseContextMenuOptions<P> {
  props?: P;
  position?: Position;
  anchors?: Anchor[];
  attachTo?: HTMLElement | null;
  portal?: HTMLElement | string | null;
  hideOnBlur?: boolean;
  hideOnClick?: boolean;
  hideOnClickOutside?: boolean;
  hideOnEscape?: boolean;
  style?: React.CSSProperties;
}

type Position = {
  x: number;
  y: number;
};

type Anchor = "top" | "right" | "bottom" | "left";

export default function useContextMenu<P>(
  MenuComponent: React.ComponentType<P>,
  options: UseContextMenuOptions<P> = {}
): [JSX.Element | null, (e?: React.MouseEvent) => void, () => void] {
  const optionsRef = useRef<UseContextMenuOptions<P>>(options);
  optionsRef.current = options;

  const [position, setPosition] = useState<Position | null>(null);
  const hideMenu = useCallback(() => {
    setPosition(null);
  }, []);

  const props = options.props ?? ({} as P);
  const menu = position ? (
    <MenuContainer
      anchors={options.anchors}
      attachTo={options.attachTo}
      portal={options.portal}
      position={position}
      hideOnBlur={options.hideOnBlur}
      hideOnClick={options.hideOnClick}
      hideOnClickOutside={options.hideOnClickOutside}
      hideOnEscape={options.hideOnEscape}
      style={options.style}
      onHide={hideMenu}
    >
      <MenuComponent {...props} />
    </MenuContainer>
  ) : null;

  const showMenu = useCallback(
    (e?: React.MouseEvent) => {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }

      if (!MenuComponent) {
        return;
      }

      const attachTo = optionsRef.current.attachTo;
      const currentTarget = attachTo ?? e?.currentTarget;
      if (!currentTarget) {
        return;
      }

      const position = optionsRef.current.position;
      const anchors = optionsRef.current.anchors ?? [];
      let x, y;
      if (position) {
        // This menu must be shown at the specific position defined in options.
        const rect = currentTarget.getBoundingClientRect();
        x = anchors.includes("right") ? position.x + rect.right : position.x + rect.left;
        y = anchors.includes("bottom") ? position.y + rect.bottom : position.y + rect.top;
      } else if (e?.isTrusted) {
        x = e.clientX;
        y = e.clientY;
      } else {
        // The menu was triggered by the keyboard or a script.
        // We need to find the element size and show the context menu at the origin of that element.
        const rect = currentTarget.getBoundingClientRect();
        x = anchors.includes("right") ? rect.right : rect.left;
        y = anchors.includes("bottom") ? rect.bottom : rect.top;
      }

      setPosition({ x, y });
    },
    [MenuComponent]
  );

  return [menu, showMenu, hideMenu];
}

interface MenuContainerProps {
  anchors?: Anchor[];
  attachTo?: HTMLElement | null;
  className?: string;
  portal?: HTMLElement | string | null;
  position: Position;
  hideOnBlur?: boolean;
  hideOnClick?: boolean;
  hideOnClickOutside?: boolean;
  hideOnEscape?: boolean;
  style?: React.CSSProperties;
  onHide(): void;
}

const MenuContainer: React.FC<MenuContainerProps & React.DetailsHTMLAttributes<HTMLDivElement>> = ({
  anchors = [],
  attachTo,
  portal = document.body,
  position,
  hideOnBlur = true,
  hideOnClick = true,
  hideOnClickOutside = true,
  hideOnEscape = true,
  style: outerStyle,
  children,
  onHide,
  ...props
}) => {
  const [style, setStyle] = useState<React.CSSProperties>(() => ({ ...outerStyle, opacity: "0" }));
  const container = useRef<HTMLDivElement>(null);
  const anchor = anchors?.join(",") ?? "";

  useEffect(() => {
    const node = container.current;
    if (!node) {
      return;
    }

    const [width, height] = [node.clientWidth, node.clientHeight];
    const [windowWidth, windowHeight] = [window.innerWidth, window.innerHeight];

    const style: React.CSSProperties = { ...outerStyle };
    if (anchor) {
      const offset = 10;

      if (anchor.includes("top")) {
        style.top = position.y;
        if (style.top + height > windowHeight) {
          style.height = windowHeight - style.top - offset;
        }
      } else {
        style.bottom = windowHeight - position.y;
      }

      if (anchor.includes("left")) {
        style.left = position.x;
        if (style.left + width > windowWidth) {
          style.width = windowWidth - style.left - offset;
        }
      } else {
        style.right = windowWidth - position.x;
      }
    } else {
      style.left = position.x + width > windowWidth ? windowWidth - width : position.x;
      style.top = position.y + height > windowHeight ? windowHeight - height : position.y;
    }
    setStyle(style);
  }, [position, anchor, outerStyle]);

  useEffect(() => {
    function hideIfClickedOutside(e: MouseEvent) {
      if (hideOnClickOutside && !container.current?.contains(e.target as Element)) {
        onHide();
      }
    }

    function hideIfFocusedOutside(e: FocusEvent) {
      if (hideOnBlur && !container.current?.contains(e.relatedTarget as Element)) {
        onHide();
      }
    }

    function hideIfClicked(e: MouseEvent) {
      if (hideOnBlur && (!hideOnClick || !container.current?.contains(e.target as Element))) {
        onHide();
      }
    }

    function hideIfEscapeIsPressed(e: KeyboardEvent) {
      if (hideOnEscape && e.key === "Escape") {
        onHide();
      }
    }

    document.addEventListener("contextmenu", hideIfClicked, { capture: true });
    document.addEventListener("click", hideIfClicked);
    document.addEventListener("mousedown", hideIfClickedOutside);
    document.addEventListener("focusout", hideIfFocusedOutside);

    const menu = container.current;
    menu?.addEventListener("keydown", hideIfEscapeIsPressed);

    return () => {
      document.removeEventListener("contextmenu", hideIfClicked, { capture: true });
      document.removeEventListener("click", hideIfClicked);
      document.removeEventListener("mousedown", hideIfClickedOutside);
      document.removeEventListener("focusout", hideIfFocusedOutside);
      menu?.removeEventListener("keydown", hideIfEscapeIsPressed);
    };
  }, [hideOnBlur, hideOnClick, hideOnClickOutside, hideOnEscape, onHide]);

  const handleHideOnClick = useCallback(
    (e: React.MouseEvent) => {
      if (hideOnClick) {
        e.preventDefault();
        e.stopPropagation();
        onHide();
      }
    },
    [hideOnClick, onHide]
  );

  const jsx = (
    <StyledMenuContainer ref={container as any} style={style} {...props} onClick={handleHideOnClick}>
      {children}
    </StyledMenuContainer>
  );

  if (portal) {
    const element = typeof portal === "string" ? document.querySelector(portal) : portal;
    return createPortal(jsx, element ?? document.body);
  } else {
    return jsx;
  }
};

const StyledMenuContainer = styled.div`
  position: fixed;
  width: auto;
  height: auto;
  z-index: 4;

  ${StyledMenu} {
    max-width: 100%;
    max-height: 100%;
  }
`;
