import { action, computed, makeObservable, observable, onBecomeObserved, runInAction } from "mobx";
import { openURL } from "../util/Browser";
import { getExtension, getName, splitPath } from "../util/Path";
import ACL from "./ACL";
import Checkpoints from "./Checkpoints";
import { Commands } from "./commands/Provider";
import { IListCommand } from "./commands/types/ListCommand";
import PathUpload, { PathUploadType } from "./PathUpload";
import ResolvedACL from "./ResolvedACL";
import Storage from "./Storage";
import Tags from "./Tags";

export enum PathType {
  File = "file",
  Folder = "folder",
  Symlink = "symlink",
  Mount = "mount",
  Unknown = "unknown",
}

export enum PathPermission {
  Read = "read",
  Write = "write",
  Admin = "admin",
}

class Path {
  public childrenMap: Map<string, Path>;
  public parent: Path | null;
  public loading: boolean;
  public opened: boolean;
  public tags: Tags;
  public acl: ACL;
  public resolvedACL: ResolvedACL;
  public checkpoints: Checkpoints;

  constructor(
    public readonly path: string,
    public readonly type: PathType,
    public readonly storage: Storage,
    public dateCreated?: Date,
    public dateModified?: Date,
    public author?: string,
    public modifiedBy?: string,
    public size: number = 0,
    public mounted?: boolean,
    public link: string = "",
    public downloadLink: string = "",
    public thumbnail: string = "",
    public generatedThumbnail: string = "",
    public permissions: PathPermission[] = []
  ) {
    this.childrenMap = new Map<string, Path>();
    this.parent = null;
    this.loading = false;
    this.opened = false;
    this.checkpoints = new Checkpoints(this.path, this.storage);
    this.tags = new Tags(this.path, this.storage);
    this.acl = new ACL(this, this.storage);
    this.resolvedACL = new ResolvedACL(this, this.storage);

    makeObservable(this, {
      path: observable,
      type: observable,
      dateCreated: observable,
      dateModified: observable,
      author: observable,
      childrenMap: observable,
      loading: observable,
      opened: observable,
      checkpoints: observable,
      tags: observable,
      link: observable,
      downloadLink: observable,
      thumbnail: observable,
      generatedThumbnail: observable,
      name: computed,
      kind: computed,
      children: computed,
      folders: computed,
      selected: computed,
      parents: computed,
      setOpened: action.bound,
      setLoading: action.bound,
      add: action.bound,
      remove: action.bound,
      select: action.bound,
      load: action.bound,
      toggle: action.bound,
      mergeChildren: action.bound,
      merge: action.bound,
      download: action.bound,
      upload: action.bound,
      invalidateLink: action.bound,
      invalidateThumbnails: action.bound,
    });

    onBecomeObserved(this, "link", () => {
      if (!this.link) {
        this.link = this.storage.provider.linkGenerator.createLink({ path: this.path, storage: this.storage.name });
      }
    });

    onBecomeObserved(this, "downloadLink", async () => {
      if (!this.downloadLink) {
        const downloadLink = await this.storage.provider.linkGenerator.createDownloadLink({
          path: this.path,
          storage: this.storage.name,
        });

        runInAction(() => {
          this.downloadLink = downloadLink;
        });
      }
    });

    onBecomeObserved(this, "thumbnail", async () => {
      if (!this.thumbnail) {
        const thumbnail = await this.storage.provider.linkGenerator.createThumbnailLink({
          path: this.path,
          storage: this.storage.name,
        });

        runInAction(() => {
          this.thumbnail = thumbnail;
        });
      }
    });

    onBecomeObserved(this, "generatedThumbnail", async () => {
      if (!this.generatedThumbnail) {
        const generatedThumbnail = await this.storage.provider.linkGenerator.createGeneratedThumbnailLink({
          path: this.path,
          storage: this.storage.name,
        });

        runInAction(() => {
          this.generatedThumbnail = generatedThumbnail;
        });
      }
    });
  }

  public get name(): string {
    const name = getName(this.path);
    return name === "/" ? this.storage?.publicName ?? "" : name;
  }

  public get parents(): Path[] {
    const result: Path[] = [];

    let currentPath = this.parent;
    while (currentPath) {
      result.push(currentPath);
      currentPath = currentPath.parent;
    }

    return result;
  }

  public get kind(): string {
    if (this.type === PathType.Folder) {
      return "Folder";
    }

    if (this.type === PathType.Symlink) {
      return "Symlink";
    }

    if (this.type === PathType.Mount) {
      return "Mount";
    }

    const extension = getExtension(this.path).toUpperCase();
    switch (extension) {
      case ".PNG":
      case ".JPG":
      case ".JPEG":
      case ".BMP":
        return "Image";

      case ".TXT":
        return "Text document";

      default: {
        const dotIndex = extension.lastIndexOf(".");
        return dotIndex === -1 ? extension : extension.substr(dotIndex + 1);
      }
    }
  }

  public get children(): Path[] {
    return Array.from(this.childrenMap.values()).sort(Path.compare);
  }

  public get folders(): Path[] {
    return this.children.filter((item) => item.type === PathType.Folder);
  }

  public get selected(): boolean {
    return this.storage.selectedPaths.has(this) ?? false;
  }

  public get isRoot(): boolean {
    return this.path === "/";
  }

  public static fromJSON(data: any, storage: Storage): Path {
    const path = new Path(
      data.path,
      data.type,
      storage,
      new Date(data.dateCreated),
      new Date(data.dateModified),
      data.author,
      data.modifiedBy,
      data.size,
      data.mounted
    );

    const children = data.children
      ? data.children.map((child: any) => {
          const childPath = Path.fromJSON(child, storage);
          childPath.parent = path;
          return childPath;
        })
      : [];

    path.mergeChildren(children);
    path.opened = Boolean(data.opened);
    path.permissions = data.permissions || [];
    return path;
  }

  public static compare(a: Path, b: Path): number {
    if (a.type === PathType.Mount && b.type !== PathType.Mount) {
      return -1;
    }

    if (a.type !== PathType.Mount && b.type === PathType.Mount) {
      return 1;
    }

    if (a.type === PathType.Folder && b.type !== PathType.Folder) {
      return -1;
    }

    if (a.type !== PathType.Folder && b.type === PathType.Folder) {
      return 1;
    }

    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  }

  public select() {
    this.storage?.selectedPaths.select(this);
  }

  public async toggle(options?: { opened: boolean }): Promise<void> {
    this.setOpened(options ? options.opened : !this.opened);
    if (this.opened) {
      return this.load();
    }
  }

  public setOpened(value: boolean): void {
    this.opened = value;
  }

  public setLoading(loading: boolean): void {
    this.loading = loading;
  }

  public async load(): Promise<void> {
    const command = this.storage.commands.get<IListCommand>(Commands.List);
    if (command) {
      await command.execute({ path: this });
    }
  }

  public add(path: Path): void {
    path.parent = this;
    this.childrenMap.set(path.path, path);
  }

  public remove(path: string): void {
    this.childrenMap.delete(path);
  }

  public get(path: string): Path | undefined {
    return this.childrenMap.get(path);
  }

  public canDownload(): boolean {
    return this.permissions.includes(PathPermission.Read);
  }

  public canWrite(): boolean {
    return this.permissions.includes(PathPermission.Write);
  }

  public canAdmin(): boolean {
    return this.permissions.includes(PathPermission.Admin);
  }

  public canContain(path: Path): boolean {
    return (
      this.type === PathType.Folder &&
      this.path !== path.parent?.path &&
      !splitPath(this.path).includes(path.path) &&
      this.storage.name === path.storage.name
    );
  }

  public async download(): Promise<void> {
    if (!this.downloadLink) {
      this.downloadLink = await this.storage.provider.linkGenerator.createDownloadLink({
        path: this.path,
        storage: this.storage.name,
      });
    }

    if (!this.downloadLink) {
      throw new Error("Can't download this path without a link.");
    }

    openURL(this.downloadLink, { fileName: this.name });
  }

  public async upload(files: File[], type?: PathUploadType, description?: string): Promise<void> {
    const upload = new PathUpload(this, this.storage, files, type, description);
    const scheduled = this.storage.uploads.add(upload);
    await scheduled.start();
    await this.load();
  }

  public mergeChildren(children: Path[]): void {
    const values = new Map();

    for (const child of children) {
      const path = this.childrenMap.get(child.path);
      if (path) {
        path.merge(child);
        values.set(path.path, path);
      } else {
        child.parent = this;
        values.set(child.path, child);
      }
    }

    this.childrenMap = values;
  }

  public merge(path: Path): void {
    this.dateCreated = path.dateCreated;
    this.dateModified = path.dateModified;
    this.author = path.author;
    this.modifiedBy = path.modifiedBy;
    this.permissions = path.permissions;
    this.size = path.size;
    this.mounted = path.mounted;
    this.link = path.link || this.link;
    this.downloadLink = path.downloadLink || this.downloadLink;
    this.thumbnail = path.thumbnail || this.thumbnail;
    this.generatedThumbnail = path.generatedThumbnail || this.generatedThumbnail;
  }

  public invalidateLink(): void {
    this.link = this.storage.provider.linkGenerator.createLink({
      path: this.path,
      storage: this.storage.name,
    });
  }

  public async invalidateThumbnails(): Promise<void> {
    this.thumbnail = await this.storage.provider.linkGenerator.createThumbnailLink({
      path: this.path,
      storage: this.storage.name,
    });
    this.generatedThumbnail = await this.storage.provider.linkGenerator.createGeneratedThumbnailLink({
      path: this.path,
      storage: this.storage.name,
    });
  }

  public toJSON(): any {
    return {
      path: this.path,
      type: this.type,
      dateCreated: this.dateCreated,
      dateModified: this.dateModified,
      author: this.author,
      modifiedBy: this.modifiedBy,
      size: this.size,
      opened: this.opened,
      permissions: this.permissions,
      children: Array.from(this.childrenMap.values()).map((path) => path.toJSON()),
    };
  }
}

export default Path;
