import { observer } from "mobx-react";
import React, { useCallback, useContext, useState } from "react";
import { Commands } from "../state/commands/Provider";
import Storage from "../state/Storage";
import Button from "./Button";
import Dialog from "./Dialog";
import { DialogContext } from "./Dialogs";
import DialogSpinner from "./DialogSpinner";
import DialogTitle from "./DialogTitle";
import FormGroup from "./FormGroup";
import Input from "./Input";
import Label from "./Label";
import useQuery from "../hooks/useQuery";
import { IGetAPITokensCommand } from "../state/commands/types/GetAPITokensCommand";
import { ICreateAPITokenCommand } from "../state/commands/types/CreateAPITokenCommand";
import styled from "styled-components";
import useBrowserClipboard from "../hooks/useBrowserClipboard";
import Icon, { Icons } from "./Icon";
import { TableCell, TableCellComponentProps } from "./Table";
import { APIToken } from "../state/APITokens";
import VirtualizedTable from "./VirtualizedTable";
import Warning from "./Warning";
import ConfirmationWarning from "./ConfirmationWarning";
import { IDeleteAPITokenCommand } from "../state/commands/types/DeleteAPITokenCommand";
import { toJS } from "mobx";
import DialogTextWrapper from "./DialogTextWrapper";
import FormSubmitButtonGroup from "./FormSubmitButtonGroup";
import TextArea from "./TextArea";
import { confirm } from "../util/Dialog";

export interface APITokensDialogProps {
  storage: Storage;
}

const columns = [
  { name: "name", title: "Name" },
  { name: "actions", title: "", width: 32 },
];

const APITokensDialog: React.FC<APITokensDialogProps> = ({ storage }) => {
  const clipboard = useBrowserClipboard();
  const [name, setName] = useState("");
  const [error, setError] = useState<string>();

  const [createdToken, setCreatedToken] = useState<APIToken>();

  const loading = storage.apiTokens.loading;
  const rows = storage.apiTokens.items;

  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setName(e.target.value);
      setError(undefined);
    },
    [setName, setError]
  );

  const isTokenAlreadyExist = storage.apiTokens.names.includes(name);

  useQuery(
    async () => {
      try {
        const command = storage.commands.get<IGetAPITokensCommand>(Commands.GetAPITokens);
        if (command) {
          return await command.execute({ storage: storage });
        } else {
          throw new Error(`${Commands.GetAPITokens} commands are not supported.`);
        }
      } catch (e) {
        setError(e.message);
      }
    },
    {
      keys: [storage],
    }
  );

  async function submit(): Promise<any> {
    if (isTokenAlreadyExist) {
      setError("Token with this name already exists.");
      return;
    }

    try {
      const command = storage.commands.get<ICreateAPITokenCommand>(Commands.CreateAPIToken);
      if (command) {
        const token = await command.execute({ storage: storage, name });

        setCreatedToken(token);
        setName("");
      } else {
        throw new Error(`${Commands.CreateAPIToken} command is not supported.`);
      }
    } catch (e) {
      setError(e.message);
    }
  }

  const deleteToken = useCallback(
    async function (name: string) {
      try {
        const command = storage.commands.get<IDeleteAPITokenCommand>(Commands.DeleteAPIToken);
        if (command) {
          await command.execute({ storage: storage, name });
        } else {
          throw new Error(`${Commands.DeleteAPIToken} command is not supported.`);
        }
      } catch (e) {
        setError(e.message);
      }
    },
    [storage, setError]
  );

  const { hide } = useContext(DialogContext);
  const copyCreateToken = useCallback(() => {
    if (createdToken?.apiToken) {
      clipboard.copy(createdToken.apiToken);
    }
  }, [createdToken, clipboard]);

  return (
    <StyledDialog aria-label={"API Tokens"} onClose={hide}>
      <DialogTitle title={"API Tokens"} onClose={hide} />
      <DialogSpinner loading={loading} />
      <DialogTextWrapper>
        <Title>Create new API token</Title>
        <FormGroup>
          <FormContainer>
            <Label>
              Token Name
              <Input required name={"name"} value={name} onChange={onChange} />
            </Label>

            <CreateButton kind={"attention"} disabled={loading} onClick={submit}>
              Create
            </CreateButton>
          </FormContainer>
        </FormGroup>
        {error && <Warning>{error}</Warning>}
        {createdToken && (
          <>
            <ConfirmationWarning>
              Make sure you save the token - you won't be able to access it again.
            </ConfirmationWarning>

            <Label>
              Generated Token
              <TokenArea value={createdToken.apiToken} readOnly />
            </Label>

            <CopyButton onClick={copyCreateToken} kind="attention">
              <Icon icon={Icons.Copy} /> Copy
            </CopyButton>
          </>
        )}
        <Separator />
        <Title>Active personal API tokens ({rows.length})</Title>
        <TableContainer aria-label={"API Tokens table"}>
          <VirtualizedTable
            columns={columns}
            rowKey={"name"}
            rows={toJS(rows)}
            cellComponent={TokenTableCell}
            cellProps={{ deleteToken }}
          />
        </TableContainer>
        <StyledFormSubmitButtonGroup>
          <Button onClick={hide}>Close</Button>
        </StyledFormSubmitButtonGroup>
      </DialogTextWrapper>
    </StyledDialog>
  );
};

const StyledFormSubmitButtonGroup = styled(FormSubmitButtonGroup)`
  margin-top: 8px;
`;

const TokenArea = styled(TextArea)`
  height: 112px;
`;

export const Separator = styled.div`
  outline: none;
  user-select: none;
  border-top: 2px solid ${({ theme }) => theme.colors.menuSeparator};
  margin-top: 8px;
`;

const Title = styled.div`
  margin-top: 12px;
  color: ${({ theme }) => theme.colors.foreground};
`;

const FormContainer = styled.div`
  display: flex;
  align-items: end;
`;

const CreateButton = styled(Button)`
  margin-left: 12px;
`;

const TableContainer = styled.div`
  height: 200px;
  border: 1px solid ${({ theme }) => theme.colors.border};
`;

const StyledDialog = styled(Dialog)`
  width: 700px;
`;

const CopyButton = styled(Button)`
  margin-left: auto;
  margin-top: 8px;
  display: block;
`;

export default observer(APITokensDialog);

type TokenTableCellProps = TableCellComponentProps<APIToken> & { deleteToken: (name: string) => void };

let TokenTableCell: React.FC<TokenTableCellProps> = ({ column, children, row: apiToken, deleteToken, ...props }) => {
  const onDeleteClick = useCallback(async () => {
    const confirmed = await confirm(
      "Are you sure you want to revoke this API token? This action cannot be undone.",
      "Delete API token"
    );
    if (confirmed) {
      deleteToken(apiToken.name);
    }
  }, [apiToken.name, deleteToken]);

  if (column.name === "name") {
    children = <span title={apiToken.name}>{apiToken.name}</span>;
  } else if (column.name === "actions") {
    children = <StyledDelete onClick={onDeleteClick} clickable title={`Delete token ${apiToken.name}`} />;
  }
  return (
    <StyledTableCell column={column} row={apiToken} {...props}>
      {children}
    </StyledTableCell>
  );
};

const StyledDelete = styled(Icon).attrs({ icon: Icons.Delete })`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.errorText};
`;

const StyledTableCell = styled(TableCell)`
  cursor: pointer;
  padding: 8px;
  overflow: hidden;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  user-select: text;
`;
