import React, { useCallback } from "react";
import CheckpointUploadNotesDialog from "../components/CheckpointUploadNotesDialog";
import PathMoveDialog from "../components/PathMoveDialog";
import { Commands } from "../state/commands/Provider";
import { ICreateCheckpointCommand } from "../state/commands/types/CreateCheckpointCommand";
import dnd from "../state/DND";
import Path, { PathType } from "../state/Path";
import { PathUploadType } from "../state/PathUpload";
import { FileWithPath, getFilesFromEvent } from "../util/Browser";
import { isFolder } from "../util/Path";
import useDialog from "./useDialog";
import useDrop from "./useDrop";
import usePathDropSource from "./usePathDropSource";
import usePermission from "./usePermission";

export default function usePathDrop(destination?: Path) {
  const pathMoveDialog = useDialog(PathMoveDialog);
  const checkpointUploadDialog = useDialog(CheckpointUploadNotesDialog, { path: destination });

  const showMoveDialog = useCallback(() => {
    if (!destination) {
      return;
    }

    if (dnd.type === "paths") {
      pathMoveDialog.show({ source: dnd.data, destination });
    } else if (dnd.type === "path") {
      pathMoveDialog.show({ source: [dnd.data], destination });
    }

    dnd.reset();
  }, [pathMoveDialog, destination]);

  const upload = useCallback(
    async (e: React.DragEvent) => {
      if (!destination) {
        return;
      }

      if (e.dataTransfer?.files?.length) {
        const dataTransferLength = e.dataTransfer.files.length;
        const files = (await getFilesFromEvent(e.nativeEvent)) as FileWithPath[];
        const type: PathUploadType =
          dataTransferLength === 1 && (files.length > 1 || isFolder(files[0].path))
            ? PathUploadType.directory
            : PathUploadType.files;

        const allowedToCreateCheckpoint = await destination.storage.commands.allowed<ICreateCheckpointCommand>(
          Commands.CreateCheckpoint,
          {
            path: destination,
          }
        );

        if (allowedToCreateCheckpoint) {
          checkpointUploadDialog.show({ files, type });
        } else {
          await destination.upload(files, type);
        }
      } else {
        showMoveDialog();
      }
    },
    [checkpointUploadDialog, showMoveDialog, destination]
  );

  const canMove = usePermission(async () => {
    if (!destination) {
      return false;
    }

    let source: Path[];

    if (dnd.type === "path") {
      source = [dnd.data];
    } else if (dnd.type === "paths") {
      source = dnd.data;
    } else {
      source = [];
    }

    return destination.storage.commands.allowed(Commands.Move, { source, destination });
  }, [dnd.data, destination]);

  const allowed = useCallback(
    (e?: React.DragEvent): boolean => {
      if (!destination) {
        return false;
      }
      return Boolean(canMove === "allowed" && destination.type === PathType.Folder && destination.canWrite());
    },
    [destination, canMove]
  );

  const drop = useDrop(upload, allowed);
  const dropSource = usePathDropSource(showMoveDialog, () => allowed());
  return {
    ...drop,
    ...dropSource,
  };
}
