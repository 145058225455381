import React from "react";
import useDialog from "../hooks/useDialog";
import { Checkpoint } from "../state/Checkpoints";
import { Commands } from "../state/commands/Provider";
import { IDeleteCheckpointsCommand } from "../state/commands/types/DeleteCheckpointsCommand";
import { IDownloadCheckpointCommand } from "../state/commands/types/DownloadCheckpointCommand";
import { IRestoreCheckpointCommand } from "../state/commands/types/RestoreCheckpointCommand";
import Path from "../state/Path";
import CheckpointDeleteDialog from "./CheckpointDeleteDialog";
import CheckpointDeleteOlderThanDialog from "./CheckpointDeleteOlderThanDialog";
import { Icons } from "./Icon";
import Menu from "./Menu";
import { MenuIconItem } from "./MenuItem";
import usePermission from "../hooks/usePermission";
import Loader from "./Loader";
import { observer } from "mobx-react";

export interface CheckpointMenuProps {
  className?: string;
  checkpoint: Checkpoint;
  path: Path;
  copyClipboardURL(): void;
}

const CheckpointMenu = ({ className, path, checkpoint, copyClipboardURL }: CheckpointMenuProps) => {
  const olderCheckpoints = Array.from(path.checkpoints.map.values()).filter(
    (iterCheckpoint) => iterCheckpoint.date < checkpoint.date
  );

  const deleteDialog = useDialog(CheckpointDeleteDialog, { path, checkpoint });
  const deleteOlderThanDialog = useDialog(CheckpointDeleteOlderThanDialog, { path, checkpoint, olderCheckpoints });

  const commands = path.storage.commands;
  const allowedToDelete = usePermission(
    () => commands.allowed<IDeleteCheckpointsCommand>(Commands.DeleteCheckpoints, { path }),
    [path]
  );

  const allowedToRestore = usePermission(() => commands.allowed(Commands.RestoreCheckpoint, { path }), [path]);
  const restore = async () => {
    const command = path.storage.commands.get<IRestoreCheckpointCommand>(Commands.RestoreCheckpoint);
    if (command) {
      await command.execute({ path, checkpoint });
    }
  };

  const allowedToDownload = usePermission(() => commands.allowed(Commands.DownloadCheckpoint, { path }), [path]);
  const download = async () => {
    const command = path.storage.commands.get<IDownloadCheckpointCommand>(Commands.DownloadCheckpoint);
    if (command) {
      await command.execute({ path, checkpoint: checkpoint.id! });
    }
  };

  const loading = allowedToDelete === "loading" || allowedToRestore === "loading" || allowedToDownload === "loading";
  if (loading) {
    return (
      <Menu className={className}>
        <Loader />
      </Menu>
    );
  }

  return (
    <Menu className={className}>
      <MenuIconItem icon={Icons.Copy} onClick={copyClipboardURL}>
        Copy URL
      </MenuIconItem>

      <MenuIconItem icon={Icons.Download} visible={allowedToDownload === "allowed"} onClick={download}>
        Download
      </MenuIconItem>

      <MenuIconItem icon={Icons.Redo} visible={allowedToRestore === "allowed"} onClick={restore}>
        Restore
      </MenuIconItem>

      <MenuIconItem icon={Icons.Delete} visible={allowedToDelete === "allowed"} onClick={() => deleteDialog.show()}>
        Delete
      </MenuIconItem>

      <MenuIconItem
        icon={Icons.Delete}
        visible={allowedToDelete === "allowed" && olderCheckpoints.length > 0}
        onClick={() => deleteOlderThanDialog.show()}
      >
        Delete older than #{checkpoint.id}
      </MenuIconItem>
    </Menu>
  );
};

export default observer(CheckpointMenu);
