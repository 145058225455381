import { StatusType } from "@omniverse/api/data";
import { Commands } from "../Provider";
import {
  IDeleteCheckpointsCommandAllowedArguments as IDeleteCheckpointsCommandAllowedArguments,
  IDeleteCheckpointsCommandArguments as IDeleteCheckpointsCommandArguments,
} from "../types/DeleteCheckpointsCommand";

import { NucleusCommand } from "./index";

export default class NucleusDeleteCheckpointsCommand extends NucleusCommand<
  IDeleteCheckpointsCommandArguments,
  IDeleteCheckpointsCommandAllowedArguments
> {
  name = Commands.DeleteCheckpoints;

  public async allowed({ path }: IDeleteCheckpointsCommandAllowedArguments): Promise<boolean> {
    return path.canWrite();
  }

  public async execute({ path, checkpoints }: IDeleteCheckpointsCommandArguments) {
    const connection = await this.provider.getConnection();

    console.log(
      `[${this.provider.name}] Delete checkpoints for path  ${path.path} ${checkpoints.map(
        (checkpoint) => checkpoint.id
      )}`
    );

    const pathsToDelete = checkpoints.map((checkpoint) => ({
      path: path.path,
      checkpoint: checkpoint.id,
    }));

    const response = await connection.delete2({
      paths_to_delete: pathsToDelete,
    });

    const pathsToDeleteWithStatus = pathsToDelete.map((path, index) => ({
      path,
      status: response.responses[index],
    }));

    const invalidCheckpointDeletion = pathsToDeleteWithStatus.filter((path) => path.status !== StatusType.OK);
    if (invalidCheckpointDeletion.length > 0) {
      console.log(
        `Cannot delete checkpoints for path ${path.path}:  ${invalidCheckpointDeletion.map(
          (invalidCheckpointDeletion) =>
            `${invalidCheckpointDeletion.path.checkpoint} -- ${invalidCheckpointDeletion.status}\n`
        )}.`
      );
      throw new Error(`Failed to delete ${invalidCheckpointDeletion.length} checkpoints of ${checkpoints.length}.`);
    }

    for (const checkpoint of checkpoints) {
      path.checkpoints.map.delete(checkpoint.id!);
    }
  }
}
