import React, { useCallback } from "react";
import styled from "styled-components";
import Icon, { Icons } from "./Icon";
import { DefaultTheme } from "./Theme";

export interface TagProps {
  className?: string;
  disabled?: boolean;
  hideDelete?: boolean;
  tag?: string;
  onClick?(tag: string): void;
  onDelete?(tag: string): void;
  children?: React.ReactNode;
}

const Tag: React.FC<TagProps> = React.memo(function Tag({
  className,
  disabled = true,
  hideDelete = false,
  tag = "",
  onClick,
  onDelete,
  children,
}) {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(tag);
    }
  }, [tag, onClick]);

  const deleteTag = useCallback(
    (e: React.MouseEvent) => {
      if (onDelete) {
        e.preventDefault();
        e.stopPropagation();
        onDelete(tag);
      }
    },
    [tag, onDelete]
  );

  if (typeof children === "undefined") {
    children = tag;
  }

  return (
    <StyledTag className={className} title={tag} readOnly={disabled} onClick={handleClick}>
      {children}
      {!hideDelete && !disabled && <DeleteIcon title={`Remove ${tag}`} onMouseDown={deleteTag} />}
    </StyledTag>
  );
});

const StyledTag = styled.div<{ readOnly: boolean }>`
  position: relative;
  display: inline-block;
  padding: 5px;
  background: ${({ theme }) => theme.colors.selectedItemBackground};
  color: ${({ theme }) => theme.colors.selectedItemForeground};
  border-radius: 5px;
  cursor: ${({ readOnly }) => (readOnly ? "default" : "text")};
  box-sizing: border-box;
  height: 20px;
  line-height: 10px;
  font-size: 9pt;
  flex: 0 1 auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
StyledTag.defaultProps = {
  theme: DefaultTheme,
};

const DeleteIcon = styled(Icon).attrs({ icon: Icons.Close, clickable: true })`
  margin-left: 5px;
`;

export default Tag;
