import Form from "@omniverse/auth/react/Form";
import NvidiaLogo from "@omniverse/auth/react/NvidiaLogo";
import OmniverseLogo from "@omniverse/auth/react/OmniverseLogo";
import React from "react";


export interface OmniverseFormProps {
  className?: string;
  children?: React.ReactNode;
}

const OmniverseForm: React.FC<OmniverseFormProps> = ({ className, children }) => {
  return (
    <Form className={className}>
      <NvidiaLogo />
      <OmniverseLogo />
      {children}
    </Form>
  );
};

export default OmniverseForm;