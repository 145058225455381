import { observer } from "mobx-react";
import React from "react";
import useError from "../hooks/useError";
import usePermission from "../hooks/usePermission";
import useQuery from "../hooks/useQuery";
import useSubmit from "../hooks/useSubmit";
import { Commands } from "../state/commands/Provider";
import { IAddUserToGroupCommand } from "../state/commands/types/AddUserToGroupCommand";
import { IGetGroupUsersCommand } from "../state/commands/types/GetGroupUsersCommand";
import { IGetUsersCommand } from "../state/commands/types/GetUsersCommand";
import { IRemoveUserFromGroupCommand } from "../state/commands/types/RemoveUserFromGroupCommand";
import Group from "../state/Group";
import User from "../state/User";
import { ReactComponent as GroupSVG } from "../static/group.svg";
import ErrorMessage from "./ErrorMessage";
import GroupUsers from "./GroupUsers";
import Loader from "./Loader";
import RightPanel from "./RightPanel";
import RightPanelHeader from "./RightPanelHeader";
import RightPanelHeaderImage from "./RightPanelHeaderImage";
import RightPanelRow from "./RightPanelRow";
import RightPanelScrollable from "./RightPanelScrollable";
import RightPanelTitle from "./RightPanelTitle";
import RightPanelToggleable from "./RightPanelToggleable";

export interface GroupPanelProps {
  group: Group;
}

const GroupPanel: React.FC<GroupPanelProps> = ({ group }) => {
  const [error, setError, clearError] = useError();

  const { submit: addUser } = useSubmit(
    async (username: string) => {
      const command = group.storage.commands.get<IAddUserToGroupCommand>(Commands.AddUserToGroup);
      if (await command?.allowed()) {
        const user = new User(username, group.storage);
        return await command!.execute({ group, user });
      }
    },
    {
      onError: setError,
      onSubmit: clearError,
    }
  );

  const { submit: removeUser } = useSubmit(
    async (username: string) => {
      const command = group.storage.commands.get<IRemoveUserFromGroupCommand>(Commands.RemoveUserFromGroup);
      if (await command?.allowed()) {
        const user = new User(username, group.storage);
        await command!.execute({ group, user });
      }
    },
    {
      onError: setError,
      onSubmit: clearError,
    }
  );

  useQuery(
    async () => {
      const command = group.storage.commands.get<IGetUsersCommand>(Commands.GetUsers);
      if (await command?.allowed()) {
        return command?.execute({ users: group.storage.users });
      } else {
        return [];
      }
    },
    {
      keys: [group.storage],
    }
  );

  useQuery(
    async () => {
      const command = group.storage.commands.get<IGetGroupUsersCommand>(Commands.GetGroupUsers);
      if (await command?.allowed()) {
        return command?.execute({ group });
      }
    },
    {
      keys: [group],
    }
  );

  const supportsGroupUsers = usePermission(
    () => group.storage.commands.allowed<IGetGroupUsersCommand>(Commands.GetGroupUsers),
    [group]
  );
  return (
    <RightPanel>
      <RightPanelHeader>
        <RightPanelRow>
          <RightPanelHeaderImage src={GroupSVG} />
          <RightPanelTitle>{group.name}</RightPanelTitle>
        </RightPanelRow>
      </RightPanelHeader>

      {supportsGroupUsers === "allowed" && (
        <RightPanelToggleable label={"Assigned Users"} opened={true}>
          <RightPanelScrollable>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <GroupUsers
              disabled={group.readonly}
              items={group.usernames}
              suggestions={group.storage.users.items}
              onAdd={addUser}
              onDelete={removeUser}
            />
            {group.loading && <Loader />}
          </RightPanelScrollable>
        </RightPanelToggleable>
      )}
    </RightPanel>
  );
};

export default observer(GroupPanel);
