import React from "react";
import useDialogForm from "../hooks/useDialogForm";
import { Commands } from "../state/commands/Provider";
import { IDeleteCommand } from "../state/commands/types/DeleteCommand";
import Path from "../state/Path";
import ConfirmationDialog from "./ConfirmationDialog";
import FormErrorList from "./FormErrorList";
import ImportantText from "./ImportantText";

export interface PathDeleteListDialogProps {
  paths: Path[];
}

const PathDeleteListDialog: React.FC<PathDeleteListDialogProps> = ({ paths }) => {
  const form = useDialogForm({
    fields: {},
    onSubmit: confirm,
  });

  async function confirm() {
    const commands = paths.map((path) => {
      const command = path.storage.commands.get<IDeleteCommand>(Commands.Delete);
      return command?.execute({ path });
    });

    return await Promise.all(commands);
  }

  return (
    <ConfirmationDialog
      title={"Delete Paths"}
      kind={"danger"}
      loading={form.loading}
      onConfirm={form.submit}
      onCancel={form.cancel}
    >
      <FormErrorList errors={form.errors} />
      Do you really want to delete <ImportantText>{paths.length} paths</ImportantText>?
    </ConfirmationDialog>
  );
};

export default PathDeleteListDialog;
