import { Resizable, NumberSize, ResizeDirection } from "re-resizable";
import React from "react";
import { TableColumn } from "./Table";

export interface ResizableTableHeaderProps {
  column: TableColumn;
  minWidth?: number;
  resizable: boolean;
  style?: React.CSSProperties;
  onResize?(column: TableColumn, newWidth: number): void;
}

export default class ResizableTableHeader extends React.Component<ResizableTableHeaderProps> {
  public static resizeDirections = { right: true };
  public static resizeDisabled = {};

  protected handleResize = throttleFrame(
    (event: MouseEvent | TouchEvent, direction: ResizeDirection, elementRef: HTMLDivElement, delta: NumberSize) => {
      if (this.props.onResize) {
        this.props.onResize(this.props.column, elementRef.clientWidth);
      }
    }
  );

  private ref: React.RefObject<Resizable> = React.createRef();

  public componentDidMount() {
    if (this.props.onResize && this.props.resizable) {
      this.props.onResize(this.props.column, this.ref.current!.size.width);
    }
  }

  public render() {
    const { resizable, style, children, minWidth = 25 } = this.props;

    const size = {
      width: this.props.column.width || "auto",
      height: "auto",
    };

    return (
      <Resizable
        ref={this.ref}
        className={"resizable"}
        enable={resizable ? ResizableTableHeader.resizeDirections : ResizableTableHeader.resizeDisabled}
        minWidth={minWidth}
        size={size}
        style={style}
        onResize={this.handleResize}
      >
        {children}
      </Resizable>
    );
  }
}

function throttleFrame(func: (...args: any[]) => void) {
  return (...args: any[]) => {
    requestAnimationFrame(() => {
      func(...args);
    });
  };
}
