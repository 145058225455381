import styled from "styled-components";
import { DefaultTheme } from "./Theme";

const FormError = styled.div`
  background-color: ${({ theme }) => theme.colors.errorText};
  color: white;
  margin: 15px 0;
  padding: 5px 8px;
  font-size: 10pt;
  overflow-wrap: break-word;
`;
FormError.defaultProps = {
  theme: DefaultTheme,
};

export default FormError;
