import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import useContextMenu from "../hooks/useContextMenu";
import { PredefinedIconProps } from "./Icon";
import { StyledRenderer } from "./index";
import Input from "./Input";
import Loader from "./Loader";
import ScrollBar from "./ScrollBar";
import Spinner from "./Spinner";
import { DefaultTheme } from "./Theme";

export interface SuggestionEntry<T = any> {
  value: string;
  data?: T;
}

export interface AutoCompleteProps<T = any> {
  className?: string;
  containerComponent?: React.ComponentType<AutoCompleteContainerProps>;
  disabled?: boolean;
  id?: string;
  inputComponent?: StyledRenderer;
  loading?: boolean;
  matchComponent?: StyledRenderer;
  matcher?(props: AutoCompleteMatchProps): boolean;
  name?: string;
  replacer?(currentValue: string, suggestion: string): string;
  placeholder?: string;
  readOnly?: boolean;
  spellCheck?: boolean;
  spinner?: StyledRenderer<PredefinedIconProps>;
  suggestionComponent?: StyledRenderer<AutoCompleteSuggestionProps>;
  suggestions?: SuggestionEntry<T>[];
  style?: React.CSSProperties;
  tabIndex?: number;
  value: string;
  ref?: React.Ref<HTMLInputElement>;
  onBlur?(e: React.FocusEvent<HTMLInputElement>): void;
  onChange(value: string, suggestion?: string | null): void;
  onFocus?(e: React.FocusEvent<HTMLInputElement>): void;
  onKeyDown?(e: React.KeyboardEvent<HTMLInputElement>): void;
  onKeyUp?(e: React.KeyboardEvent<HTMLInputElement>): void;
}

export interface AutoCompleteMatchProps<T = any> {
  suggestion: SuggestionEntry<T>;
  value: string;
  insensitiveValue: string;
  lastSelectedSuggestion: string;
  selectionStart: number | null;
  selectionEnd: number | null;
}

const throttleTime = 30;

const AutoComplete = React.forwardRef<HTMLInputElement | undefined, AutoCompleteProps>(function AutoComplete(
  {
    className,
    containerComponent: Container = AutoCompleteContainer,
    disabled = false,
    id,
    inputComponent: StyledInput = AutoCompleteInput,
    loading = false,
    matchComponent,
    matcher = matchAutoCompleteSuggestion,
    name,
    placeholder,
    readOnly = false,
    replacer = replaceValueWithSuggestion,
    spellCheck,
    spinner: StyledSpinner = AutoCompleteSpinner,
    suggestionComponent,
    suggestions = [],
    tabIndex = -1,
    value,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    onKeyUp,
    children,
    ...props
  },
  ref
) {
  const container = useRef<HTMLDivElement>(null);
  const input = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => input.current!);

  const [focused, setFocused] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState<number | null>(null);
  const [lastSelectedSuggestion, setLastSelectedSuggestion] = useState("");
  const throttle = useRef<number>();

  const insensitiveValue = useMemo(() => (value ? value.toLowerCase() : ""), [value]);
  const selectionStart = input.current ? input.current.selectionStart : 0;
  const selectionEnd = input.current ? input.current.selectionEnd : 0;

  const matched = useMemo(() => {
    if (value) {
      return suggestions.filter((suggestion) =>
        matcher({
          suggestion: suggestion,
          value,
          insensitiveValue,
          lastSelectedSuggestion,
          selectionStart,
          selectionEnd,
        })
      );
    }
    return [];
  }, [matcher, suggestions, value, insensitiveValue, lastSelectedSuggestion, selectionStart, selectionEnd]);

  const chooseSuggestion = useCallback(
    (suggestion: string) => {
      setSelectedMatch(null);
      setLastSelectedSuggestion(suggestion);
      onChange(replacer(value, suggestion), suggestion);
    },
    [value, replacer, onChange]
  );

  const change = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (readOnly) {
        return;
      }
      setSelectedMatch(null);
      setLastSelectedSuggestion("");
      onChange(e.target.value, null);
    },
    [readOnly, onChange]
  );

  const focus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setFocused(true);
      if (onFocus) {
        onFocus(e);
      }
    },
    [onFocus]
  );

  const blur = useCallback((e: React.FocusEvent) => {
    setFocused(false);
  }, []);

  const keyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (readOnly) {
        return;
      }

      if (e.key === "ArrowUp" && matched.length) {
        e.preventDefault();

        if (throttle.current) {
          return;
        }

        setSelectedMatch((index) => {
          return index ? index - 1 : null;
        });

        throttle.current = window.setTimeout(() => {
          throttle.current = undefined;
        }, throttleTime);

        return;
      } else if (e.key === "ArrowDown" && matched.length) {
        e.preventDefault();

        if (throttle.current) {
          return;
        }

        setSelectedMatch((index) => {
          if (index === null) {
            return 0;
          }
          if (index < matched.length - 1) {
            return index + 1;
          }
          return index;
        });

        throttle.current = window.setTimeout(() => {
          throttle.current = undefined;
        }, throttleTime);

        return;
      } else if (["Tab", "Enter"].includes(e.key) && selectedMatch !== null && matched.length) {
        e.preventDefault();
        e.stopPropagation();
        chooseSuggestion(matched[selectedMatch].value);
        setFocused(false);
        return;
      }

      if (onKeyDown) {
        onKeyDown(e);
      }
    },
    [matched, readOnly, throttle, selectedMatch, chooseSuggestion, onKeyDown]
  );

  useEffect(() => {
    return () => {
      clearTimeout(throttle.current);
    };
  }, []);

  return (
    <Container className={className} {...props} ref={container} placeholder={placeholder} value={value} onBlur={onBlur}>
      <StyledInput
        ref={input}
        name={name}
        id={id}
        disabled={disabled}
        placeholder={placeholder}
        spellCheck={spellCheck}
        tabIndex={tabIndex}
        value={value}
        onFocus={focus}
        onBlur={blur}
        onChange={change}
        onKeyDown={keyDown}
        onKeyUp={onKeyUp}
        autoComplete="off"
      />
      {loading && <StyledSpinner />}
      {focused && value && (
        <Match
          matched={matched}
          selectedMatch={selectedMatch}
          value={value}
          loading={loading}
          component={matchComponent}
          container={container.current}
          suggestion={suggestionComponent}
          onChoose={chooseSuggestion}
        />
      )}
    </Container>
  );
});

export const matchAutoCompleteSuggestion = ({
  suggestion,
  insensitiveValue,
  lastSelectedSuggestion,
}: AutoCompleteMatchProps) => {
  const insensitiveSuggestion = suggestion.value.toLowerCase();
  return (
    lastSelectedSuggestion !== suggestion.value &&
    (insensitiveSuggestion === insensitiveValue || insensitiveSuggestion.startsWith(insensitiveValue))
  );
};

export const replaceValueWithSuggestion = (value: string, suggestion: string) => {
  return suggestion;
};

export type AutoCompleteContainerProps = React.ComponentPropsWithRef<"div"> & {
  placeholder?: string;
  value?: string;
};

export const AutoCompleteContainer = styled.div.withConfig<AutoCompleteContainerProps>({
  shouldForwardProp: (prop) => prop !== "placeholder" && prop !== "value",
})`
  position: relative;
`;

export const AutoCompleteInput = styled(Input)`
  padding: 5px 8px;
`;

const Match: React.FC<{
  matched: SuggestionEntry[];
  selectedMatch: number | null;
  value: string;
  loading: boolean;
  component?: StyledRenderer;
  container?: HTMLDivElement | null;
  suggestion?: StyledRenderer<AutoCompleteSuggestionProps>;
  onChoose(suggestion: string): void;
}> = React.memo(function Match({
  matched,
  selectedMatch,
  component: StyledMatch = AutoCompleteMatch,
  container,
  suggestion: StyledSuggestion = AutoCompleteSuggestion,
  loading,
  onChoose,
}) {
  const [match, showMatch] = useContextMenu(StyledMatch, {
    attachTo: container,
    anchors: ["top", "left"],
    // TODO: remove hardcoded y
    position: { x: 0, y: 30 },
    props: {
      children: (
        <>
          {matched &&
            matched.map((suggestion, index) => (
              <Suggestion
                key={suggestion.value}
                suggestion={suggestion.value}
                data={suggestion.data}
                selected={index === selectedMatch}
                component={StyledSuggestion}
                onChoose={onChoose}
              />
            ))}
          {loading && <Loader>Loading...</Loader>}
        </>
      ),
    },
  });

  useEffect(() => {
    if ((matched && matched.length) || loading) {
      showMatch();
    }
  }, [matched, loading, showMatch]);
  return match;
});

export const AutoCompleteMatch = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.inputBackground};
  color: ${({ theme }) => theme.colors.inputText};
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: 0 0 5px -3px black;
  width: 100%;
  max-height: 400px;
  overflow: auto;
  z-index: 1;

  ${ScrollBar};
`;
AutoCompleteMatch.defaultProps = {
  theme: DefaultTheme,
};

export interface AutoCompleteSuggestionProps<T = any> {
  suggestion: string;
  title?: string;
  selected: boolean;
  data?: T;
  onMouseDown?(e: React.MouseEvent): void;
  ref?: React.Ref<HTMLDivElement>;
  children?: React.ReactNode;
}

const Suggestion: React.FC<
  AutoCompleteSuggestionProps & {
    component: StyledRenderer<AutoCompleteSuggestionProps>;
    onChoose(suggestion: string): void;
  }
> = React.memo(({ component: StyledSuggestion = AutoCompleteSuggestion, selected, onChoose, suggestion, data }) => {
  const ref = useRef<HTMLDivElement>(null);
  const click = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onChoose(suggestion);
    },
    [suggestion, onChoose]
  );

  useEffect(() => {
    if (selected && ref.current) {
      ref.current.scrollIntoView({ block: "nearest" });
    }
  }, [selected, ref]);

  return (
    <StyledSuggestion
      ref={ref}
      suggestion={suggestion}
      title={suggestion}
      data={data}
      selected={selected}
      onMouseDown={click}
    >
      {suggestion}
    </StyledSuggestion>
  );
});

Suggestion.displayName = "Suggestion";

export const AutoCompleteSuggestion = styled.div.withConfig<AutoCompleteSuggestionProps>({
  shouldForwardProp: (prop) => prop !== "data",
})`
  padding: 5px 10px;
  height: 25px;
  line-height: 25px;
  cursor: default;
  user-select: none;
  background: ${({ selected, theme }) => (selected ? theme.colors.selectedItemBackground : "inherit")};
  color: ${({ selected, theme }) => (selected ? theme.colors.selectedItemForeground : "inherit")};
  font-size: 10pt;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    background: ${({ selected, theme }) =>
      selected ? theme.colors.selectedItemHover : theme.colors.hoverItemBackground};
    color: ${({ selected, theme }) => (selected ? theme.colors.selectedItemForeground : theme.colors.foreground)};
  }
`;
AutoCompleteSuggestion.defaultProps = {
  theme: DefaultTheme,
};

export const AutoCompleteSpinner = styled(Spinner)`
  position: absolute;
  display: block;
  top: calc(50% - 8px);
  right: 10px;
  color: ${({ theme }) => theme.colors.foreground};
`;
AutoCompleteSpinner.defaultProps = {
  theme: DefaultTheme,
};

export default AutoComplete;
