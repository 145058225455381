import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import Group from "../state/Group";
import User from "../state/User";
import type { AutoCompleteMatchProps, AutoCompleteSuggestionProps } from "./AutoComplete";
import AutoComplete, { AutoCompleteProps, AutoCompleteSuggestion, SuggestionEntry } from "./AutoComplete";

const UserGroupSuggestionContainer = styled(AutoCompleteSuggestion)`
  height: auto;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

const UserGroupSuggestion = React.forwardRef<HTMLDivElement, AutoCompleteSuggestionProps<User | Group>>(
  function UserGroupSuggestion({ data, ...props }, ref) {
    let body: React.ReactNode = "";

    if (data instanceof Group) {
      body = <div>{data.name}</div>;
    } else if (data instanceof User) {
      const fullName = [data.profile?.firstName, data.profile?.lastName].join(" ");
      body = (
        <>
          <div>{data.name}</div>
          {data.profile?.email && data.profile.email !== data.name && <div>{data.profile.email}</div>}
          {fullName && <div>{fullName}</div>}
        </>
      );
    } else {
      body = "";
    }

    return (
      <UserGroupSuggestionContainer {...props} ref={ref}>
        {body}
      </UserGroupSuggestionContainer>
    );
  }
);

export const mapUsersToSuggestions = (users: User[]): UserGroupSuggestionEntry[] =>
  users.map((user) => ({ value: user.name, data: user }));

export const mapGroupsToSuggestions = (groups: Group[]): UserGroupSuggestionEntry[] =>
  groups.map((group) => ({ value: group.name, data: group }));

export type UserGroupSuggestionEntry = SuggestionEntry<User | Group>;

const matcher = ({ suggestion, insensitiveValue, lastSelectedSuggestion }: AutoCompleteMatchProps<User | Group>) => {
  const data = suggestion.data;

  let fields: Array<string | undefined> = [];
  if (data instanceof User) {
    fields = [data.name, data.profile?.firstName, data.profile?.lastName, data.profile?.email];
  } else if (data instanceof Group) {
    fields = [data.name];
  }

  const searchString = fields.join().toLowerCase();
  return lastSelectedSuggestion !== suggestion.value && searchString.includes(insensitiveValue);
};

const UserGroupAutoComplete = React.forwardRef<HTMLInputElement, AutoCompleteProps>(function UserGroupAutoComplete(
  props,
  ref
) {
  return <AutoComplete ref={ref} suggestionComponent={UserGroupSuggestion} matcher={matcher} {...props} />;
});

export default observer(UserGroupAutoComplete);
