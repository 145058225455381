export function unique<T>(array: T[]): T[] {
  return [...new Set(array)];
}

export async function fromAsyncIter<T>(iterator: AsyncGenerator<T>): Promise<T[]> {
  const results: T[] = [];
  for await (const item of iterator) {
    results.push(item);
  }
  return results;
}

export async function* toAsyncIter<T>(array: T[]): AsyncIterable<T> {
  for await (const item of array) {
    yield item;
  }
}
