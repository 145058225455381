import { observer } from "mobx-react";
import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useApp from "../hooks/useApp";
import useAppNavigate from "../hooks/useAppNavigate";
import useCwd from "../hooks/useCwd";
import usePathDrop from "../hooks/usePathDrop";
import useStorageList from "../hooks/useStorageList";
import Path from "../state/Path";
import StorageList from "../state/StorageList";
import AutoComplete, {
  AutoCompleteContainer,
  AutoCompleteMatch,
  AutoCompleteMatchProps,
  AutoCompleteProps,
} from "./AutoComplete";
import StyledBreadcrumb from "./Breadcrumb";
import BaseBreadcrumbs from "./Breadcrumbs";
import Input from "./Input";
import { DefaultTheme } from "./Theme";

const ContentBreadcrumbs: React.FC = () => {
  const storageList = useStorageList();
  const navigate = useAppNavigate();
  const cwd = useCwd();
  const storage = cwd?.storage;
  const breadcrumbs = useMemo(() => getBreadcrumbs(cwd), [cwd]);

  const [selectedPath, setSelectedPath] = useState<Path | null>(null);

  const focus = useCallback((): string => {
    return cwd?.link ?? "";
  }, [cwd]);

  const change = useCallback(
    (link: string): void => {
      if (storage) {
        let result = storage?.provider.linkGenerator.parseLink(link);
        if (result) {
          link = storage?.provider.linkGenerator.createLink(result);
        } else {
          const server = storage?.name ?? "";
          if (server) {
            // This is relative path.
            link = storage?.provider.linkGenerator.createLink({ storage: storage.name, path: link });
          }
        }
      }

      if (link) {
        navigate(`/${link}`);
      }
    },
    [storage, navigate]
  );

  const currentServer = cwd?.storage.name ?? "";
  const suggestions = getSuggestions(storageList);
  const matcher = useCallback(
    ({ value, suggestion, lastSelectedSuggestion }: AutoCompleteMatchProps): boolean => {
      if (suggestion.value === lastSelectedSuggestion) {
        return false;
      }

      let link = storage?.provider.linkGenerator.parseLink(value);
      if (!link) {
        // Create link from relative path
        link = storage?.provider.linkGenerator.parseLink(
          storage?.provider.linkGenerator.createLink({ path: value, storage: currentServer })
        );
        if (!link) {
          return false;
        }
      }

      return suggestion.value.startsWith(value);
    },
    [storage, currentServer]
  );

  const changeAutoComplete = useCallback(
    async (value: string) => {
      let link = storage?.provider.linkGenerator.parseLink(value);
      if (!link) {
        return;
      }

      const server = storageList.find(link.storage);
      if (server) {
        const path = server.get(link.path);
        if (path) {
          setSelectedPath(path);
          try {
            await path.load();
          } catch {
            // If load is failed, then ignore it -- suggestions won't be displayed in this case.
          }
        }
      }
    },
    [storageList, storage?.provider.linkGenerator]
  );

  const autoCompleteProps = useMemo(
    (): Partial<AutoCompleteProps> => ({
      matcher,
      suggestions: suggestions.map((suggestion) => ({ value: suggestion })),
      loading: selectedPath?.loading,
      onChange: changeAutoComplete,
    }),
    [matcher, selectedPath, suggestions, changeAutoComplete]
  );

  return (
    <StyledBreadcrumbs
      input={StyledAutoComplete}
      autoCompleteProps={autoCompleteProps}
      onFocus={focus}
      onChange={change}
    >
      {breadcrumbs.map((breadcrumb, index) => (
        <StyledBreadcrumb key={index}>
          <BreadcrumbLink path={breadcrumb} />
        </StyledBreadcrumb>
      ))}
    </StyledBreadcrumbs>
  );
};

function getBreadcrumbs(path: Path | null): Path[] {
  const breadcrumbs = [];

  while (path) {
    breadcrumbs.push(path);
    path = path.parent;
  }

  return breadcrumbs.reverse();
}

function getSuggestions(servers: StorageList): string[] {
  let suggestions: string[] = [];

  for (const server of servers.items) {
    suggestions = suggestions.concat(server.paths.map((path) => path.link ?? "").sort());
  }

  return suggestions;
}

const StyledBreadcrumbs = styled(BaseBreadcrumbs)`
  flex: 1 1 550px;
  background: ${({ theme }) => theme.colors.toolbarBackground};
  border: 1px solid ${({ theme }) => theme.colors.toolbarBorder};
  margin-right: 2.5rem;
  height: 25px;
  box-sizing: border-box;
`;
StyledBreadcrumbs.defaultProps = {
  theme: DefaultTheme,
};

const StyledInput = styled(Input)`
  flex: 1 1 550px;
  height: 27px;
  outline: none;
  background: ${({ theme }) => theme.colors.toolbarBackground};
  color: ${({ theme }) => theme.colors.toolbarForeground};
  border: 0;
  margin-right: 10px;
  box-sizing: border-box;
  border: none;
`;
StyledInput.defaultProps = {
  theme: DefaultTheme,
};

const StyledMatch = styled(AutoCompleteMatch)`
  z-index: 1;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.foreground};
`;
StyledMatch.defaultProps = {
  theme: DefaultTheme,
};

const StyledAutoCompleteContainer = styled(AutoCompleteContainer)`
  flex: 1 1 550px;
  height: 25px;
  margin-right: 2.5rem;
  box-sizing: border-box;
`;

const StyledAutoComplete = styled(AutoComplete).attrs({
  inputComponent: StyledInput,
  matchComponent: StyledMatch,
  containerComponent: StyledAutoCompleteContainer,
})`
  flex: 1 1 550px;
  margin-right: 10px;
`;

const BreadcrumbLink: React.FC<{ path: Path }> = ({ path }) => {
  const app = useApp();
  const { allowed, ...drop } = usePathDrop(path);
  return (
    <StyledBreadcrumbLink to={app.href(`/${path.link}`)} title={path.name} {...drop}>
      {path.name}
    </StyledBreadcrumbLink>
  );
};

const StyledBreadcrumbLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

export default observer(ContentBreadcrumbs);
