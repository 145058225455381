import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import useCwd from "../hooks/useCwd";
import useDateInput from "../hooks/useDateInput";
import useInput from "../hooks/useInput";
import { SearchPrefixes } from "../state/commands/types/GetSearchPrefixesCommand";
import { PathSearchQuery, sanitizeTags } from "../state/PathSearch";
import Users from "../state/Users";
import { parseRelativeDate } from "../util/DateTime";
import { AutoCompleteContainer } from "./AutoComplete";
import Button from "./Button";
import ButtonGroup from "./ButtonGroup";
import FormError from "./FormError";
import FormRow from "./FormRow";
import Input from "./Input";
import Label from "./Label";
import Select, { Option, OptionDivider, OptionGroup } from "./Select";
import TagAutoComplete from "./TagAutoComplete";
import { DefaultTheme } from "./Theme";
import UserAutoComplete, { mapUsersToSuggestions } from "./UserGroupAutoComplete";

const StyledAutoCompleteContainer = styled(AutoCompleteContainer)`
  width: 100%;
`;

export interface SearchOptionsProps {
  className?: string;
  initial?: Partial<SearchOptionsQuery>;
  prefixes?: SearchPrefixes;
  tagSuggestions?: string[];
  onSubmit(data: SearchOptionsQuery): void;
  onClose(): void;
  users: Users;
}

type SearchOptionsQuery = Omit<PathSearchQuery, "image">;

const SearchOptions: React.FC<SearchOptionsProps> = ({
  className,
  initial,
  prefixes = {},
  tagSuggestions,
  onSubmit,
  onClose,
}) => {
  const ref = useRef<HTMLFormElement>(null);
  const cwd = useCwd();

  const [createdBy, setCreatedBy] = useState(initial?.createdBy ?? "");
  const [modifiedBy, setModifiedBy] = useState(initial?.modifiedBy ?? "");
  const [name, setName] = useInput(() => {
    if (!initial?.name) {
      return "";
    }
    return initial.name.map((name) => (name.includes(" ") && !name.startsWith('"') ? `"${name}"` : name)).join(" ");
  });
  const [description, setDescription] = useInput(initial?.description ?? "");
  const [tags, setTags] = useState(initial?.tags ?? []);
  const [type, setType] = useState(initial?.type || "All");
  const [customType, setCustomType] = useInput(initial?.type ?? "");
  const [date, setDate] = useState<string>(() => getDateType(initial?.dateStart));
  const [dateStart, setDateStart] = useDateInput(initial?.dateStart ?? "");
  const [dateEnd, setDateEnd] = useDateInput(initial?.dateEnd ?? "");
  const [fileSize, setFileSize] = useInput(initial?.fileSize ?? "");
  const [fileSizeComparator, setFileSizeComparator] = useState(initial?.fileSizeComparator || "Greater Than");
  const [error, setError] = useState("");

  function submit(e?: React.FormEvent) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (date === "Custom" && dateStart && dateEnd) {
      const range = {
        start: new Date(dateStart),
        end: new Date(dateEnd),
      };
      if (range.end.getTime() < range.start.getTime()) {
        setError(`End date must be greater than start date.`);
        return;
      }
    }

    const searchedName = name.trim();

    setError("");
    onSubmit?.({
      name: searchedName ? createNameFilters(searchedName) : [],
      description,
      tags: sanitizeTags(tags),
      type: type === "All" ? "" : type === "Custom File Types" ? customType : type,
      createdBy,
      modifiedBy,
      dateStart: date === "Any time" ? "" : date === "Custom" ? dateStart : parseRelativeDate(date, "since"),
      dateEnd: date === "Custom" ? dateEnd : "",
      fileSize,
      fileSizeComparator,
    });
  }

  const setCreatedByMe = useCallback(() => {
    setCreatedBy(cwd?.storage.session.username ?? "");
  }, [cwd, setCreatedBy]);

  const setModifiedByMe = useCallback(() => {
    setModifiedBy(cwd?.storage.session.username ?? "");
  }, [cwd, setModifiedBy]);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  const userSuggestions = cwd ? mapUsersToSuggestions(cwd.storage.users.items) : [];
  return (
    <StyledSearchOptions className={className} ref={ref} tabIndex={-1} onSubmit={submit}>
      {error && <SearchError>{error}</SearchError>}

      {prefixes.description && (
        <>
          <Label htmlFor={"description"}>AI Search</Label>
          <Input
            name={"description"}
            id={"description"}
            placeholder={"e.g. red rusty barrel"}
            value={description}
            onChange={setDescription}
          />
        </>
      )}

      {prefixes.name && (
        <>
          <Label htmlFor={"name"}>File Name</Label>
          <Input name={"name"} id={"name"} placeholder={"File or Folder Name"} value={name} onChange={setName} />
        </>
      )}

      {prefixes.createdBy && (
        <>
          <Label htmlFor={"createdBy"}>Created By</Label>
          <FormRow>
            <UserAutoComplete
              id={"createdBy"}
              name={"createdBy"}
              placeholder={`e.g. user-name@my-company.com`}
              suggestions={userSuggestions}
              value={createdBy}
              onChange={setCreatedBy}
              containerComponent={StyledAutoCompleteContainer}
              inputComponent={Input}
            />
            <Button onClick={setCreatedByMe}>Me</Button>
          </FormRow>
        </>
      )}

      {prefixes.modifiedBy && (
        <>
          <Label htmlFor={"modifiedBy"}>Modified By</Label>
          <FormRow>
            <UserAutoComplete
              id={"modifiedBy"}
              name={"modifiedBy"}
              placeholder={`e.g. user-name@my-company.com`}
              suggestions={userSuggestions}
              value={modifiedBy}
              onChange={setModifiedBy}
              containerComponent={StyledAutoCompleteContainer}
              inputComponent={Input}
            />
            <Button onClick={setModifiedByMe}>Me</Button>
          </FormRow>
        </>
      )}

      {prefixes.tags && (
        <>
          <Label htmlFor={"tags"}>Tags</Label>
          <TagAutoComplete
            name={"tags"}
            id={"tags"}
            placeholder={"e.g.: red, blue"}
            suggestions={tagSuggestions}
            value={tags}
            onChange={setTags}
          />
        </>
      )}

      {prefixes.type && (
        <>
          <Label htmlFor={"type"}>File Type</Label>
          <FormRow>
            <Select name={"type"} id={"type"} value={type} onChange={setType}>
              <Option value={"All"}>All</Option>
              <OptionDivider />
              <OptionGroup label={"USD"}>
                <Option value={"usd"}>usd</Option>
                <Option value={"usda"}>usda</Option>
                <Option value={"usdc"}>usdc</Option>
                <Option value={"usdz"}>usdz</Option>
              </OptionGroup>
              <OptionGroup label={"Other 3D Files"}>
                <Option value={"obj"}>obj</Option>
                <Option value={"fbx"}>fbx</Option>
                <Option value={"gltf"}>gltf</Option>
                <Option value={"glb"}>glb</Option>
                <Option value={"lxa"}>lxa</Option>
                <Option value={"md5"}>md5</Option>
                <Option value={"es7"}>es7</Option>
              </OptionGroup>
              <OptionDivider />
              <OptionGroup label={"Images"}>
                <Option value={"png"}>png</Option>
                <Option value={"svg"}>svg</Option>
                <Option value={"tiff"}>tiff</Option>
                <Option value={"gif"}>gif</Option>
                <Option value={"tga"}>tga</Option>
                <Option value={"exr"}>exr</Option>
              </OptionGroup>
              <OptionGroup label={"Audio"}>
                <Option value={"wav"}>wav</Option>
                <Option value={"wave"}>wave</Option>
                <Option value={"ogg"}>ogg</Option>
                <Option value={"oga"}>oga</Option>
                <Option value={"flac"}>flac</Option>
                <Option value={"fla"}>fla</Option>
                <Option value={"mp3"}>mp3</Option>
                <Option value={"m4a"}>m4a</Option>
                <Option value={"spx"}>spx</Option>
                <Option value={"opus"}>opus</Option>
                <Option value={"adpcm"}>adpcm</Option>
              </OptionGroup>
              <OptionGroup label={"Materials"}>
                <Option value={"mdl"}>mdl</Option>
                <Option value={"mtlx"}>mtlx</Option>
              </OptionGroup>
              <OptionGroup label={"Scripts"}>
                <Option value={"py"}>py</Option>
              </OptionGroup>
              <OptionDivider />
              <Option value={"Custom File Types"}>Custom File Types</Option>
            </Select>

            <Input
              name={"customType"}
              placeholder={"Custom File Type"}
              style={{ visibility: type === "Custom File Types" ? "visible" : "hidden" }}
              value={customType}
              onChange={setCustomType}
            />
          </FormRow>
        </>
      )}

      {prefixes.fileSize && (
        <>
          <Label htmlFor={"fileSize"}>File Size</Label>
          <FormRow>
            <Select value={fileSizeComparator} onChange={setFileSizeComparator}>
              <Option value={"Greater Than"}>Greater Than</Option>
              <Option value={"Less Than"}>Less Than</Option>
            </Select>
            <Input id={"fileSize"} placeholder={"0 MB"} value={fileSize} onChange={setFileSize} />
          </FormRow>
        </>
      )}

      {prefixes.dateEnd && (
        <>
          <Label htmlFor={"date"}>Date</Label>
          <FormRow>
            <Select value={date} id={"date"} onChange={setDate}>
              <Option value={"Any time"}>Any time</Option>
              <Option value={"Today"}>Today</Option>
              <Option value={"Yesterday"}>Yesterday</Option>
              <Option value={"Last 7 days"}>Last 7 days</Option>
              <Option value={"Last 30 days"}>Last 30 days</Option>
              <Option value={"Last 90 days"}>Last 90 days</Option>
              <Option value={"Custom"}>Custom</Option>
            </Select>

            {date === "Custom" ? (
              <>
                <SearchDateTime
                  placeholder={"Start date"}
                  style={{ visibility: date === "Custom" ? "visible" : "hidden" }}
                  value={dateStart}
                  onChange={setDateStart}
                />

                <SearchDateTime
                  placeholder={"End date"}
                  style={{ visibility: date === "Custom" ? "visible" : "hidden" }}
                  value={dateEnd}
                  onChange={setDateEnd}
                />
              </>
            ) : (
              <Input style={{ visibility: "hidden" }} />
            )}
          </FormRow>
        </>
      )}

      <SearchOptionButtons placement={"right"}>
        <Button type={"submit"} onClick={submit}>
          Search
        </Button>
        <Button onClick={onClose}>Close</Button>
      </SearchOptionButtons>
    </StyledSearchOptions>
  );
};

const StyledSearchOptions = styled.form`
  display: grid;
  padding: 1em;
  background-color: ${({ theme }) => theme.colors.headerBackground};
  color: ${({ theme }) => theme.colors.headerForeground};
  grid-template-columns: 100px calc(540px - 2em);
  gap: 10px;
  box-shadow: ${({ theme }) => theme.shadows.default};
  align-items: center;
  box-sizing: border-box;
  width: 650px;
`;
StyledSearchOptions.defaultProps = {
  theme: DefaultTheme,
};

const SearchOptionButtons = styled(ButtonGroup)`
  grid-column: 1 / 3;
`;

const SearchError = styled(FormError)`
  grid-column: 1 / 3;
  margin: 0;
`;

const SearchDateTime = styled(Input).attrs({ type: "date" })`
  display: inline-block;
  overflow: visible;
  flex: 0 0 170px;
  width: 170px;
  min-width: 0;
`;

function getDateType(dateString: string | undefined): string {
  if (!dateString) {
    return "Any time";
  }

  const timestamp = Date.parse(dateString);
  if (isNaN(timestamp)) {
    return "Any time";
  }

  return "Custom";
}

function createNameFilters(value: string): string[] {
  return value.split(/("[\w ]+")|(\w+)/g).filter((name) => name && name !== " ");
}

export default observer(SearchOptions);
