import { observer } from "mobx-react";
import React from "react";
import useDialogForm from "../hooks/useDialogForm";
import { Commands } from "../state/commands/Provider";
import { IDeleteCommand } from "../state/commands/types/DeleteCommand";
import Path from "../state/Path";
import ConfirmationDialog from "./ConfirmationDialog";
import FormErrorList from "./FormErrorList";
import ImportantText from "./ImportantText";
import Warning from "./Warning";

export interface PathDeleteDialogProps {
  path: Path;
}

const PathDeleteDialog: React.FC<PathDeleteDialogProps> = ({ path }) => {
  const form = useDialogForm({
    fields: {},
    onSubmit: confirm,
  });

  async function confirm() {
    const command = path.storage.commands.get<IDeleteCommand>(Commands.Delete);
    if (command) {
      return command?.execute({ path });
    }
  }

  return (
    <ConfirmationDialog
      title={"Delete Path"}
      kind={"danger"}
      loading={form.loading}
      onConfirm={form.submit}
      onCancel={form.cancel}
    >
      <FormErrorList errors={form.errors} />

      <Warning>
        <p>Do you really want to delete "<ImportantText>{path.name}</ImportantText>"?</p>
        <p>This action cannot be undone. <br /> The {path.type} and the checkpoints will be permanently deleted.</p>
      </Warning>
    </ConfirmationDialog>
  );
};

export default observer(PathDeleteDialog);
