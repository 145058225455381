import { observer } from "mobx-react";
import React from "react";
import useDialogForm from "../hooks/useDialogForm";
import { Commands } from "../state/commands/Provider";
import { IUnmountCommand } from "../state/commands/types/UnmountCommand";
import Path from "../state/Path";
import ConfirmationDialog from "./ConfirmationDialog";
import ImportantText from "./ImportantText";

export interface MountDeleteConfirmationProps {
  path: Path;
}

const MountDeleteConfirmation: React.FC<MountDeleteConfirmationProps> = ({ path }) => {
  const form = useDialogForm({
    fields: {},
    onSubmit: unmount,
  });

  async function unmount() {
    const command = path.storage.commands.get<IUnmountCommand>(Commands.Unmount);
    if (command) {
      await command.execute({ path });
    } else {
      throw new Error(`${Commands.Unmount} command is not supported.`);
    }
  }

  return (
    <ConfirmationDialog title={"Unmount"} kind={"danger"} onConfirm={form.submit} onCancel={form.cancel}>
      Are you sure you want to unmount <ImportantText>{path.name}</ImportantText>?
    </ConfirmationDialog>
  );
};

export default observer(MountDeleteConfirmation);
