import { observer } from "mobx-react";
import React, { useCallback, useContext, useMemo, useState } from "react";
import styled from "styled-components";
import useContextMenu from "../hooks/useContextMenu";
import useDoubleClick from "../hooks/useDoubleClick";
import { DropStyle } from "../hooks/useDrop";
import { useMultipleSelection } from "../hooks/useMultipleSelection";
import usePathDrag from "../hooks/usePathDrag";
import usePathDrop from "../hooks/usePathDrop";
import usePathNavigate from "../hooks/usePathNavigate";
import useSelectionReset from "../hooks/useSelectionReset";
import Path, { PathType } from "../state/Path";
import Selection from "../state/Selection";
import { getDefaultPathThumbnail } from "../util/PathThumbnail";
import DateTime from "./DateTime";
import FileSize from "./FileSize";
import Icon, { Icons } from "./Icon";
import PathMenu from "./PathMenu";
import Picture from "./Picture";
import SortingIcon from "./SortingIcon";
import { TableCellComponentProps, TableColumn, TableHeaderComponentProps } from "./Table";
import TableColumnOrdering from "./TableColumnOrdering";
import TableColumnResizing from "./TableColumnResizing";
import TableColumnVisibility from "./TableColumnVisibility";
import { TableDiv, TableDivBody, TableDivCell, TableDivHead, TableDivHeader, TableDivRow } from "./TableDiv";
import TableSorter from "./TableSorter";
import VirtualizedTable, {
  VirtualizedBody,
  VirtualizedTableBodyComponentProps,
  VirtualizedTableBodyContainerProps,
  VirtualizedTableRowComponentProps,
} from "./VirtualizedTable";
import VirtualizedTableSelectionBox from "./VirtualizedTableSelectionBox";

export interface PathTableProps {
  cwd?: Path;
  defaultDirection?: "ASC" | "DESC";
  defaultSorting?: PathTableColumn;
  paths: Path[];
  showEmptyColumn?: boolean;
}

export enum PathTableColumn {
  None = "",
  Name = "name",
  DateModified = "dateModified",
  Type = "type",
  CreatedBy = "author",
  ModifiedBy = "modifiedBy",
  Size = "size",
}

const PathTable: React.FC<PathTableProps> = ({
  cwd,
  defaultDirection = "ASC",
  defaultSorting = PathTableColumn.Name,
  paths,
  showEmptyColumn = false,
}) => {
  const [sorting, setSorting] = useState(defaultSorting);
  const [direction, setDirection] = useState<"ASC" | "DESC">(defaultDirection);

  const context = useMemo(
    (): IPathTableContext => ({
      sorting,
      direction,
      cwd,
    }),
    [sorting, direction, cwd]
  );

  function sortRows(rows: Path[]): Path[] {
    return [...rows].sort((a, b) => {
      const comparisonDirection = direction === "ASC" ? 1 : -1;
      if (sorting === PathTableColumn.Name) {
        return comparisonDirection * Path.compare(a, b);
      }

      if (sorting === PathTableColumn.Type) {
        return comparisonDirection * a.type.localeCompare(b.type);
      }

      if (sorting === PathTableColumn.DateModified) {
        if (!a.dateModified) {
          return comparisonDirection;
        }
        if (!b.dateModified) {
          return -comparisonDirection;
        }

        return comparisonDirection * (a.dateModified.valueOf() - b.dateModified.valueOf());
      }

      if (sorting === PathTableColumn.Size) {
        return comparisonDirection * (a.size - b.size);
      }

      if (sorting === PathTableColumn.CreatedBy) {
        if (!a.author) {
          return comparisonDirection;
        }
        if (!b.author) {
          return -comparisonDirection;
        }
        return comparisonDirection * a.author.localeCompare(b.author);
      }

      if (sorting === PathTableColumn.ModifiedBy) {
        if (!a.modifiedBy) {
          return comparisonDirection;
        }
        if (!b.modifiedBy) {
          return -comparisonDirection;
        }
        return comparisonDirection * a.modifiedBy.localeCompare(b.modifiedBy);
      }

      return 0;
    });
  }

  const resetSelection = useSelectionReset(() => {
    cwd?.storage.selectedPaths.reset();
  });

  function selectColumn(column: TableColumn) {
    setDirection(sorting === column.name ? (direction === "ASC" ? "DESC" : "ASC") : "ASC");
    setSorting(column.name as PathTableColumn);
  }

  return (
    <PathTableContext.Provider value={context}>
      <TableColumnVisibility
        columns={showEmptyColumn ? PathTableColumnsWithEmpty : PathTableColumns}
        headComponent={TableDivHead}
      >
        {({ columns, headComponent }) => (
          <TableSorter<Path> rows={paths} sorting={sorting} direction={direction} sort={sortRows}>
            {({ rows }) => (
              <TableColumnOrdering columns={columns} headerComponent={PathTableHeader}>
                {({ columns, headerComponent }) => (
                  <TableColumnResizing columns={columns} headerComponent={headerComponent}>
                    {({ columns, headerComponent }) => (
                      <VirtualizedTable<Path>
                        columns={columns}
                        rows={rows}
                        rowKey={"path"}
                        rowHeight={PathTableRowHeight}
                        headTableComponent={TableDiv}
                        headComponent={headComponent}
                        headerComponent={headerComponent}
                        bodyContainerComponent={PathTableBodyContainer}
                        bodyComponent={PathTableBody}
                        tableComponent={TableDiv}
                        rowComponent={PathTableRow}
                        cellComponent={PathTableCell}
                        onClick={resetSelection}
                        onHeaderSelect={selectColumn}
                      />
                    )}
                  </TableColumnResizing>
                )}
              </TableColumnOrdering>
            )}
          </TableSorter>
        )}
      </TableColumnVisibility>
    </PathTableContext.Provider>
  );
};

interface IPathTableContext {
  sorting: string;
  direction: "ASC" | "DESC";
  cwd?: Path;
}

const PathTableContext = React.createContext<IPathTableContext>({
  sorting: PathTableColumn.Name,
  direction: "ASC",
});

const PathTableBodyContainer = React.forwardRef((props: VirtualizedTableBodyContainerProps<Path>, ref: any) => {
  const context = useContext(PathTableContext);
  const drop = usePathDrop(context.cwd);
  const selection = useMultipleSelection(context.cwd?.storage.selectedPaths ?? new Selection());
  return (
    <StyledVirtualizedTableSelectionBox
      rowHeight={PathTableRowHeight}
      rows={props.rows}
      onSelect={selection.selectMultiple}
    >
      <StyledVirtualizedBody {...props} {...drop} ref={ref} />
    </StyledVirtualizedTableSelectionBox>
  );
});
PathTableBodyContainer.displayName = "PathTableBodyContainer";

const StyledVirtualizedTableSelectionBox = styled(VirtualizedTableSelectionBox)`
  flex: 1;
  overflow: hidden;
`;

const StyledVirtualizedBody = styled(VirtualizedBody)`
  height: 100%;
`;

const PathTableBody: React.FC<VirtualizedTableBodyComponentProps<Path>> = (props) => {
  const context = useContext(PathTableContext);
  const selection = useMultipleSelection(context.cwd?.storage.selectedPaths ?? new Selection());
  return (
    <VirtualizedTableSelectionBox {...props} onSelect={selection.selectMultiple}>
      <TableDivBody {...props} />
    </VirtualizedTableSelectionBox>
  );
};

const PathTableColumns: TableColumn[] = [
  { name: PathTableColumn.Name, title: "Name", width: 250 },
  { name: PathTableColumn.DateModified, title: "Date modified", width: 150 },
  { name: PathTableColumn.Type, title: "Type", width: 120 },
  { name: PathTableColumn.CreatedBy, title: "Created by", width: 150 },
  { name: PathTableColumn.ModifiedBy, title: "Modified by", width: 150 },
  { name: PathTableColumn.Size, title: "Size", width: 100 },
];

const PathTableColumnsWithEmpty: TableColumn[] = [
  { name: PathTableColumn.None, title: "#", width: 50 } as TableColumn,
].concat(PathTableColumns);

const PathTableHeader: React.FC<TableHeaderComponentProps> = ({ column, style, onClick, children, ...props }) => {
  const context = useContext(PathTableContext);

  const handleClick = useCallback(() => {
    onClick(column);
  }, [column, onClick]);

  return (
    <StyledPathTableHeader {...props} column={column} style={style} onClick={handleClick}>
      {column.name === PathTableColumn.None ? <Icon icon={Icons.Search} title={"Search relevance"} /> : children}
      {column.name === context.sorting && <PathSortingIcon direction={context.direction} />}
    </StyledPathTableHeader>
  );
};

const StyledPathTableHeader = styled(TableDivHeader)`
  padding: ${({ column }) => (column.name === PathTableColumn.Name ? "10px 10px 10px 50px" : "10px")};
`;

const PathSortingIcon = styled(SortingIcon)`
  margin: 0 10px 0 auto;
`;

let PathTableRow: React.FC<VirtualizedTableRowComponentProps<Path>> = ({ row: path, children, ...props }) => {
  const drag = usePathDrag(path);
  const drop = usePathDrop(path);

  const [menu, onContextMenu] = useContextMenu(PathMenu, { props: { path } });
  return (
    <StyledPathTableRow
      {...props}
      {...drag}
      {...drop}
      row={path}
      selected={path.selected}
      highlighted={Boolean(menu)}
      onContextMenu={onContextMenu}
    >
      {children}
      {menu}
    </StyledPathTableRow>
  );
};
PathTableRow = observer(PathTableRow);

const PathTableRowHeight = 35;

const StyledPathTableRow = styled(TableDivRow)<VirtualizedTableRowComponentProps>`
  user-select: none;
  ${DropStyle};
`;

let PathTableCell: React.FC<TableCellComponentProps<Path>> = ({ column, row: path, rowIndex, value, style }) => {
  const navigateTo = usePathNavigate();
  const selection = useMultipleSelection(path.storage.selectedPaths);
  const handleClick = useDoubleClick(select, open);

  async function select(e: React.MouseEvent) {
    selection.select(path);
  }

  async function open(e: React.MouseEvent) {
    if (path.type === PathType.Folder || path.type === PathType.Mount) {
      navigateTo(path);
    }
  }

  const cellProps: PathTableCellProps = {
    row: path,
    rowIndex,
    column,
    style,
    onClick: handleClick,
  };

  if (column.name === PathTableColumn.None) {
    return <TableDivCell {...cellProps} />;
  }

  if (column.name === PathTableColumn.Name) {
    return <PathNameCell {...cellProps} />;
  }

  if (column.name === PathTableColumn.DateModified) {
    return <PathDateModifiedCell {...cellProps} />;
  }

  if (column.name === PathTableColumn.Type) {
    return <PathTypeCell {...cellProps} />;
  }

  if (column.name === PathTableColumn.Size) {
    return <PathSizeCell {...cellProps} />;
  }

  return <TableDivCell {...cellProps}>{value}</TableDivCell>;
};
PathTableCell = observer(PathTableCell);

interface PathTableCellProps {
  column: TableColumn;
  row: Path;
  rowIndex: number;
  style: React.CSSProperties;
  width?: number;
  className?: string;
  onClick(e: React.MouseEvent): void;
}

const PathNameCell: React.FC<PathTableCellProps> = ({ row: path, ...props }) => {
  const defaultThumbnail = useMemo(() => getDefaultPathThumbnail(path), [path]);
  return (
    <TableDivCell row={path} {...props}>
      <PathImage src={path.thumbnail} fallbacks={[path.generatedThumbnail, defaultThumbnail]} />

      {!path.canWrite() && <PathTableLockIcon />}
      {path.name}
    </TableDivCell>
  );
};

const PathImage = styled(Picture)`
  width: 25px;
  height: 15px;
  vertical-align: middle;
  margin: -2px 10px 0 5px;
  text-align: left;
  object-fit: contain;
`;

const PathDateModifiedCell: React.FC<PathTableCellProps> = (props) => {
  const path = props.row;
  return <TableDivCell {...props}>{path.dateModified ? <DateTime date={path.dateModified} /> : ""}</TableDivCell>;
};

const PathTypeCell: React.FC<PathTableCellProps> = (props) => {
  const path = props.row;
  return <TableDivCell {...props}>{path.kind}</TableDivCell>;
};

const PathSizeCell: React.FC<PathTableCellProps> = (props) => {
  const path = props.row;
  return (
    <StyledFileSizeCell {...props}>{path.type !== PathType.Folder && <FileSize size={path.size} />}</StyledFileSizeCell>
  );
};

const StyledFileSizeCell = styled(TableDivCell)`
  text-align: right;
`;

const PathTableLockIcon = styled(Icon).attrs({
  icon: Icons.Lock,
})`
  left: 32px;
  bottom: 5px;
  position: absolute;
  width: 10px;
  font-size: 10pt;
  color: ${({ theme }) => theme.colors.errorText};
  vertical-align: middle;
`;

export default observer(PathTable);
