import { observer } from "mobx-react";
import React from "react";
import useDialog from "../hooks/useDialog";
import usePermission from "../hooks/usePermission";
import { Commands } from "../state/commands/Provider";
import { IAddUserToGroupCommand } from "../state/commands/types/AddUserToGroupCommand";
import { IDeleteGroupCommand, IDeleteGroupCommandAllowedArguments } from "../state/commands/types/DeleteGroupCommand";
import { IRenameGroupCommand, IRenameGroupCommandAllowedArguments } from "../state/commands/types/RenameGroupCommand";
import Group from "../state/Group";
import GroupAddUserDialog from "./GroupAddUserDialog";
import GroupDeleteConfirmation from "./GroupDeleteConfirmation";
import GroupFormDialog from "./GroupFormDialog";
import { Icons } from "./Icon";
import Loader from "./Loader";
import Menu from "./Menu";
import { MenuIconItem, MenuItemDivider } from "./MenuItem";

export interface GroupMenuProps {
  group: Group;
}

const GroupMenu: React.FC<GroupMenuProps> = ({ group }) => {
  const addUserDialog = useDialog(GroupAddUserDialog, { group, suggestions: group.storage.users.items });
  const renameDialog = useDialog(GroupFormDialog, { groups: group.storage.groups, group });
  const deleteDialog = useDialog(GroupDeleteConfirmation, { group });

  function addUserToGroup() {
    addUserDialog.show();
  }

  function renameGroup() {
    renameDialog.show();
  }

  function deleteGroup() {
    deleteDialog.show();
  }

  const canAddUsers = usePermission(
    () => group.storage.commands.allowed<IAddUserToGroupCommand>(Commands.AddUserToGroup),
    [group]
  );
  const canRename = usePermission(
    () =>
      group.storage.commands.allowed<IRenameGroupCommand, IRenameGroupCommandAllowedArguments>(Commands.RenameGroup, {
        group,
      }),
    [group]
  );
  const canDelete = usePermission(
    () =>
      group.storage.commands.allowed<IDeleteGroupCommand, IDeleteGroupCommandAllowedArguments>(Commands.DeleteGroup, {
        group,
      }),
    [group]
  );

  const loading = canAddUsers === "loading" || canRename === "loading" || canDelete === "loading";
  if (loading) {
    return (
      <Menu>
        <Loader />
      </Menu>
    );
  }

  const visible = canAddUsers === "allowed" || canRename === "allowed" || canDelete === "allowed";
  if (!visible) {
    return null;
  }

  return (
    <Menu>
      <MenuIconItem icon={Icons.User} visible={canAddUsers === "allowed"} onClick={addUserToGroup}>
        Add Group User
      </MenuIconItem>
      <MenuItemDivider visible={canRename === "allowed" || canDelete === "allowed"} />
      <MenuIconItem icon={Icons.Edit} visible={canRename === "allowed"} onClick={renameGroup}>
        Rename
      </MenuIconItem>
      <MenuIconItem icon={Icons.Remove} visible={canDelete === "allowed"} onClick={deleteGroup}>
        Delete
      </MenuIconItem>
    </Menu>
  );
};

export default observer(GroupMenu);
