import React from "react";
import useDialogForm from "../hooks/useDialogForm";
import { Checkpoint } from "../state/Checkpoints";
import { Commands } from "../state/commands/Provider";
import Path from "../state/Path";
import ConfirmationDialog from "./ConfirmationDialog";
import { observer } from "mobx-react";
import { IDeleteAllCheckpointsCommand } from "../state/commands/types/DeleteAllCheckpointsCommand";
import ImportantText from "./ImportantText";
import Warning from "./Warning";

export interface CheckpointsDeleteForFolderDialogProps {
  path: Path;
}

const CheckpointsDeleteForFolderDialog: React.FC<CheckpointsDeleteForFolderDialogProps> = ({ path }) => {
  const form = useDialogForm({
    fields: {},
    onSubmit: confirm,
  });

  async function confirm() {
    const command = path.storage.commands.get<IDeleteAllCheckpointsCommand>(Commands.DeleteAllCheckpoints);
    if (command) {
      return command.execute({ path });
    }
  }

  return (
    <ConfirmationDialog
      title={"Delete Checkpoints"}
      loading={form.loading}
      onConfirm={form.submit}
      onCancel={form.cancel}
    >
      <Warning>
        <p>
          Are you sure you want to recursively delete all Checkpoints of all files in the directory structure below the{" "}
          "<ImportantText>{path.name}</ImportantText>" directory?
        </p>
        <p>
          This action cannot be undone. <br />
          This action will skip checkpoints that you do not have permissions to delete.
        </p>
      </Warning>
    </ConfirmationDialog>
  );
};

export default observer(CheckpointsDeleteForFolderDialog);
