import { StatusType } from "@omniverse/api/data";
import { Commands } from "../Provider";
import {
  IDownloadCheckpointCommandAllowedArguments,
  IDownloadCheckpointCommandArguments,
} from "../types/DownloadCheckpointCommand";
import { NucleusCommand } from "./index";
import { nanoid } from "nanoid";
import { openURL } from "../../../util/Browser";
import { wait } from "../../../util/Timeout";
import { join, getNameWithoutExtension, getExtension } from "../../../util/Path";

export default class NucleusDownloadCheckpointCommand extends NucleusCommand<
  IDownloadCheckpointCommandArguments,
  IDownloadCheckpointCommandAllowedArguments
> {
  name = Commands.DownloadCheckpoint;

  public async allowed(path: IDownloadCheckpointCommandAllowedArguments): Promise<boolean> {
    return this.provider.supportsVersioning;
  }

  public async execute({ path, checkpoint }: IDownloadCheckpointCommandArguments): Promise<void> {
    console.log(`[${this.provider.name}] Download checkpoint #${checkpoint} for ${path.path}`);

    if (await this.isPath2Supported()) {
      const link = await this.provider.linkGenerator.createCheckpointDownloadLink(path.path, checkpoint);
      openURL(link, { fileName: path.name });
    } else {
      return this.provider.busyContext.run(async () => {
        const connection = await this.provider.getConnection();

        const homeDir = `/Users/${path.storage.session.username}/`;
        const name = getNameWithoutExtension(path.name);
        const ext = getExtension(path.name);
        const copiedPath = join(homeDir, `${name}_checkpoint_${checkpoint}_${nanoid(6)}.${ext}`);

        const res = await connection.copy2({
          paths_to_copy: [
            {
              src: { path: path.path, checkpoint: checkpoint },
              dst: {
                path: copiedPath,
              },
            },
          ],
        });

        if (res.status !== StatusType.OK && res.responses[0] !== StatusType.OK) {
          console.error(res);
          throw new Error("Checkpoint download failed");
        }

        const link = await path.storage.provider.linkGenerator.createDownloadLink({
          path: copiedPath,
          storage: path.storage.name,
        });

        openURL(link, { fileName: path.name });

        await wait(10000);
        const deleteCopy = await connection.delete2({ paths_to_delete: [{ path: copiedPath }] });
        if (deleteCopy.status !== StatusType.OK && deleteCopy.responses[0] !== StatusType.OK) {
          console.error(deleteCopy);
          throw new Error("Checkpoint download cleanup failed");
        }
      });
    }
  }

  private async isPath2Supported(): Promise<boolean> {
    // If path2 method doesn't exist then error would 404,
    // we pass not parameters and method exist it would return 400
    try {
      const res = await fetch(`${this.provider.linkGenerator.lftAddress}/path2/`);
      return res.status !== 404;
    } catch {
      return false;
    }
  }
}
