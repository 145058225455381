import { useCallback, useState } from "react";
import { TableColumn } from "../components/Table";
import {ResizableTableColumn} from "../components/TableColumnResizing";
import User from "../state/User";
import { compareBoolean } from "../util/Boolean";

enum UserTableColumn {
  Name = "name",
  FirstName = "firstName",
  LastName = "lastName",
  Email = "email",
  Provider = "provider",
  Admin = "admin",
  ReadOnly = "nucleusRo",
  Disabled = "disabled",
}

const columns: ResizableTableColumn[] = [
  { name: UserTableColumn.Name, title: "User", width: 250, minWidth: 100 },
  { name: UserTableColumn.FirstName, title: "First Name", width: 200 },
  { name: UserTableColumn.LastName, title: "Last Name", width: 200 },
  { name: UserTableColumn.Email, title: "Email", width: 100 },
  { name: UserTableColumn.Provider, title: "Account Type", width: 100 },
  { name: UserTableColumn.Admin, title: "Admin", width: 75 },
  { name: UserTableColumn.ReadOnly, title: "Read Only", width: 100 },
  { name: UserTableColumn.Disabled, title: "Disabled", width: 80 },
];

export default function useUserTable() {
  const [sorting, setSorting] = useState(UserTableColumn.Name);
  const [direction, setDirection] = useState<"ASC" | "DESC">("ASC");

  const sortRows = useCallback(
    (rows: User[]): User[] => {
      return [...rows].sort((a, b) => {
        const comparisonDirection = direction === "ASC" ? 1 : -1;
        if (sorting === UserTableColumn.Name) {
          return comparisonDirection * a.name.localeCompare(b.name);
        }

        if (sorting === UserTableColumn.FirstName) {
          if (!a.profile?.firstName) {
            return comparisonDirection;
          }
          if (!b.profile?.firstName) {
            return -comparisonDirection;
          }
          return comparisonDirection * a.profile.firstName.localeCompare(b.profile.firstName);
        }

        if (sorting === UserTableColumn.LastName) {
          if (!a.profile?.lastName) {
            return comparisonDirection;
          }
          if (!b.profile?.lastName) {
            return -comparisonDirection;
          }
          return comparisonDirection * a.profile.lastName.localeCompare(b.profile.lastName);
        }

        if (sorting === UserTableColumn.Email) {
          if (!a.profile?.email) {
            return comparisonDirection;
          }
          if (!b.profile?.email) {
            return -comparisonDirection;
          }
          return comparisonDirection * a.profile.email.localeCompare(b.profile.email);
        }

        if (sorting === UserTableColumn.Provider) {
          if (!a.profile?.provider) {
            return comparisonDirection;
          }
          if (!b.profile?.provider) {
            return -comparisonDirection;
          }
          return comparisonDirection * a.profile.provider.localeCompare(b.profile.provider);
        }

        if (sorting === UserTableColumn.Disabled) {
          return comparisonDirection * compareBoolean(a.profile?.enabled, b.profile?.enabled);
        }

        if (sorting === UserTableColumn.Admin) {
          return comparisonDirection * compareBoolean(a.profile?.admin, b.profile?.admin);
        }

        if (sorting === UserTableColumn.ReadOnly) {
          return comparisonDirection * compareBoolean(a.profile?.nucleusRo, b.profile?.nucleusRo);
        }

        return 0;
      });
    },
    [sorting, direction]
  );

  const onHeaderSelect = useCallback(
    (column: TableColumn) => {
      setDirection(sorting === column.name ? (direction === "ASC" ? "DESC" : "ASC") : "ASC");
      setSorting(column.name as UserTableColumn);
    },
    [sorting, direction]
  );

  return {
    columns,
    sorting,
    direction,
    sortRows,
    onHeaderSelect,
  };
}
