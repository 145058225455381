import { PathPermission, PathType as NucleusPathType, StatusType } from "@omniverse/api/data";
import { PathType } from "../../Path";
import { Commands } from "../Provider";
import {
  IDeleteAllCheckpointsCommandAllowedArguments,
  IDeleteAllCheckpointsCommandArguments,
} from "../types/DeleteAllCheckpointsCommand";
import { NucleusCommand } from "./index";
import { listRecursively } from "./util";

export default class NucleusDeleteAllCheckpointsCommand extends NucleusCommand<
  IDeleteAllCheckpointsCommandArguments,
  IDeleteAllCheckpointsCommandAllowedArguments
> {
  name = Commands.DeleteAllCheckpoints;

  public async allowed({ path }: IDeleteAllCheckpointsCommandAllowedArguments): Promise<boolean> {
    return this.provider.supportsVersioning && path.canWrite();
  }

  public async execute({ path }: IDeleteAllCheckpointsCommandArguments) {
    return this.provider.busyContext.run(async () => {
      let tasks = [];
      if (path.type === PathType.Folder) {
        console.log(`[${this.provider.name}] Find all files in ${path.path} to delete checkpoints`);
        for await (const entry of listRecursively(this.provider.connectionPool, path.path)) {
          if (entry.path_type !== NucleusPathType.Asset || entry.mounted || !entry.acl.includes(PathPermission.Write)) {
            continue;
          }
          tasks.push(this.deleteAllCheckpoints(entry.path));
        }
      } else {
        tasks.push(this.deleteAllCheckpoints(path.path));
      }

      await Promise.all(tasks);
    });
  }

  private async deleteAllCheckpoints(path: string): Promise<void> {
    console.log(`[${this.provider.name}] Delete checkpoints for ${path}`);

    const connection = await this.provider.getConnection();
    const response = await connection.getCheckpoints({ path: { path: path } });
    if (response.status !== StatusType.OK) {
      throw new Error(`Failed to download checkpoints from the server for ${path}: ${response.status}.`);
    }

    console.log(`[${this.provider.name}] Found ${response.checkpoints.length} checkpoints for ${path}, deleting...`);
    const deleteResponse = await connection.delete2({
      paths_to_delete: response.checkpoints.map((checkpoint) => ({
        path: path,
        checkpoint: checkpoint.checkpoint_id,
      })),
    });

    if (deleteResponse.status !== StatusType.OK) {
      throw new Error(`Failed to delete checkpoints from the server for ${path}: ${deleteResponse.status}.`);
    }
  }
}
