import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter, HashRouter } from "react-router-dom";
import BusyAppQuitDialog from "./components/BusyAppQuitDialog";
import Loader from "./components/Loader";
import TitleBar from "./components/TitleBar";
import Navigator from "./Navigator";
import Application from "./state/Application";
import { isElectron } from "./util/Electron";
import { joinURL } from "./util/Path";
import { getBaseURL } from "./util/URL";

const electron = isElectron();
if (electron) {
  Object.assign(console, window.log.functions);
}

const basename = getBaseURL();

function App({ initializedApp }: { initializedApp?: Application }) {
  const [loading, setLoading] = useState(!initializedApp);
  const [app, setApp] = useState<Application | undefined>(initializedApp);
  const [requestedToQuit, setRequestedToQuit] = useState(false);

  useEffect(() => {
    if (!app) {
      console.time("Initialize application");
      initializeApplication()
        .then((app) => {
          setApp(app);
          console.timeEnd("Initialize application");
        })
        .finally(() => setLoading(false));
    }
  }, [app]);

  useEffect(() => {
    const beforeUnload: OnBeforeUnloadEventHandler = (event) => {
      if (requestedToQuit) {
        return null;
      }

      if (app?.busy) {
        if (isElectron()) {
          setRequestedToQuit(true);
        }
        const message =
          "Do you really want to close this page? You still have some active tasks, this may lead to unsaved changes.";
        event.returnValue = true;
        return message;
      }

      return null;
    };
    window.addEventListener("beforeunload", beforeUnload);

    return () => {
      window.removeEventListener("beforeunload", beforeUnload);
    };
  }, [app, requestedToQuit]);

  const onQuitDialogClose = useCallback(() => setRequestedToQuit(false), [setRequestedToQuit]);

  if (loading) {
    return <Loader />;
  }

  const Router: React.ComponentType<{ basename?: string }> = electron ? HashRouter : BrowserRouter;
  return (
    <Router basename={basename}>
      {isElectron() && <TitleBar />}
      <Navigator app={app}>
        {requestedToQuit && <BusyAppQuitDialog onClose={onQuitDialogClose} onConfirm={window.app.quit} />}
      </Navigator>
    </Router>
  );
}

export async function initializeApplication(): Promise<Application> {
  let settings;
  try {
    const response = await fetch(joinURL(basename, "/settings.json"));
    settings = await response.json();
  } catch (error) {
    // Settings don't exist or can't be loaded -- initialize the app from local storage.
    return Application.load({ basename, useBasenamePrefix: false });
  }

  const etag = localStorage.getItem("etag");
  if (etag === settings.etag) {
    // These application settings didn't change since the last deployment.
    // Use localStorage state instead of initializing the app from the settings.
    return Application.load({ basename, useBasenamePrefix: false });
  }

  // The etag is changed.
  // We need to bust the local storage and reinitialize the application based on the server settings.
  localStorage.clear();
  localStorage.setItem("etag", settings.etag);

  const app = Application.fromJSON(settings, { basename, useBasenamePrefix: false });
  app.useLocalStorage();
  return app;
}

export default App;
