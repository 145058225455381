import { observer } from "mobx-react";
import React from "react";
import useError from "../hooks/useError";
import useNotification from "../hooks/useNotification";
import usePermission from "../hooks/usePermission";
import useQuery from "../hooks/useQuery";
import useSubmit from "../hooks/useSubmit";
import { Commands } from "../state/commands/Provider";
import { IAddUserToGroupCommand } from "../state/commands/types/AddUserToGroupCommand";
import { IGetUserGroupsCommand } from "../state/commands/types/GetUserGroupsCommand";
import { IGetUserProfileCommand } from "../state/commands/types/GetUserProfileCommand";
import { IRemoveUserFromGroupCommand } from "../state/commands/types/RemoveUserFromGroupCommand";
import {
  ISetUserProfileCommand,
  ISetUserProfileCommandAllowedArguments,
} from "../state/commands/types/SetUserProfileCommand";
import Group from "../state/Group";
import User, { Profile } from "../state/User";
import { ReactComponent as UserSVG } from "../static/user.svg";
import ErrorMessage from "./ErrorMessage";
import GroupList from "./GroupList";
import Loader from "./Loader";
import ProfileForm from "./ProfileForm";
import ProfileSubmitNotification from "./ProfileSubmitNotification";
import RightPanel from "./RightPanel";
import RightPanelContainer from "./RightPanelContainer";
import RightPanelHeader from "./RightPanelHeader";
import RightPanelHeaderImage from "./RightPanelHeaderImage";
import RightPanelRow from "./RightPanelRow";
import RightPanelScrollable from "./RightPanelScrollable";
import RightPanelTitle from "./RightPanelTitle";
import RightPanelToggleable from "./RightPanelToggleable";
import UserAdminPanel from "./UserAdminPanel";
import { mapGroupsToSuggestions } from "./UserGroupAutoComplete";

export interface UserPanelProps {
  user: User;
}

const UserPanel: React.FC<UserPanelProps> = ({ user }) => {
  const profileNotification = useNotification(ProfileSubmitNotification, { user });
  const getProfileQuery = useQuery(
    async () => {
      const command = user.storage.commands.get<IGetUserProfileCommand>(Commands.GetUserProfile);
      if (await command?.allowed()) {
        return command?.execute({ user });
      }
    },
    { keys: [user] }
  );

  const { submit: submitProfile, loading: loadingProfile, error: profileFormError } = useSubmit(
    async (profile: Profile) => {
      const command = user.storage.commands.get<ISetUserProfileCommand>(Commands.SetUserProfile);
      if (await command?.allowed({ user })) {
        return command?.execute({ user, profile });
      }
    },
    {
      onSuccess: () => profileNotification.show(),
    }
  );

  const [groupError, setGroupError, clearGroupError] = useError();
  const { submit: addGroup } = useSubmit(
    async (groupName: string) => {
      const group = new Group(groupName, user.storage);
      const command = user.storage.commands.get<IAddUserToGroupCommand>(Commands.AddUserToGroup);
      if (await command?.allowed()) {
        return command?.execute({ user, group });
      }
    },
    {
      onSubmit: clearGroupError,
      onError: setGroupError,
    }
  );

  const { submit: deleteGroup } = useSubmit(
    async (groupName: string) => {
      const group = new Group(groupName, user.storage);
      const command = user.storage.commands.get<IRemoveUserFromGroupCommand>(Commands.RemoveUserFromGroup);
      if (await command?.allowed()) {
        return command?.execute({ user, group });
      }
    },
    {
      onSubmit: clearGroupError,
      onError: setGroupError,
    }
  );
  const { ok: groupsLoaded } = useQuery(
    async () => {
      const command = user.storage.commands.get<IGetUserGroupsCommand>(Commands.GetUserGroups);
      if (await command?.allowed()) {
        return command?.execute({ user });
      }
    },
    {
      keys: [user],
      onSubmit: clearGroupError,
      onError: setGroupError,
    }
  );

  const supportsProfile = usePermission(
    () => user.storage.commands.allowed<IGetUserProfileCommand>(Commands.GetUserProfile),
    [user]
  );
  const supportsProfileEdit = usePermission(
    () =>
      user.storage.commands.allowed<ISetUserProfileCommand, ISetUserProfileCommandAllowedArguments>(
        Commands.SetUserProfile,
        { user }
      ),
    [user]
  );
  const supportsUserGroups = usePermission(
    () => user.storage.commands.allowed<IGetUserGroupsCommand>(Commands.GetUserGroups),
    [user]
  );
  const supportsUserGroupsEdit = usePermission(async () => {
    const allowedToAddGroupUsers = await user.storage.commands.allowed<IAddUserToGroupCommand>(Commands.AddUserToGroup);
    return Boolean(allowedToAddGroupUsers && user.profile?.activated);
  }, [user.profile]);

  return (
    <RightPanel>
      <RightPanelHeader>
        <RightPanelRow>
          <RightPanelHeaderImage src={UserSVG} />
          <RightPanelTitle>{user.name}</RightPanelTitle>
        </RightPanelRow>
      </RightPanelHeader>

      <RightPanelScrollable>
        {supportsProfile === "allowed" && (
          <RightPanelToggleable label={"Profile"}>
            {getProfileQuery.error && <ErrorMessage>{getProfileQuery.error}</ErrorMessage>}
            {user.loading ? (
              <Loader>Loading...</Loader>
            ) : (
              <RightPanelContainer>
                {user.profile && (
                  <ProfileForm
                    key={user.name}
                    initial={user.profile}
                    loading={loadingProfile}
                    readonly={supportsProfileEdit !== "allowed"}
                    error={profileFormError}
                    onSubmit={submitProfile}
                  />
                )}
                <UserAdminPanel user={user} />
              </RightPanelContainer>
            )}
          </RightPanelToggleable>
        )}

        {supportsUserGroups === "allowed" && (
          <RightPanelToggleable label={"Assigned Groups"}>
            <RightPanelScrollable>
              {groupError && <ErrorMessage>{groupError}</ErrorMessage>}
              <GroupList
                items={user.groupNames}
                suggestions={mapGroupsToSuggestions(user.storage.groups.items)}
                disabled={!groupsLoaded || supportsUserGroupsEdit !== "allowed"}
                allowAny={false}
                onAdd={addGroup}
                onDelete={deleteGroup}
              />
            </RightPanelScrollable>
          </RightPanelToggleable>
        )}
      </RightPanelScrollable>
    </RightPanel>
  );
};

export default observer(UserPanel);
