import { Path as NucleusPath } from "@omniverse/api/data";
import { Prefixes } from "@omniverse/search-grammar-parser";
import { StatusType } from "@omniverse/search/data";
import { toJS } from "mobx";
import { toShortISODate } from "../../../util/DateTime";
import { formatFileSize, parseFileSize, toBase64 } from "../../../util/File";
import { resizeImage } from "../../../util/Image";
import { OfflineModeError } from "../../../util/SessionErrors";
import Path from "../../Path";
import { PathSearchQuery } from "../../PathSearch";
import { Tag } from "../../Tags";
import { Commands } from "../Provider";
import {
  ISearchCommand,
  ISearchCommandArguments,
  ISearchDeserializeResult,
  ISearchSerializeOptions,
} from "../types/SearchCommand";
import { NucleusCommand } from "./index";
import { parseNucleusPath } from "./Nucleus";

export default class NucleusSearchCommand
  extends NucleusCommand<ISearchCommandArguments, void, Path[]>
  implements ISearchCommand {
  name: Commands.Search = Commands.Search;

  public async allowed(): Promise<boolean> {
    return true;
  }

  public async serialize(
    query: PathSearchQuery,
    { files = false, text = "" }: ISearchSerializeOptions = {}
  ): Promise<string> {
    if (text) {
      const prefixes = new Prefixes(text);
      await this.serializeVisualInfo(query, prefixes, { files });

      if (prefixes.any()) {
        return prefixes.toString();
      } else {
        return text;
      }
    }

    const prefixes = new Prefixes();
    if (query.name?.length) {
      prefixes.set("name", query.name);
    }
    if (query.description) {
      prefixes.set("description", query.description);
    }
    if (query.type) {
      prefixes.set("ext", query.type);
    }
    await this.serializeVisualInfo(query, prefixes, { files });
    if (query.createdBy) {
      prefixes.set("created_by", query.createdBy);
    }
    if (query.modifiedBy) {
      prefixes.set("modified_by", query.modifiedBy);
    }
    if (query.fileSize) {
      const fileSizeFilter = getFileSizeFilter(query.fileSizeComparator);
      const fileSize = parseFileSize(query.fileSize, "MB");
      if (fileSize) {
        prefixes.set(fileSizeFilter, formatFileSize(fileSize));
      } else {
        prefixes.set(fileSizeFilter, query.fileSize);
      }
    }
    if (query.dateStart) {
      const dateStart = toShortISODate(new Date(query.dateStart));
      prefixes.set("modified_after", dateStart);
    }
    if (query.dateEnd) {
      const dateEnd = toShortISODate(new Date(query.dateEnd));
      prefixes.set("modified_before", dateEnd);
    }
    return prefixes.toString();
  }

  protected async serializeVisualInfo(query: PathSearchQuery, prefixes: Prefixes, { files }: ISearchSerializeOptions) {
    if (query.tags) {
      for (const tag of query.tags) {
        prefixes.append("tag", tag);
      }
      const tags = new Set(prefixes.getAll("tag"));
      prefixes.set("tag", [...tags]);
    }

    if (query.sample && files) {
      prefixes.set("image", query.sample);
    }
    if (query.image && files) {
      const resizedImage = await resizeImage(query.image, maxImageSize.width, maxImageSize.height);
      if (resizedImage) {
        const image = await toBase64(resizedImage);
        prefixes.set("image", image);
      }
    }
  }

  public async deserialize(value: string): Promise<ISearchDeserializeResult> {
    const prefixes = new Prefixes(value);
    if (prefixes.any()) {
      let tags = prefixes.getAll("tag");
      if (tags) {
        tags = tags.map((fullName) => {
          const tag = Tag.fromFullName(fullName);
          if (tag.isAppearance) {
            return tag.name;
          } else {
            return tag.fullName;
          }
        });
      }

      const query: PathSearchQuery = {
        name: prefixes.getAll("name"),
        description: prefixes.get("description"),
        tags,
        type: prefixes.get("ext"),
        createdBy: prefixes.get("created_by"),
        modifiedBy: prefixes.get("modified_by"),
        fileSize: prefixes.get("larger_than") || prefixes.get("smaller_than"),
        fileSizeComparator: getFileSizeComparator(
          prefixes.has("larger_than") ? "larger_than" : prefixes.has("smaller_than") ? "smaller_than" : ""
        ),
        dateStart: prefixes.get("modified_after"),
        dateEnd: prefixes.get("modified_before"),
        sample: prefixes.get("sample"),
      };

      // Delete tags from visible text since they are represented with Tag components
      prefixes.delete("tag");

      // Image must be deleted from visible text because base64-strings can be too large
      prefixes.delete("image");
      const text = prefixes.toString();

      return {
        query,
        text,
      };
    } else {
      return {
        query: {},
        text: value,
      };
    }
  }

  public async execute({ search, query, parent, text = "" }: ISearchCommandArguments): Promise<Path[]> {
    if (!this.provider.session.established) {
      throw new OfflineModeError();
    }

    if (text) {
      console.log(`[${this.provider.name}] Search ${text} in ${parent.path}`);
    }
    console.log(`[${this.provider.name}] Search options in ${parent.path}:`);
    console.log(toJS(query));

    let queryString: string | undefined;

    if (this.provider.supportsAdvancedSearch) {
      queryString = await this.serialize(query, { files: true, text });
    } else if (query.name) {
      const [name] = query.name;
      queryString = name ?? "";
    } else {
      queryString = text;
    }

    const service = await this.provider.createSearchClient();
    try {
      const results = await service.find2({
        query: {
          name: queryString,
          parent: parent.path ?? "/",
          tags: query.tags,
        },
        token: this.provider.session.accessToken!,
      });
      if (results.status === StatusType.TokenExpired) {
        await this.provider.session.refresh();
        return await this.execute({ parent, query, search });
      }

      return await Promise.all(
        results.paths.map((data) => parseNucleusPath(data as NucleusPath, parent, this.provider.linkGenerator))
      );
    } finally {
      await service.transport.close();
    }
  }
}

function getFileSizeFilter(comparator?: string): string {
  switch (comparator) {
    case "Greater Than":
      return "larger_than";
    case "Less Than":
      return "smaller_than";
    default:
      return "smaller_than";
  }
}

function getFileSizeComparator(filter?: string): string | undefined {
  switch (filter) {
    case "larger_than":
      return "Greater Than";
    case "smaller_than":
      return "Less Than";
    default:
      return undefined;
  }
}

const maxImageSize = {
  width: 224,
  height: 224,
};
