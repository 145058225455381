import { action, computed, makeObservable, observable } from "mobx";
import Storage from "./Storage";
import User from "./User";

class Group {
  public static Admin = "gm";
  public static Users = "users";

  protected userMap: Map<string, User>;
  public loading: boolean;

  public constructor(public name: string, public readonly storage: Storage) {
    this.userMap = new Map<string, User>();
    this.loading = false;

    makeObservable<this, "userMap">(this, {
      name: observable,
      userMap: observable,
      loading: observable,
      readonly: computed,
      users: computed,
      usernames: computed,
      rename: action.bound,
      setUsers: action.bound,
      addUser: action.bound,
      removeUser: action.bound,
      clear: action.bound,
    });
  }

  public get readonly(): boolean {
    return this.name === Group.Users;
  }

  public get users(): User[] {
    return Array.from(this.userMap.values()).sort((a, b) => a.name.localeCompare(b.name));
  }

  public get usernames(): string[] {
    return this.users.map((user) => user.name).sort();
  }

  public rename(groupName: string): void {
    this.name = groupName;
  }

  public setUsers(users: User[]) {
    this.userMap = new Map<string, User>(users.map((user) => [user.name, user]));
  }

  public addUser(user: User): void {
    this.userMap.set(user.name, user);
  }

  public removeUser(user: User): void {
    this.userMap.delete(user.name);
  }

  public clear(): void {
    this.userMap.clear();
  }
}

export default Group;
