import { Credentials, SSO, Tokens } from "@omniverse/auth";
import { Auth, AuthStatus } from "@omniverse/auth/data";
import { AuthenticationResult } from "@omniverse/auth/react/AuthForm";
import { DiscoverySearchInfo, ServiceMeta } from "@omniverse/discovery";
import { InterfaceCapabilities } from "@omniverse/idl/schema";
import Stream from "@omniverse/idl/stream";
import { IpcRendererEvent } from "electron";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { action, computed, makeObservable, observable, toJS } from "mobx";
import { OfflineModeError } from "../../../util/SessionErrors";
import { IExternalAuth, ISession } from "../Provider";
import { createNucleusServiceClient } from "./NucleusDiscovery";

class NucleusSession implements ISession {
  public server: string;
  public username?: string;
  public accessToken?: string;
  public refreshToken?: string;
  public isSuperUser?: boolean;
  public isNucleusReadOnly?: boolean;
  protected expiresAt?: number;

  constructor(
    server: string,
    username?: string,
    accessToken?: string,
    refreshToken?: string,
    isSuperUser?: boolean,
    isNucleusReadOnly?: boolean
  ) {
    this.server = server;
    this.username = username;
    this.accessToken = accessToken;
    this.parseJWT(this.accessToken);

    this.refreshToken = refreshToken;
    this.isSuperUser = isSuperUser;
    this.isNucleusReadOnly = isNucleusReadOnly;

    makeObservable<this, "setAuthResult">(this, {
      server: observable,
      username: observable,
      accessToken: observable,
      refreshToken: observable,
      setAuthResult: action.bound,
      authenticate: action.bound,
      disconnect: action.bound,
      established: computed,
    });
  }

  public get established(): boolean {
    return Boolean(this.username && this.accessToken && this.refreshToken);
  }

  public get expired(): boolean {
    return Boolean(this.expiresAt && Date.now() >= this.expiresAt);
  }

  public static fromJSON(data: any): NucleusSession {
    return new NucleusSession(
      data.server,
      data.username,
      data.accessToken,
      data.refreshToken,
      data.isSuperUser,
      data.isNucleusReadOnly
    );
  }

  public static async fromStarfleet(server: string, token: string): Promise<NucleusSession> {
    const session = new NucleusSession(server);
    const sso = await session.createSSOClient();
    try {
      const response = await sso.auth({ type: "Starfleet", params: { id_token: token } });
      if (response.status === AuthStatus.OK) {
        session.setAuthResult(response);
        return session;
      } else {
        throw new Error(`Failed to authenticate with the Starfleet token: ${response.status}.`);
      }
    } finally {
      await sso.transport.close();
    }
  }

  public authenticate(
    username: string,
    accessToken?: string,
    refreshToken?: string,
    isSuperUser: boolean = false
  ): void {
    this.username = username;
    this.accessToken = accessToken;
    this.parseJWT(this.accessToken);
    this.refreshToken = refreshToken;
    this.isSuperUser = isSuperUser;
  }

  protected parseJWT(accessToken?: string): void {
    if (!accessToken) {
      this.expiresAt = undefined;
      return;
    }

    try {
      const payload: JwtPayload = jwtDecode(accessToken);
      if (payload.exp) {
        this.expiresAt = payload.exp * 1000;
      }
    } catch {
      this.expiresAt = undefined;
    }
  }

  /**
   * Opens an external browser to complete the authentication.
   * The service generates a unique `nonce` string to subscribe to authentication results returned
   * after the user finishes his authentication flow.
   */
  public openExternal(): IExternalAuth {
    return new NucleusExternalAuth(this);
  }

  public disconnect(): void {
    this.username = undefined;
    this.accessToken = undefined;
    this.expiresAt = undefined;
    this.refreshToken = undefined;
    this.isSuperUser = undefined;
    this.isNucleusReadOnly = undefined;
  }

  public async refresh(): Promise<void> {
    if (!this.refreshToken) {
      throw new OfflineModeError();
    }

    const tokens = await this.createTokensClient();
    try {
      const result = await tokens.refresh({ refresh_token: this.refreshToken });
      if (result.status === AuthStatus.OK) {
        this.setAuthResult(result);
      } else if (result.status === AuthStatus.Expired || result.status === AuthStatus.InvalidToken) {
        this.disconnect();
        throw new OfflineModeError();
      } else {
        throw new Error(`Unable to refresh the current session -- ${result.status}.`);
      }
    } finally {
      await tokens.transport.close();
    }
  }

  protected setAuthResult(result: Auth) {
    this.username = result.username!;
    this.accessToken = result.access_token!;
    this.parseJWT(this.accessToken);

    this.refreshToken = result.refresh_token!;
    this.isSuperUser = result.profile?.admin;
    this.isNucleusReadOnly = result.profile?.nucleus_ro;
  }

  public toJSON() {
    return toJS(this);
  }

  public async createSSOClient(): Promise<SSO> {
    try {
      return await createNucleusServiceClient(SSO, this.server, "SSO", { capabilities: { auth: 0 } });
    } catch (error) {
      throw new Error(
        "Could not connect to the authentication service. " +
          "Please check your network connection and the server status with administrator."
      );
    }
  }

  public async createTokensClient(): Promise<Tokens & DiscoverySearchInfo> {
    try {
      return await createNucleusServiceClient(Tokens, this.server, "TOKENS", { capabilities: { refresh: 0 } });
    } catch (error) {
      throw new Error(
        "Could not connect to the authentication service. " +
          "Please check your network connection and the server status with administrator."
      );
    }
  }

  public async createCredentialsClient(): Promise<Credentials & DiscoverySearchInfo> {
    try {
      return await createNucleusServiceClient(Credentials, this.server, "CREDENTIALS", { capabilities: { auth: 0 } });
    } catch (error) {
      throw new Error(
        "Could not connect to the authentication service. " +
          "Please check your network connection and the server status with administrator."
      );
    }
  }

  public async getExternalLoginURL(): Promise<string> {
    let credentials;
    try {
      credentials = await this.createCredentialsClient();
    } catch (error) {
      console.error(error);
      throw new Error(
        "Could not connect to the authentication service. " +
          "Please check your network connection and the server status with administrator."
      );
    } finally {
      if (credentials) credentials.transport.close();
    }

    let loginURL = credentials[ServiceMeta]?.login_url;
    if (!loginURL) {
      throw new Error(
        "Could not establish the address for the invitation form. Please contact the system administrator."
      );
    }

    loginURL = loginURL.replace("*", this.server);
    return loginURL;
  }
}

export class NucleusExternalAuth implements IExternalAuth {
  private static CANCELLED = Symbol("CANCELLED");

  protected cancelled: boolean;
  protected tokens?: Tokens & DiscoverySearchInfo;
  protected stream?: Stream<Auth>;
  private readonly cancellation: Promise<symbol>;
  private onLoopback?: (auth: AuthenticationResult) => void;
  private onCancel?: () => void;

  constructor(protected readonly session: NucleusSession) {
    this.cancelled = false;
    this.cancellation = new Promise((resolve) => {
      this.onCancel = () => resolve(NucleusExternalAuth.CANCELLED);
    });
  }

  public async run(): Promise<void> {
    console.log("Starting external Nucleus authentication...");

    const loginURL = await this.session.getExternalLoginURL();

    console.log("Got login form URL:", loginURL);
    try {
      this.tokens = await this.session.createTokensClient();
    } catch (error) {
      this.close();
      console.error(error);
      throw new Error(
        "Could not connect to the authentication service. " +
          "Please check your network connection and the server status with administrator."
      );
    }

    const supportsNonce = typeof this.tokens[InterfaceCapabilities]?.subscribe !== "undefined";
    if (supportsNonce) {
      await this.runNonceAuthentication(loginURL);
    } else {
      await this.runHttpLoopbackAuthentication(loginURL);
    }

    await window.app?.bringToFront();
  }

  protected async runNonceAuthentication(loginURL: string): Promise<void> {
    if (!this.tokens) {
      console.error("You must initialize the tokens API before calling this method.");
      throw new Error("Internal error. Please contact the system administrator.");
    }

    console.log(`Running nonce authentication for ${loginURL}...`);
    try {
      this.stream = await this.tokens.subscribe();
    } catch (error) {
      this.close();
      console.error(error);
      throw new Error("Failed to subscribe to the authentication results. Please contact the system administrator.");
    }

    if (this.cancelled) {
      this.close();
      throw new NucleusExternalAuthCancelled();
    }

    let nonce;
    try {
      const start = await Promise.race([this.stream.read(), this.cancellation]);
      if (start === NucleusExternalAuth.CANCELLED) {
        this.close();
        throw new NucleusExternalAuthCancelled();
      }

      if (typeof start === "symbol") {
        throw new Error("Unexpected end of stream for authentication subscription.");
      }

      if (start.status !== AuthStatus.Subscribed) {
        throw new Error(`Unexpected subscription status -- ${start.status}`);
      }

      nonce = start.nonce!;
    } catch (error) {
      if (error instanceof NucleusExternalAuthCancelled) {
        throw error;
      }

      this.close();
      console.error(error);
      throw new Error("Failed to subscribe to the authentication results. Please contact the system administrator.");
    }

    try {
      const redirect = new URL(loginURL);
      redirect.searchParams.set("server", this.session.server);
      redirect.searchParams.set("nonce", nonce);
      window.open(redirect.toString(), "_self");

      const result: symbol | Auth = await Promise.race([this.stream.read(), this.cancellation]);
      if (result === NucleusExternalAuth.CANCELLED) {
        this.close();
        throw new NucleusExternalAuthCancelled();
      }

      if (typeof result === "symbol") {
        throw new Error(`Unexpected end of stream for authentication results.`);
      }

      if (result.status !== AuthStatus.OK) {
        throw new Error(`Unexpected status for the authentication result -- ${result.status}`);
      }

      this.session.username = result.username!;
      this.session.accessToken = result.access_token!;
      this.session.refreshToken = result.refresh_token!;
      this.session.isSuperUser = result.profile?.admin;
      this.session.isNucleusReadOnly = result.profile?.nucleus_ro;
    } catch (error) {
      if (error instanceof NucleusExternalAuthCancelled) {
        throw error;
      }

      console.error(error);
      throw new Error("Failed to authenticate on the server. Please contact the system administrator.");
    } finally {
      this.close();
    }
  }

  protected async runHttpLoopbackAuthentication(loginURL: string): Promise<void> {
    console.log(`Running http redirect authentication for ${loginURL}...`);

    const loopback = await window.nucleus.auth.getLoopback();
    try {
      const redirect = new URL(loginURL);
      redirect.searchParams.set("server", this.session.server);
      redirect.searchParams.set("redirect", loopback);
      window.open(redirect.toString(), "_self");

      const subscription = new Promise<AuthenticationResult>((resolve) => {
        this.onLoopback = resolve;
      });
      window.nucleus.auth.subscribe(this.handleHttpLoopback);

      const result: symbol | AuthenticationResult = await Promise.race([subscription, this.cancellation]);
      if (typeof result === "symbol" || result === NucleusExternalAuth.CANCELLED) {
        this.close();
        throw new NucleusExternalAuthCancelled();
      }

      if (result.status !== AuthStatus.OK) {
        throw new Error(`Unexpected status for the authentication result -- ${result.status}`);
      }

      this.session.username = result.username!;
      this.session.accessToken = result.accessToken!;
      this.session.refreshToken = result.refreshToken!;
      this.session.isSuperUser = result.profile?.admin;
      this.session.isNucleusReadOnly = result.profile?.nucleus_ro;
    } catch (error) {
      if (error instanceof NucleusExternalAuthCancelled) {
        throw error;
      }

      console.error(error);
      throw new Error("Failed to authenticate on the server. Please contact the system administrator.");
    } finally {
      this.close();
    }
  }

  public cancel(): void {
    this.cancelled = true;
    this.onCancel?.();
  }

  private close(): void {
    this.stream?.close();
    this.tokens?.transport.close();
    window.nucleus?.auth?.unsubscribe(this.handleHttpLoopback);
  }

  private handleHttpLoopback = (event: IpcRendererEvent, auth: AuthenticationResult) => {
    this.onLoopback?.(auth);
  };
}

export class NucleusExternalAuthCancelled extends Error {
  constructor() {
    super("Cancelled.");
  }
}

export default NucleusSession;
