import React from "react";
import styled from "styled-components";
import Icon, { Icons } from "./Icon";
import { DefaultTheme } from "./Theme";

export interface WarningProps {
  className?: string;
}

const Warning: React.FC<WarningProps> = ({ className, children }) => (
  <WarningContainer className={className}>
    <WarningIcon />
    <WarningText>{children}</WarningText>
  </WarningContainer>
);

const WarningContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.errorBackground};
  padding: 0.25rem 1rem 0.25rem 0;
`;
WarningContainer.defaultProps = {
  theme: DefaultTheme,
};

export const WarningIcon = styled(Icon).attrs({
  icon: Icons.Alert,
})`
  margin: 0 0.75rem;
  color: ${({ theme }) => theme.colors.errorText};
`;
WarningIcon.defaultProps = {
  theme: DefaultTheme,
};

export const WarningText = styled.span`
  color: ${({ theme }) => theme.colors.errorText};
  overflow-wrap: break-word;
  word-break: break-word;
`;
WarningText.defaultProps = {
  theme: DefaultTheme,
};

export default Warning;
