import { Credentials } from "@omniverse/auth";
import { useCallback } from "react";
import connect from "../Connection";

export interface CredentialServerCheck {
  ok: boolean;
  server: string;
  errors?: string[];
}

export default function useCredentialServerCheck() {
  return useCallback(async (server: string): Promise<CredentialServerCheck> => {
    if (!server) {
      return {
        ok: false,
        server,
        errors: ["You have to specify the server."],
      };
    }

    let resolvedServer = await getCanonicalName(server);
    if (!resolvedServer) {
      console.log(`Cannot resolve the hostname, proceed with ${server}...`);
      resolvedServer = server;
    }

    try {
      const connection = await connect(resolvedServer, Credentials, { auth: 0 });
      await connection.transport.close();
      return {
        ok: true,
        server: resolvedServer,
      };
    } catch (error) {
      console.log(error);
      return {
        ok: false,
        server,
        errors: ["Failed to connect to the server."],
      };
    }
  }, []);
}

export async function getCanonicalName(server: string): Promise<string> {
  if (["127.0.0.1", "localhost"].includes(server)) {
    return server;
  }

  try {
    const response = await fetch(`http://${server}/_sys/canonical-name-json`);
    const data = await response.json();
    return data.fqdn ? data.fqdn : "";
  } catch (error) {
    return "";
  }
}
