export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export function getMonthName(month: number): string {
  return months[month];
}

export const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export function getDayName(dayOfTheWeek: number): string {
  return days[dayOfTheWeek];
}

export function getDaysInMonth(month: number, year: number): number {
  // Using '0' as a day returns the last day of the last month specified in the date,
  // therefore we have to add 1 to the month number.
  return new Date(year, month + 1, 0).getDate();
}

export const daysInWeek = 7;

export function formatDateTime(value: Date): string {
  return value.toLocaleString();
}

export function parseRelativeDate(value: string, range: "at" | "since" = "at"): string {
  const date = new Date();

  switch (value) {
    case "Today":
      date.setUTCHours(0, 0, 0);
      break;
    case "Yesterday": {
      date.setUTCDate(date.getUTCDate() - 1);
      date.setUTCHours(0, 0, 0);
      break;
    }
    case "Last 7 days": {
      date.setUTCDate(date.getUTCDate() - 7);
      date.setUTCHours(0, 0, 0);
      break;
    }
    case "Last 30 days": {
      date.setUTCDate(date.getUTCDate() - 30);
      date.setUTCHours(0, 0, 0);
      break;
    }
    case "Last 90 days": {
      date.setUTCDate(date.getUTCDate() - 90);
      date.setUTCHours(0, 0, 0);
      break;
    }
    default:
      throw new Error("Invalid Date");
  }

  if (range === "since") {
    date.setUTCDate(date.getUTCDate() - 1);
  }

  return date.toISOString();
}

/**
 * Converts minutes to milliseconds.
 */
export function minutes(value: number): number {
  return value * 60 * 1000;
}

/**
 * Converts hours to milliseconds.
 */
export function hours(value: number): number {
  return value * minutes(60);
}

export function toShortISODate(date: Date): string {
  return date.toISOString().slice(0, 10);
}

export function toShortISODateTime(date: Date): string {
  return date.toISOString().slice(0, 16);
}