import React from "react";
import ConfirmationDialog from "./ConfirmationDialog";


export interface BusyAppQuitDialogProps {
  onClose: () => void;
  onConfirm: () => void;
}

const BusyAppQuitDialog: React.FC<BusyAppQuitDialogProps> = ({ onClose, onConfirm }) => {
  return (
    <ConfirmationDialog
      title={"Quit Navigator"}
      kind={"danger"}
      onConfirm={onConfirm}
      onCancel={onClose}
    >
      Do you really want to close Navigator? You still have some active tasks, this may lead to unsaved changes.
    </ConfirmationDialog>
  );
};

export default BusyAppQuitDialog;
