import { getCanonicalName } from "@omniverse/auth/react/hooks/CredentialServerCheck";
import React, { useContext, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { toPublicName } from "../state/commands/Provider";
import { isElectron } from "../util/Electron";
import Dialog from "./Dialog";
import { DialogContext } from "./Dialogs";
import DialogTitle from "./DialogTitle";
import FormSpinner from "./FormSpinner";
import NucleusExternalAuthForm from "./NucleusExternalAuthForm";
import NucleusLoginForm from "./NucleusLoginForm";
import NucleusRegistrationForm from "./NucleusRegistrationForm";
import OmniverseForm from "./OmniverseForm";

export interface NucleusAuthDialogProps {
  path?: string;
  serverName?: string;
}

const NucleusAuthDialog: React.FC<NucleusAuthDialogProps> = ({ path = "/", serverName }) => {
  const { hide } = useContext(DialogContext);

  const [registration, setRegistration] = useState("");
  const [loading, setLoading] = useState(true);
  const [initial, setInitial] = useState(() => ({
    server: serverName ? toPublicName(serverName) : "",
  }));
  useEffect(() => {
    if (!serverName) {
      setLoading(false);
      return;
    }

    setLoading(true);
    getCanonicalName(serverName)
      .then((server) => {
        if (server) setInitial({ server });
      })
      .finally(() => setLoading(false));
  }, [serverName]);

  const extras = useMemo(() => ({ path }), [path]);

  function goToRegistration(server: string) {
    setRegistration(server);
  }

  function goToLogIn() {
    setRegistration("");
  }

  if (loading) {
    return (
      <StyledAuthDialog onClose={hide}>
        <DialogTitle title={"Authenticate"} onClose={hide} />
        <OmniverseDialogForm>
          <FormSpinner />
        </OmniverseDialogForm>
      </StyledAuthDialog>
    );
  }

  return (
    <StyledAuthDialog onClose={hide}>
      <DialogTitle title={"Authenticate"} onClose={hide} />
      {isElectron() ? (
        <StyledNucleusExternalAuthForm path={path} serverName={initial.server} onClose={hide} />
      ) : registration ? (
        <StyledRegistrationForm server={registration} extras={extras} onSuccess={hide} onCancel={goToLogIn} />
      ) : (
        <StyledLoginForm initial={initial} extras={extras} onSuccess={hide} onRegister={goToRegistration} />
      )}
    </StyledAuthDialog>
  );
};

const StyledAuthDialog = styled(Dialog)`
  width: 275px;
  min-width: 275px;
  overflow: hidden;
`;

const StyledAuthDialogForm = css`
  border-radius: 0;
  box-shadow: none;
  min-height: 200px;
`;

const OmniverseDialogForm = styled(OmniverseForm)`
  ${StyledAuthDialogForm};
`;

const StyledLoginForm = styled(NucleusLoginForm)`
  ${StyledAuthDialogForm};
`;

const StyledRegistrationForm = styled(NucleusRegistrationForm)`
  ${StyledAuthDialogForm};
`;

const StyledNucleusExternalAuthForm = styled(NucleusExternalAuthForm)`
  ${StyledAuthDialogForm};
`;

export default NucleusAuthDialog;
