import { StatusType } from "@omniverse/api/data";
import { DeleteError } from "../../../util/PathErrors";
import { Commands } from "../Provider";
import { IDeleteCommandAllowedArguments, IDeleteCommandArguments } from "../types/DeleteCommand";
import { NucleusCommand } from "./index";
import { IDeleteAllCheckpointsCommand } from "../types/DeleteAllCheckpointsCommand";

export default class NucleusDeleteCommand extends NucleusCommand<
  IDeleteCommandArguments,
  IDeleteCommandAllowedArguments
> {
  public name = Commands.Delete;

  public async allowed({ path }: IDeleteCommandAllowedArguments): Promise<boolean> {
    return path.canWrite();
  }

  public async execute({ path }: IDeleteCommandArguments): Promise<void> {
    return this.provider.busyContext.run(async () => {
      console.log(`[${this.provider.name}] Delete ${path.path}`);

      const connection = await this.provider.getConnection();

      const canDeleteCheckpoints = await this.provider.commands.allowed(Commands.DeleteAllCheckpoints, { path });
      if (canDeleteCheckpoints) {
        const command = this.provider.commands.get<IDeleteAllCheckpointsCommand>(Commands.DeleteAllCheckpoints);
        if (command) {
          await command.execute({ path });
        }
      }

      const results = await connection.delete({ uri: path.path });

      for await (const result of results) {
        if (result.status === StatusType.Done || result.status === StatusType.InvalidPath) {
          break;
        }
        if (result.status !== StatusType.OK) {
          throw new DeleteError(result);
        }
      }

      if (path.parent) {
        path.parent.remove(path.path);
      }
    });
  }
}
