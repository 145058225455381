import { action, computed, makeObservable, observable } from "mobx";
import Storage from "./Storage";
import User from "./User";

class Users {
  protected map: Map<string, User>;
  public loading: boolean;

  public constructor(public readonly storage: Storage) {
    this.map = new Map<string, User>();
    this.loading = false;

    makeObservable<this, "map">(this, {
      map: observable,
      loading: observable,
      items: computed,
      names: computed,
      add: action.bound,
      set: action.bound,
      delete: action.bound,
      setLoading: action.bound,
    });
  }

  public get items(): User[] {
    return Array.from(this.map.values()).sort((a, b) => a.name.localeCompare(b.name));
  }

  public get names(): string[] {
    return this.items.map((user) => user.name);
  }

  public add(user: User): void {
    this.map.set(user.name, user);
  }

  public set(users: User[]): void {
    this.map = new Map<string, User>(users.map((user) => [user.name, user]));
  }

  public get(username: string): User | undefined {
    return this.map.get(username);
  }

  public delete(user: User): void {
    this.map.delete(user.name);
  }

  public setLoading(value: boolean): void {
    this.loading = value;
  }
}

export default Users;
