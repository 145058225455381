import { observer } from "mobx-react";
import React from "react";
import useDialogForm from "../hooks/useDialogForm";
import useInput from "../hooks/useInput";
import { Commands } from "../state/commands/Provider";
import { IMoveCommand } from "../state/commands/types/MoveCommand";
import Path from "../state/Path";
import Button from "./Button";
import ButtonGroup from "./ButtonGroup";
import Dialog from "./Dialog";
import DialogForm from "./DialogForm";
import DialogSpinner from "./DialogSpinner";
import DialogTitle from "./DialogTitle";
import FormErrorList from "./FormErrorList";
import FormGroup from "./FormGroup";
import ImportantText from "./ImportantText";
import Input from "./Input";
import Label from "./Label";
import Warning from "./Warning";

export interface PathRenameDialogProps {
  path: Path;
}

interface PathRenameDialogFields {
  name: string;
}

const PathRenameDialog: React.FC<PathRenameDialogProps> = ({ path }) => {
  const [name, setName] = useInput(path.name);
  const form = useDialogForm({
    fields: {
      name,
    },
    onSubmit: submit,
  });

  function submit({ name }: PathRenameDialogFields) {
    const command = path.storage.commands.get<IMoveCommand>(Commands.Move);
    if (command) {
      return command.execute({ source: [path], destination: path.parent!, newName: name });
    } else {
      throw new Error(`${Commands.Move} command is not supported.`);
    }
  }

  return (
    <Dialog onClose={form.cancel}>
      <DialogTitle title={"Rename Path"} onClose={form.cancel} />
      <DialogSpinner loading={form.loading} />
      <DialogForm>
        <FormErrorList errors={form.errors} />
        <FormGroup>
          <Warning>
            <p>
              Do you really want to rename "<ImportantText>{path.name}</ImportantText>"?
            </p>
            <p>
              This action cannot be undone. <br />
              The {path.type} and the checkpoints will be permanently deleted.
            </p>
          </Warning>
        </FormGroup>

        <FormGroup>
          <Label>
            New name:
            <Input autoFocus required name={"name"} disabled={form.loading} value={name} onChange={setName} />
          </Label>
        </FormGroup>
        <ButtonGroup>
          <Button kind={"attention"} disabled={form.loading} onClick={form.submit}>
            Submit
          </Button>
          <Button disabled={form.loading} onClick={form.cancel}>
            Cancel
          </Button>
        </ButtonGroup>
      </DialogForm>
    </Dialog>
  );
};

export default observer(PathRenameDialog);
