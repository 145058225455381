import React, { useEffect, useState } from "react";
import FormPageLayout from "../components/FormPageLayout";
import Loader from "../components/Loader";
import NucleusLoginForm from "../components/NucleusLoginForm";
import useStorageList from "../hooks/useStorageList";

const AdminPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [server, setServer] = useState<string | undefined>();
  const storages = useStorageList();

  useEffect(() => {
    try {
      const onlyOneServerAvailable = storages.readOnly && storages.items.length === 1;
      if (onlyOneServerAvailable) {
        const server = storages.items[0];
        setServer(server.name);
      }
    } finally {
      setLoading(false);
    }
  }, [storages]);

  if (loading) {
    return (
      <FormPageLayout>
        <Loader />
      </FormPageLayout>
    );
  }

  return (
    <FormPageLayout>
      <NucleusLoginForm forceCredentials initial={{ server }} />
    </FormPageLayout>
  );
};

export default AdminPage;
