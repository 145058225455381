import React, { useEffect, useMemo, useRef, useState } from "react";
import { Routes } from "react-router";
import { Route } from "react-router-dom";
import Dialogs from "./components/Dialogs";
import ErrorMessage from "./components/ErrorMessage";
import Loader from "./components/Loader";
import { ThemeProvider } from "./components/Theme";
import ApplicationContext, { ApplicationContextData } from "./context/ApplicationContext";
import AdminPage from "./pages/AdminPage";
import ContentLayout from "./pages/ContentLayout";
import InvitationPage from "./pages/InvitationPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import SSOPage from "./pages/SSOPage";
import Application from "./state/Application";
import "./static/css/fonts.css";
import "./static/font-awesome/css/all.min.css";
import "./transport";

export interface NavigatorProps {
  app?: Application;
  basename?: string;
}

function Navigator({ app: initialApp, basename, children }: React.PropsWithChildren<NavigatorProps>) {
  const [app, setApp] = useState<Application | null>(initialApp ?? null);
  const [error, setError] = useState<Error | null>(null);

  const initial = useRef({
    app: initialApp,
  });

  useEffect(() => {
    if (initial.current.app) {
      return;
    }

    Application.load({ basename })
      .then((app) => setApp(app))
      .catch((err) => {
        console.error(err);
        setError(err);
      });
  }, [basename]);

  useDefaultDND();

  const context = useMemo((): ApplicationContextData => ({ app: app! }), [app]);

  if (error) {
    return <ErrorMessage>{error!.toString()}</ErrorMessage>;
  }

  if (app) {
    return (
      <ApplicationContext.Provider value={context}>
        <ThemeProvider>
          <Dialogs>
            <Routes>
              <Route path={"/sso/:params"} element={<SSOPage />} />
              <Route path={"/admin"} element={<AdminPage />} />
              <Route path={"/reset-password"} element={<ResetPasswordPage />} />
              <Route path={"/invitation"} element={<InvitationPage />} />
              <Route path={"*"} element={<ContentLayout />} />
            </Routes>
          </Dialogs>
          {children}
        </ThemeProvider>
      </ApplicationContext.Provider>
    );
  }

  return <Loader />;
}

const useDefaultDND = () => {
  useEffect(() => {
    document.body.addEventListener("dragover", (e: DragEvent) => {
      e.preventDefault();
      e.dataTransfer!.dropEffect = "none";
      return false;
    });

    document.body.addEventListener("drop", (e) => {
      e.preventDefault();
      return false;
    });
  }, []);
};

export default Navigator;
