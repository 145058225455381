import styled from "styled-components";
import Icon, { Icons } from "./Icon";
import { DefaultTheme } from "./Theme";

const TreeLock = styled(Icon).attrs({ icon: Icons.Lock })`
  position: absolute;
  left: 25px;
  top: 0;
  bottom: 0;
  line-height: 30px;
  margin: auto;
  color: ${({ theme }) => theme.colors.errorText};
  text-shadow: -1px -1px 0 ${({ theme }) => theme.colors.background},
    1px -1px 0 ${({ theme }) => theme.colors.background}, -1px 1px 0 ${({ theme }) => theme.colors.background},
    1px 1px 0 ${({ theme }) => theme.colors.background};
  font-size: 14px;
`;
TreeLock.defaultProps = {
  theme: DefaultTheme,
};

export default TreeLock;
