import { PathPermission } from "@omniverse/api/data";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import usePathNavigate from "../hooks/usePathNavigate";
import usePermission from "../hooks/usePermission";
import ACL from "../state/ACL";
import { Commands } from "../state/commands/Provider";
import { IGetACLCommand } from "../state/commands/types/GetACLCommand";
import { IGetACLResolvedCommand } from "../state/commands/types/GetACLResolvedCommand";
import { IGetGroupsCommand } from "../state/commands/types/GetGroupsCommand";
import { IGetUsersCommand } from "../state/commands/types/GetUsersCommand";
import { ISetACLCommand } from "../state/commands/types/SetACLCommand";
import Group from "../state/Group";
import Path from "../state/Path";
import { ACLAtLevel } from "../state/ResolvedACL";
import { ReactComponent as GroupSVG } from "../static/group.svg";
import { ReactComponent as UserSVG } from "../static/user.svg";
import { title } from "../util/String";
import { AutoCompleteInput, AutoCompleteMatch } from "./AutoComplete";
import ErrorMessage from "./ErrorMessage";
import Icon, { Icons } from "./Icon";
import Loader from "./Loader";
import Picture, { PictureSource } from "./Picture";
import Scrollable from "./Scrollable";
import UserAutoComplete, { mapGroupsToSuggestions, mapUsersToSuggestions } from "./UserGroupAutoComplete";

export interface PathPermissionListProps {
  acl: ACL;
  readOnly: boolean;
}

const PathPermissionList: React.FC<PathPermissionListProps> = ({ acl, readOnly }) => {
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const [assignToSelection, setAssignToSelection] = useState(true);
  const [resolvedIsOpen, setResolvedIsOpen] = useState(true);

  const canGetResolvedACL = usePermission(
    () => acl.storage.commands.allowed<IGetACLResolvedCommand>(Commands.GetResolvedACL),
    [acl]
  );

  const reload = useCallback(() => {
    const getResolveACL = acl.storage.commands.get<IGetACLResolvedCommand>(Commands.GetResolvedACL);
    if (canGetResolvedACL === "allowed" && getResolveACL) {
      getResolveACL.execute({ path: acl.path }).catch((error) => setError(error.message));
    }

    const getAcl = acl.storage.commands.get<IGetACLCommand>(Commands.GetACL);
    if (getAcl) {
      getAcl.execute({ path: acl.path }).catch((error) => setError(error.message));
    }

    const getAllUsers = acl.storage.commands.get<IGetUsersCommand>(Commands.GetUsers);
    if (getAllUsers) {
      getAllUsers.execute({ users: acl.storage.users }).catch((error) => setError(error.message));
    }

    const getAllGroups = acl.storage.commands.get<IGetGroupsCommand>(Commands.GetGroups);
    if (getAllGroups) {
      getAllGroups.execute({ groups: acl.storage.groups }).catch((error) => setError(error.message));
    }
  }, [acl, canGetResolvedACL]);

  useEffect(() => {
    reload();
  }, [reload, acl]);

  const currentUser = acl.storage.session.username;

  const suggestions = mapUsersToSuggestions(acl.storage.users.items)
    .concat(mapGroupsToSuggestions(acl.storage.groups.items))
    .filter(
      (suggestion) =>
        suggestion.data?.name && !acl.keys.includes(suggestion.data.name) && suggestion.data.name !== currentUser
    );

  const valueIsAllowed = useMemo(() => {
    return name && suggestions.some((suggestion) => suggestion.data?.name === name);
  }, [suggestions, name]);

  const addItem = useCallback(() => {
    setName("");
    if (acl.hasOwnProperty(name)) {
      return;
    }

    const setAcl = acl.storage.commands.get<ISetACLCommand>(Commands.SetACL);
    if (setAcl) {
      acl.add(name);
      return setAcl.execute({ path: acl.path, permissions: acl.entries }).then((result) => {
        reload();
        return result;
      });
    }
  }, [acl, name, reload]);

  const togglePermission = useCallback(
    (owner: string, permission: PathPermission) => {
      const permissions = acl.get(owner);
      if (permissions.has(permission)) {
        permissions.delete(permission);
      } else {
        permissions.add(permission);
      }

      if (permission === PathPermission.Read && !permissions.has(PathPermission.Read)) {
        permissions.delete(PathPermission.Write);
        permissions.delete(PathPermission.Admin);
      } else if (permission === PathPermission.Write) {
        if (permissions.has(PathPermission.Write)) {
          permissions.add(PathPermission.Read);
        } else {
          permissions.delete(PathPermission.Admin);
        }
      } else if (permission === PathPermission.Admin && permissions.has(PathPermission.Admin)) {
        permissions.add(PathPermission.Read);
        permissions.add(PathPermission.Write);
      }

      const setAcl = acl.storage.commands.get<ISetACLCommand>(Commands.SetACL);
      if (setAcl) {
        acl.update(owner, permissions);
        return setAcl.execute({ path: acl.path, permissions: acl.entries }).then((result) => {
          reload();
          return result;
        });
      }
    },
    [acl, reload]
  );

  const removeItem = useCallback(
    (owner: string) => {
      const setAcl = acl.storage.commands.get<ISetACLCommand>(Commands.SetACL);
      if (setAcl) {
        acl.remove(owner);
        return setAcl.execute({ path: acl.path, permissions: acl.entries }).then((result) => {
          reload();
          return result;
        });
      }
    },
    [acl, reload]
  );

  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent) => {
      if (["Enter", "Tab"].includes(e.key)) {
        e.stopPropagation();

        if (valueIsAllowed) {
          addItem();
        }
      }
    },
    [valueIsAllowed, addItem]
  );

  return (
    <StyledPathPermissionList>
      {error && <ErrorMessage>{error}</ErrorMessage>}

      <div>
        <ACLHeader>
          <OwnerHeader>
            <Icon
              clickable
              icon={assignToSelection ? Icons.Down : Icons.Right}
              onClick={(e) => setAssignToSelection((state) => !state)}
              title={"Collapse Assigned to Selection"}
            />
            <div>Assigned to Selection</div>
          </OwnerHeader>
          <Control title={"Read"}>R</Control>
          <Control title={"Write"}>W</Control>
          <Control title={"Owner"}>O</Control>
          <Control />
        </ACLHeader>

        {assignToSelection && (
          <>
            {acl.entries.map(([owner, permissions]) => (
              <PathPermissionRow
                key={owner}
                owner={owner}
                permissions={permissions}
                disabled={readOnly || owner === Group.Admin || owner === acl.storage.session.username}
                picture={acl.storage.groups.names.includes(owner) ? GroupSVG : UserSVG}
                onTogglePermission={togglePermission}
                onRemove={removeItem}
              />
            ))}

            {!readOnly && (
              <AutoCompleteContainer>
                <AutoCompleteIcon />
                <UserAutoComplete
                  inputComponent={StyledAutoCompleteInput}
                  placeholder={`Click to assign user/group`}
                  suggestions={suggestions}
                  value={name}
                  onChange={setName}
                  onKeyUp={handleKeyUp}
                />
                <AddIcon disabled={!valueIsAllowed} title={"Add"} onClick={addItem} />
              </AutoCompleteContainer>
            )}

            {acl.loading && <Loader>Loading ACL...</Loader>}
          </>
        )}
      </div>

      {canGetResolvedACL && (
        <div>
          <ACLHeader>
            <OwnerHeader>
              <Icon
                clickable
                icon={resolvedIsOpen ? Icons.Down : Icons.Right}
                onClick={(e) => {
                  setResolvedIsOpen((state) => !state);
                }}
                title={"Collapse Resolved ACLs"}
              />
              <div>Resolved</div>
            </OwnerHeader>
            <Control title={"Read"}>R</Control>
            <Control title={"Write"}>W</Control>
            <Control title={"Owner"}>O</Control>
            <Control />
          </ACLHeader>

          {resolvedIsOpen &&
            (acl.path.resolvedACL.loading ? (
              <Loader>Loading Resolved ACL...</Loader>
            ) : (
              <>
                {Array.from(acl.path.resolvedACL.entries).map(([owner, aclAtLevel]) => (
                  <PathResolvedRow
                    key={owner}
                    owner={owner}
                    aclAtLevel={aclAtLevel}
                    picture={acl.storage.groups.names.includes(owner) ? GroupSVG : UserSVG}
                    onTogglePermission={togglePermission}
                    onRemove={removeItem}
                    path={acl.path}
                  />
                ))}
              </>
            ))}
        </div>
      )}
    </StyledPathPermissionList>
  );
};

const StyledPathPermissionList = styled(Scrollable)`
  display: flex;
  flex-direction: column;
`;

let PathPermissionRow: React.FC<{
  owner: string;
  permissions: PathPermission[];
  picture: PictureSource;
  disabled?: boolean;
  onTogglePermission(owner: string, permission: PathPermission): void;
  onRemove(owner: string): void;
}> = ({ owner, permissions, picture, disabled = false, onTogglePermission, onRemove }) => {
  const toggleRead = useCallback(() => {
    onTogglePermission(owner, PathPermission.Read);
  }, [owner, onTogglePermission]);

  const toggleWrite = useCallback(() => {
    onTogglePermission(owner, PathPermission.Write);
  }, [owner, onTogglePermission]);

  const toggleAdmin = useCallback(() => {
    onTogglePermission(owner, PathPermission.Admin);
  }, [owner, onTogglePermission]);

  const remove = useCallback(() => {
    onRemove(owner);
  }, [owner, onRemove]);

  const canRead = permissions.includes(PathPermission.Read);
  const canWrite = permissions.includes(PathPermission.Write);
  const canAdmin = permissions.includes(PathPermission.Admin);

  return (
    <ACLRow key={owner}>
      <OwnerPicture src={picture} />
      <Owner>
        {owner}
      </Owner>
      <Control>
        <Checkbox
          checked={canRead}
          disabled={disabled}
          entry={owner}
          permission={PathPermission.Read}
          allowed={canRead}
          onClick={toggleRead}
        />
      </Control>
      <Control>
        <Checkbox
          checked={canWrite}
          disabled={disabled}
          entry={owner}
          permission={PathPermission.Write}
          allowed={canWrite}
          onClick={toggleWrite}
        />
      </Control>
      <Control>
        <Checkbox
          checked={canAdmin}
          disabled={disabled}
          entry={owner}
          permission={PathPermission.Admin}
          allowed={canAdmin}
          onClick={toggleAdmin}
        />
      </Control>
      <Control>{!disabled && <RemoveIcon title={`Remove ${owner}`} onClick={remove} />}</Control>
    </ACLRow>
  );
};

PathPermissionRow = observer(PathPermissionRow);

let PathResolvedRow: React.FC<{
  owner: string;
  aclAtLevel: ACLAtLevel;
  picture: PictureSource;
  path: Path;
  onTogglePermission(owner: string, permission: PathPermission): void;
  onRemove(owner: string): void;
}> = ({ owner, aclAtLevel, path, picture, onTogglePermission, onRemove }) => {
  const canRead = aclAtLevel.acl.has(PathPermission.Read);
  const canWrite = aclAtLevel.acl.has(PathPermission.Write);
  const canAdmin = aclAtLevel.acl.has(PathPermission.Admin);
  const navigate = usePathNavigate();

  const inheritedFrom =
    aclAtLevel.inheritedFromPath !== path.path &&
    path.parents.find((path) => path.path === aclAtLevel.inheritedFromPath);

  return (
    <ACLResolvedRow>
      <OwnerPicture src={picture} />

      <ACLResolvedContent>
        <ResolvedMainRow>
          <Owner>
            {owner} {aclAtLevel.inheritedFromGroup && `(${aclAtLevel.inheritedFromGroup})`}
          </Owner>
          <Control>
            <Checkbox checked={canRead} disabled entry={owner} permission={PathPermission.Read} allowed={canRead} />
          </Control>
          <Control>
            <Checkbox checked={canWrite} disabled entry={owner} permission={PathPermission.Write} allowed={canWrite} />
          </Control>
          <Control>
            <Checkbox checked={canAdmin} disabled entry={owner} permission={PathPermission.Admin} allowed={canAdmin} />
          </Control>
          <Control />
        </ResolvedMainRow>

        <ACLDescription>
          <SubTitle>
            {aclAtLevel.inheritedFromPath !== path.path
              ? `Inherited from ${aclAtLevel.inheritedFromPath}`
              : `Assigned to Selection`}
          </SubTitle>
          <InheritedFrom>
            {inheritedFrom && (
              <StyledIcon
                title={`Open ${inheritedFrom.path}`}
                icon={Icons.ShareSquare}
                onClick={(e) => {
                  navigate(inheritedFrom);
                }}
              />
            )}
          </InheritedFrom>
          <Control />
        </ACLDescription>
      </ACLResolvedContent>
    </ACLResolvedRow>
  );
};

PathResolvedRow = observer(PathResolvedRow);

const ACLResolvedRow = styled.div`
  display: flex;
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.inputText};
  padding: 5px;
`;

const ACLDescription = styled.div`
  display: flex;
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.inputText};
  overflow: hidden;
`;

const ACLResolvedContent = styled.div`
  flex: 1;
`;

const ACLRow = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 5px;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.inputText};
`;

const ResolvedMainRow = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.inputText};
`;

const SubTitle = styled.div`
  flex: 1;
  font-size: 9pt;
  color: ${({ theme }) => theme.colors.placeholders};
  word-break: break-all;
`;

const Owner = styled.div`
  font-size: 10pt;
  flex: 1;
  vertical-align: middle;
  word-break: break-all;
`;

const ACLHeader = styled(ACLRow)`
  font-size: 9pt;
  background: ${({ theme }) => theme.colors.frontBackground};
  color: ${({ theme }) => theme.colors.foreground};
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

const OwnerHeader = styled(Owner)`
  font-size: 9pt;
  display: flex;
  align-items: center;
`;

const OwnerPicture = styled(Picture)`
  width: 20px;
  margin-right: 5px;
  vertical-align: middle;
  fill: ${({ theme }) => theme.colors.inputText};
  flex: 0 0 20px;
`;

const Control = styled.div`
  flex: 0 0 25px;
  text-align: center;
  cursor: default;
`;

const RemoveIcon = styled(Icon).attrs({ icon: Icons.Remove, clickable: true })`
  color: ${({ theme }) => theme.colors.errorText};
`;

const InheritedFrom = styled(Control)`
  font-size: 11pt;

  i {
    margin-left: 3px;
  }
`;

const AutoCompleteContainer = styled.div`
  position: relative;
`;

const AutoCompleteIcon = styled(GroupSVG)`
  width: 20px;
  margin-right: 5px;
  vertical-align: middle;
  position: absolute;
  top: 3px;
  left: 5px;
  opacity: 0.5;
  z-index: 1;
  fill: ${({ theme }) => theme.colors.placeholders};
`;

const AddIcon = styled(Icon).attrs({ icon: Icons.Add, clickable: true })`
  position: absolute;
  right: 8px;
  top: 5px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  color: ${({ disabled, theme }) => (disabled ? theme.colors.placeholders : theme.colors.successBackground)};
`;

const StyledAutoCompleteInput = styled(AutoCompleteInput)`
  border: none;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.background};
  outline: none;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 10pt;

  &::placeholder {
    font-size: 10pt;
  }
`;

const StyledAutoCompleteMatch = styled(AutoCompleteMatch)`
  position: sticky;
`;

const StyledIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.inputText};
  filter: ${({ disabled }) => (disabled ? "opacity(50%)" : "none")};
`;

const Checkbox: React.FC<
  Omit<React.ComponentProps<typeof StyledIcon>, "icon"> & {
    disabled: boolean;
    checked: boolean;
    entry: string;
    permission: string;
    allowed: boolean;
  }
> = ({ checked, entry, permission, allowed, disabled = false, ...props }) => {
  const iconTitle = title(
    disabled
      ? allowed
        ? `${permission} permission is allowed for ${entry}`
        : `${permission} permission is denied for ${entry}`
      : allowed
      ? `Remove ${permission} permission from ${entry}`
      : `Add ${permission} permission to ${entry}`
  );

  return (
    <StyledIcon
      aria-checked={checked}
      tabIndex={0}
      icon={checked ? Icons.Checked : Icons.Empty}
      title={iconTitle}
      disabled={disabled}
      clickable={true}
      {...props}
    />
  );
};

export default observer(PathPermissionList);
