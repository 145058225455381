import React from "react";
import useDialogForm from "../hooks/useDialogForm";
import { Checkpoint } from "../state/Checkpoints";
import { Commands } from "../state/commands/Provider";
import { IDeleteCheckpointsCommand } from "../state/commands/types/DeleteCheckpointsCommand";
import Path from "../state/Path";
import ConfirmationDialog from "./ConfirmationDialog";
import ImportantText from "./ImportantText";
import { observer } from "mobx-react";
import { formatFileSize } from "../util/File";
import Warning from "./Warning";

export interface CheckpointDeleteOlderThanDialogProps {
  path: Path;
  checkpoint: Checkpoint;
  olderCheckpoints: Checkpoint[];
}

const CheckpointDeleteOlderThanDialog: React.FC<CheckpointDeleteOlderThanDialogProps> = ({
  path,
  olderCheckpoints,
}) => {
  const form = useDialogForm({
    fields: {},
    onSubmit: confirm,
  });

  const firstCheckpoint = olderCheckpoints.reduce((a, b) => {
    return a.date?.getTime() > b.date?.getTime() ? a : b;
  });

  const dataSize = olderCheckpoints.reduce((acc, val) => acc + (val.size ?? 0), 0);

  async function confirm() {
    const command = path.storage.commands.get<IDeleteCheckpointsCommand>(Commands.DeleteCheckpoints);
    if (command) {
      return command.execute({ path, checkpoints: olderCheckpoints });
    }
  }

  return (
    <ConfirmationDialog
      title={"Delete Checkpoints"}
      loading={form.loading}
      onConfirm={form.submit}
      onCancel={form.cancel}
    >
      Do you really want to permanently delete <br />
      Checkpoint #{firstCheckpoint.id} and all older ones with
      <br />
      <ImportantText>size of {formatFileSize(dataSize)}</ImportantText>
      <Warning>This action cannot be undone.</Warning>
    </ConfirmationDialog>
  );
};

export default observer(CheckpointDeleteOlderThanDialog);
