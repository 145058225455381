import { observer } from "mobx-react";
import React, { useMemo } from "react";
import styled from "styled-components";
import useTreeItemEvent from "../hooks/useTreeItemEvent";
import Nucleus from "../state/commands/nucleus/Nucleus";
import Path from "../state/Path";
import NewServerTreeItem from "./NewServerTreeItem";
import { PathTreeItemType } from "./PathTree";
import PathTreeIcon from "./PathTreeIcon";
import PathTreeRoot from "./PathTreeRoot";
import TreeItem from "./TreeItem";
import TreeItemName from "./TreeItemName";
import TreeLock from "./TreeLock";
import TreeSpinner from "./TreeSpinner";
import TreeToggle from "./TreeToggle";

export interface PathTreeItemProps<T extends PathTreeItemType = PathTreeItemType> {
  item: T;
  selected?: boolean;
  highlighted?: boolean;
  draggable?: boolean;
  style?: React.CSSProperties;
  onSelect?(e: React.MouseEvent & PathTreeItemEvent): void;
  onToggle?(e: React.MouseEvent & PathTreeItemEvent): void;
  onDragStart?(e: React.DragEvent & PathTreeItemEvent): void;
  onDragEnd?(e: React.DragEvent & PathTreeItemEvent): void;
  onDragOver?(e: React.DragEvent & PathTreeItemEvent): void;
  onDragEnter?(e: React.DragEvent & PathTreeItemEvent): void;
  onDragLeave?(e: React.DragEvent & PathTreeItemEvent): void;
  onDrop?(e: React.DragEvent & PathTreeItemEvent): void;
  onMouseEnter?(e: React.MouseEvent & PathTreeItemEvent): void;
  onMouseLeave?(e: React.MouseEvent & PathTreeItemEvent): void;
  onMouseUp?(e: React.MouseEvent & PathTreeItemEvent): void;
  onContextMenu?(e: React.MouseEvent & PathTreeItemEvent): void;
}

export type PathTreeItemEvent = {
  item: PathTreeItemType;
};

const PathTreeItem: React.FC<PathTreeItemProps> = ({ item, ...props }) => {
  if (item.type === "root") {
    return <PathTreeRoot item={item} {...props} />;
  }
  if (item.type === "new-server") {
    return <NewServerTreeItem item={item} {...props} />;
  }
  return <PathItem item={item} {...props} />;
};

let PathItem: React.FC<PathTreeItemProps<Path>> = ({
  item: path,
  selected = false,
  highlighted = false,
  draggable = false,
  style,
  onSelect,
  onToggle,
  onContextMenu,
  onDragStart,
  onDragEnd,
  onDragOver,
  onDragEnter,
  onDragLeave,
  onDrop,
  onMouseEnter,
  onMouseLeave,
  onMouseUp,
}) => {
  const handleClick = useTreeItemEvent(onSelect, path);
  const handleToggle = useTreeItemEvent(onToggle, path);
  const handleContextMenu = useTreeItemEvent(onContextMenu, path);
  const handleDragStart = useTreeItemEvent(onDragStart, path);
  const handleDragEnd = useTreeItemEvent(onDragEnd, path);
  const handleDragOver = useTreeItemEvent(onDragOver, path);
  const handleDragLeave = useTreeItemEvent(onDragLeave, path);
  const handleDragEnter = useTreeItemEvent(onDragEnter, path);
  const handleDrop = useTreeItemEvent(onDrop, path);
  const handleMouseEnter = useTreeItemEvent(onMouseEnter, path);
  const handleMouseLeave = useTreeItemEvent(onMouseLeave, path);
  const handleMouseUp = useTreeItemEvent(onMouseUp, path);

  const level = useMemo(() => {
    let slashes = 0;
    for (const char of path.path) {
      if (char === "/") {
        slashes++;
      }
    }
    return slashes;
  }, [path]);

  return (
    <TreeItem
      level={level}
      style={style}
      selected={selected}
      highlighted={highlighted}
      draggable={draggable}
      onClick={handleClick}
      onContextMenu={handleContextMenu}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
    >
      {path.loading ? (
        <TreeSpinner />
      ) : (
        <TreeToggle
          title={path.opened ? `Close ${path.name}` : `Open ${path.name}`}
          opened={path.opened}
          onClick={handleToggle}
        />
      )}
      {!path.isRoot && !path.canWrite() && <TreeLock title={"Read-only"} />}
      {path.isRoot && path.storage.provider instanceof Nucleus && path.storage.provider.session.isNucleusReadOnly && (
        <TreeLock title={"You have Read-only access to this server"} />
      )}
      <PathTreeIcon path={path} />

      <TreeItemName>
        {path.name}{" "}
        {path.isRoot ? (
          path.storage.session.established ? (
            <Username title={path.storage.session.username}>({path.storage.session.username})</Username>
          ) : (
            <Disconnected>(disconnected)</Disconnected>
          )
        ) : (
          ""
        )}
      </TreeItemName>
    </TreeItem>
  );
};
PathItem = observer(PathItem);

const Username = styled.span``;

const Disconnected = styled.span`
  color: ${({ theme }) => theme.colors.errorText};
`;

export default observer(PathTreeItem);
