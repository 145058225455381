import React from "react";
import styled from "styled-components";
import User from "../state/User";
import { ReactComponent as UserSVG } from "../static/user.svg";
import {AutoCompleteInput} from "./AutoComplete";
import { Icons } from "./Icon";
import List, { ListInput, ListInputProps, ListItem, ListProps } from "./List";
import { DefaultTheme } from "./Theme";
import UserAutoComplete, { mapUsersToSuggestions } from "./UserGroupAutoComplete";

export interface GroupUsersProps extends Omit<ListProps, "suggestions"> {
  suggestions?: User[];
}

const GroupUsers: React.FC<GroupUsersProps> = ({ suggestions: users = [], ...props }) => {
  return (
    <List
      itemComponent={GroupUser}
      inputComponent={GroupUsersInput}
      suggestions={mapUsersToSuggestions(users)}
      {...props}
    />
  );
};

const GroupUserImage = styled(UserSVG)`
  height: 20px;
  margin-right: 10px;
  fill: ${({ theme }) => theme.colors.inputText};
`;
GroupUserImage.defaultProps = {
  theme: DefaultTheme,
};

const GroupUsersInputImage = styled(GroupUserImage)`
  filter: opacity(0.5);
  z-index: 1;
  pointer-events: none;
`;

const GroupUser = styled(ListItem).attrs({
  image: GroupUserImage,
})``;

const GroupUsersInput: React.FC<ListInputProps> = (props) => {
  return (
    <StyledGroupUsersInput
      icon={Icons.Add}
      image={GroupUsersInputImage}
      placeholder={"Add User"}
      allowAny={false}
      autoCompleteComponent={StyledUserAutoComplete}
      {...props}
    />
  );
};

const StyledGroupUsersInput = styled(ListInput)`
  height: 30px;
  box-sizing: border-box;
`;

const StyledUserAutoCompleteInput = styled(AutoCompleteInput)`
  padding: 0;
  outline: none;
`;

const StyledUserAutoComplete = styled(UserAutoComplete).attrs({
  inputComponent: StyledUserAutoCompleteInput
})`
  flex: 1;
`;

export default GroupUsers;
