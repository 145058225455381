import { observer } from "mobx-react";
import React from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import usePreference from "../hooks/usePreference";
import { Preferences } from "../state/Preferences";

export let ThemeProvider: React.FC = ({ children }) => {
  const [themeName] = usePreference(Preferences.Theme, "dark");
  const theme = themes[themeName] ?? DefaultTheme;
  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
};
ThemeProvider = observer(ThemeProvider);

type ThemeRuleValue = string | null;

declare module "styled-components" {
  export interface DefaultTheme extends Theme {}
}

export interface Theme {
  colors: ThemeColors;
  shadows: ThemeShadows;
}

export interface ThemeColors {
  background: Color;
  frontBackground: Color;
  foreground: Color;
  selectedItemForeground: Color;
  selectedItemBackground: Color;
  selectedItemHover: Color;
  hoverItemBackground: Color;
  scrollBar: Color;
  scrollBarBackground: Color;
  border: Color;
  selectedItemBorder: Color;
  placeholders: Color;
  hint: Color;
  importantText: Color;
  shadow: Color;
  inputBackground: Color;
  inputText: Color;
  buttonBackground: Color;
  buttonText: Color;
  toolbarBackground: Color;
  toolbarForeground: Color;
  toolbarDisabledBackground: Color;
  toolbarDisabledText: Color;
  toolbarSelectedBackground: Color;
  toolbarSelectedForeground: Color;
  toolbarPlaceholder: Color;
  toolbarBorder: Color;
  errorBackground: Color;
  errorText: Color;
  dangerBackground: Color;
  dangerText: Color;
  successBackground: Color;
  successText: Color;
  attentionBackground: Color;
  attentionText: Color;
  warningBackground: Color;
  warningText: Color;
  headerBackground: Color;
  headerForeground: Color;
  disabledBackground: Color;
  disabledText: Color;
  selectedBreadcrumb: Color;
  selectedBreadcrumbHover: Color;
  icon: Color;
  selectedIcon: Color;
  interaction: Color;
  panelBackground: Color;
  panelText: Color;
  menuBackground: Color;
  menuText: Color;
  menuSeparator: Color;
  menuHover: Color;
}

export type Color = string;

export interface ThemeShadows {
  default: ThemeRuleValue;
  light: ThemeRuleValue;
  highlighting: ThemeRuleValue;
}

export const DefaultTheme: Theme = {
  colors: {
    background: "#e0e0e0",
    frontBackground: "#e6e6e6",
    foreground: "#6e6e6e",
    selectedItemBackground: "#bcd4d9",
    selectedItemForeground: "#0b758c",
    selectedItemHover: "#b1c8cc",
    hoverItemBackground: "#d5d5d5",
    scrollBar: "#f2f2f2",
    scrollBarBackground: "#e0e0e0",
    border: "#bbbbbb",
    selectedItemBorder: "#2d8c9f",
    placeholders: "#bbb",
    hint: "#999",
    importantText: "#262626",
    shadow: "#555",
    inputBackground: "#fff",
    inputText: "#606060",
    buttonBackground: "#e8e8e8",
    buttonText: "#666",
    toolbarBackground: "#323639",
    toolbarForeground: "#e0e0e0",
    toolbarDisabledBackground: "#484d52",
    toolbarDisabledText: "#666",
    toolbarSelectedBackground: "#99cad4",
    toolbarSelectedForeground: "#0b758c",
    toolbarBorder: "#666",
    toolbarPlaceholder: "#666",
    errorBackground: "#ffcccc",
    errorText: "#d46a6a",
    dangerBackground: "#d46a6a",
    dangerText: "#eee",
    successBackground: "#71a376",
    successText: "#eee",
    attentionBackground: "#76b900",
    attentionText: "#eee",
    warningBackground: "#d4b51c",
    warningText: "#eee",
    headerBackground: "#323639",
    headerForeground: "#eee",
    disabledBackground: "#e0e0e0",
    disabledText: "#bbb",
    selectedBreadcrumb: "#0b758c",
    selectedBreadcrumbHover: "#0b6077",
    icon: "#6e6e6e",
    selectedIcon: "#2d8c9f",
    interaction: "#57a4e8",
    panelBackground: "#bcd4d9",
    panelText: "#0b758c",
    menuBackground: "#e6e6e6",
    menuText: "#6e6e6e",
    menuHover: "#cbcbcb",
    menuSeparator: "#bbbbbb",
  },
  shadows: {
    default: "0 0 10px #555",
    light: "0 0 10px -3px #555",
    highlighting: "0 0 10px -2px #0b758c",
  },
};

export const DarkTheme: Theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: "#363636",
    frontBackground: "#464646",
    foreground: "#cccccc",
    headerBackground: "#363636",
    headerForeground: "#cccccc",
    inputBackground: "#212121",
    inputText: "#ccc",
    buttonBackground: "#424242",
    buttonText: "#cccccc",
    placeholders: "#9e9e9e",
    icon: "#646464",
    scrollBarBackground: "#363636",
    scrollBar: "#989898",
    border: "#252525",
    selectedItemBackground: "#5a5a5a",
    selectedItemForeground: "#cccccc",
    selectedBreadcrumb: "#5a5a5a",
    selectedItemHover: "#5a5a5a",
    selectedIcon: "#d4d4d4",
    toolbarSelectedBackground: "#5a5a5a",
    toolbarBackground: "#212121",
    toolbarForeground: "#cccccc",
    toolbarDisabledBackground: "#212121",
    toolbarDisabledText: "#666",
    toolbarBorder: "#212121",
    toolbarPlaceholder: "#646464",
    hoverItemBackground: "#454545",
    shadow: "#111",
    errorBackground: "#542a2a",
    errorText: "#d46a6a",
    dangerBackground: "#d46a6a",
    dangerText: "#eee",
    successBackground: "#71be76",
    successText: "#eee",
    disabledBackground: "#2d2d2d",
    disabledText: "#666",
    importantText: "#e0e0e0",
    selectedItemBorder: "#d4d4d4",
    toolbarSelectedForeground: "#d4d4d4",
    panelBackground: "#212121",
    panelText: "#cccccc",
    menuBackground: "#464646",
    menuText: "#cccccc",
    menuHover: "#666666",
    menuSeparator: "#333",
  },
  shadows: {
    default: "0 0 10px -1px #111",
    light: "0 0 10px -3px #111",
    highlighting: "0 0 10px -2px #111",
  },
};

export interface ThemedComponentProps {
  theme?: Theme;
}

const themes: { [name: string]: Theme } = {
  dark: DarkTheme,
  light: DefaultTheme,
};
