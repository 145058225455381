import { StatusType } from "@omniverse/api/data";
import { ManageGroupsError } from "../../../util/GroupErrors";
import { Commands } from "../Provider";
import { IDeleteGroupCommandAllowedArguments, IDeleteGroupCommandArguments } from "../types/DeleteGroupCommand";
import { NucleusCommand } from "./index";

export default class NucleusDeleteGroupCommand extends NucleusCommand<
  IDeleteGroupCommandArguments,
  IDeleteGroupCommandAllowedArguments
> {
  public name = Commands.DeleteGroup;

  public async allowed({ group }: IDeleteGroupCommandAllowedArguments): Promise<boolean> {
    return Boolean(this.provider.session.isSuperUser && !group.readonly);
  }

  public async execute({ group }: IDeleteGroupCommandArguments): Promise<void> {
    console.log(`[${this.provider.name}] Delete group "${group.name}"`);

    const connection = await this.provider.getConnection();
    const result = await connection.removeGroup({ group_name: group.name });
    if (result.status !== StatusType.OK) {
      throw new ManageGroupsError(result);
    }

    group.storage.groups.delete(group);
  }
}
