import styled from "styled-components";

const Input = styled.input`
  display: block;
  font-family: inherit;
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  padding: 5px 15px;
  border-radius: 3px;
  border: none;
  background: ${({ disabled }) => (disabled ? "#a5a5a5" : "#e0e0e0")};
  color: #6e6e6e;
  z-index: 1;

  &::placeholder {
    color: ${({ disabled }) => (disabled ? "#909090" : "#bbb")};
  }
`;

export default Input;
