import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { isFolder, traverse, traverseMap } from "../util/Path";
import { CommandRegistry, IProvider, ISession, parseProvider } from "./commands/Provider";
import Groups from "./Groups";
import Path, { PathType } from "./Path";
import PathClipboard from "./PathClipboard";
import PathUploadList from "./PathUploadList";
import Selection from "./Selection";
import Users from "./Users";
import APITokens from "./APITokens";

class Storage {
  public root: Path;
  public cwd: Path | null;

  public readonly groups: Groups;
  public readonly users: Users;
  public readonly apiTokens: APITokens;
  public readonly selectedPaths: Selection<Path>;
  public readonly uploads: PathUploadList;
  public readonly clipboard: PathClipboard;

  constructor(public readonly provider: IProvider) {
    this.root = new Path("/", PathType.Folder, this);
    this.cwd = null;
    this.groups = new Groups(this);
    this.users = new Users(this);
    this.apiTokens = new APITokens(this);
    this.selectedPaths = new Selection<Path>();
    this.uploads = new PathUploadList();
    this.clipboard = new PathClipboard(this);

    makeObservable<Storage, "setCwd">(this, {
      root: observable,
      cwd: observable,
      uploads: observable,
      provider: observable,
      name: computed,
      busy: computed,
      publicName: computed,
      tree: computed,
      treeFolders: computed,
      paths: computed,
      init: action.bound,
      cd: action.bound,
      setCwd: action.bound,
      clear: action.bound,
    });
  }

  public get busy(): boolean {
    return Boolean(this.provider.busy);
  }

  public get name(): string {
    return this.provider.name;
  }

  public get publicName(): string {
    return this.provider.publicName;
  }

  public get session(): ISession {
    return this.provider.session;
  }

  public get commands(): CommandRegistry {
    return this.provider.commands;
  }

  public get paths(): Path[] {
    function traverse(path: Path): Path[] {
      let results: Path[] = [path];
      for (const child of path.children) {
        results = results.concat(traverse(child));
      }
      return results;
    }

    return traverse(this.root);
  }

  /**
   * Returns all paths opened in a tree.
   */
  public get tree(): Path[] {
    function traverse(path: Path): Path[] {
      let results: Path[] = [path];
      if (path.opened) {
        for (const child of path.children) {
          results = results.concat(traverse(child));
        }
      }
      return results;
    }

    return traverse(this.root);
  }

  /**
   * Returns all folders opened in a tree.
   */
  public get treeFolders(): Path[] {
    return this.tree.filter((path) => path.type === PathType.Folder || path.type === PathType.Mount);
  }

  public static fromJSON(data: any): Storage {
    const provider = parseProvider(data.name, data.provider);
    const storage = new Storage(provider);

    if (data.root) {
      storage.root = Path.fromJSON(data.root, storage);
    }

    storage.cwd = data.cwd ? storage.get(data.cwd.path) : null;
    return storage;
  }

  public async init(): Promise<void> {
    await this.provider.init?.();

    if (this.provider.getRoot) {
      const root = await this.provider.getRoot(this);
      runInAction(() => {
        if (this.root) {
          this.root.merge(root);
        } else {
          this.root = root;
        }
      });
    }
  }

  public close(): void {
    this.provider.close?.();
  }

  public get(path: string): Path | null {
    if (!path || path === "/") {
      return this.root;
    }

    let cached: Path = this.root;
    let result = traverse(path, (current) => {
      if (current === "/") {
        cached = this.root;
      } else if (cached.childrenMap.has(current)) {
        cached = cached.childrenMap.get(current)!;
      } else {
        return null;
      }

      if (cached.path === path) {
        return cached;
      }
    });

    return result ?? null;
  }

  public async cd(path: string): Promise<void> {
    let searched = this.get(path);
    if (searched) {
      this.setCwd(searched);

      let path: Path | null = this.cwd;
      let tasks = [];
      while (path) {
        tasks.push(path.toggle({ opened: true }));
        path = path.parent;
      }
      await Promise.all(tasks);
    } else {
      let parent: Path | null = null;
      const loadingParent = traverseMap(path, (current) => {
        if (current === path || !isFolder(current)) {
          return Promise.resolve();
        }

        let currentPath = this.get(current);
        if (!currentPath) {
          currentPath = new Path(current, PathType.Folder, this);
          currentPath.parent = parent;
          if (parent) {
            parent.mergeChildren([currentPath]);
          }
        }

        parent = currentPath;
        return currentPath.toggle({ opened: true });
      });

      await Promise.all(loadingParent);

      searched = this.get(path);
      if (searched) {
        this.setCwd(searched);
        await searched.toggle({ opened: true });
      } else {
        throw new Error(`${path} does not exist on ${this.publicName}.`);
      }
    }
  }

  protected setCwd(path: Path): void {
    this.cwd = path.type === PathType.Folder || path.type === PathType.Mount ? path : path.parent!;
    this.selectedPaths.setDefault(this.cwd);
    this.selectedPaths.select(path);
  }

  public clear(): void {
    this.cwd = this.root;
    this.root.mergeChildren([]);
  }

  public toJSON() {
    return {
      name: this.name,
      cwd: this.cwd?.toJSON(),
      root: this.root.toJSON(),
      provider: this.provider.toJSON(),
    };
  }

  protected select(path: Path) {
    this.cwd = path;
    this.selectedPaths.setDefault(path);
    this.selectedPaths.select(path);
  }
}

export default Storage;
