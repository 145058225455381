import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useStateOrProp } from "../hooks/useStateOrProp";
import AutoComplete, {
  AutoCompleteContainer,
  AutoCompleteInput,
  AutoCompleteMatch,
  AutoCompleteProps,
} from "./AutoComplete";
import Tag, { TagProps } from "./Tag";
import { DefaultTheme } from "./Theme";

export interface TagAutoCompleteProps {
  autocompleteComponent?: React.ComponentType<AutoCompleteProps>;
  className?: string;
  defaultText?: string;
  disabled?: boolean;
  id?: string;
  name?: string;
  placeholder?: string;
  readOnly?: boolean;
  scrollableArea?: React.ComponentType<TagAutoCompleteScrollableAreaProps>;
  spellCheck?: boolean;
  suggestions?: string[];
  tagComponent?: React.ComponentType<TagProps>;
  text?: string;
  value: string[];
  wrapTextOnBlur?: boolean;
  onBlur?(e: React.FocusEvent<HTMLInputElement>): void;
  /**
   * Occurs when something inside the component loses focus.
   */
  onBlurOut?(e: React.FocusEvent<HTMLDivElement>): void;
  onChange(value: string[], text?: string): void;
  onClick?(e: React.MouseEvent<HTMLDivElement>): void;
  onFocus?(e: React.FocusEvent<HTMLInputElement>): void;
  /**
   * Occurs when something is focused inside the component container.
   */
  onFocusIn?(e: React.FocusEvent<HTMLDivElement>): void;
  onKeyDown?(e: React.KeyboardEvent<HTMLInputElement>): void;
  onTextChange?(text: string): void;
  after?: React.ReactNode;
  before?: React.ReactNode;
  children?: React.ReactNode;
}

export type TagAutoCompleteScrollableAreaProps = React.ComponentPropsWithRef<"div"> & {
  disabled?: boolean;
};

const TagAutoComplete: React.FC<TagAutoCompleteProps> = ({
  autocompleteComponent: TagAutoCompleteComponent = StyledTagAutoComplete,
  className,
  defaultText = "",
  disabled = false,
  id,
  name,
  placeholder,
  readOnly = false,
  scrollableArea: ScrollableArea = TagAutoCompleteScrollableArea,
  spellCheck,
  suggestions = [],
  tagComponent: TagComponent = TagItem,
  text: textProp,
  value: tags,
  wrapTextOnBlur = true,
  onBlur,
  onBlurOut,
  onChange,
  onClick,
  onFocus,
  onFocusIn,
  onKeyDown,
  onTextChange,
  after,
  before,
  children,
  ...props
}) => {
  const container = useRef<HTMLDivElement>(null);
  const scrollableArea = useRef<HTMLDivElement>(null);
  const input = useRef<HTMLInputElement>(null);

  const [focused, setFocused] = useState(false);
  const [text, setText] = useStateOrProp(textProp, onTextChange, defaultText);
  useEffect(() => {
    if (input.current) {
      input.current.style.width = text ? `calc(${text.length}ch * 2)` : placeholder ? "100%" : "auto";
    }
  }, [placeholder, text]);

  useEffect(() => {
    if (!scrollableArea.current || !input.current) {
      return;
    }

    if (focused) {
      input.current.scrollIntoView?.({ block: "start" });
    } else {
      scrollableArea.current.scrollLeft = 0;
    }
  }, [focused]);

  const filteredSuggestions = useMemo(
    () =>
      suggestions.filter((suggestion) => tags.indexOf(suggestion) === -1).map((suggestion) => ({ value: suggestion })),
    [tags, suggestions]
  );

  const wrapTag = useCallback(
    (value: string) => {
      value = value.trim();
      if (value) {
        onChange([...tags, ...value.split(",").map((tag) => tag.trim())]);
      }
      setText("");
    },
    [tags, onChange, setText]
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setFocused(false);
      if (onBlur) {
        onBlur(e);
      }

      if (!e.defaultPrevented && wrapTextOnBlur) {
        wrapTag(text);
      }
    },
    [text, onBlur, wrapTag, wrapTextOnBlur]
  );

  const handleChange = useCallback(
    (value: string, suggestion?: string) => {
      if (disabled) {
        return;
      }

      if (suggestion) {
        wrapTag(value);
      } else {
        setText(value);
      }
    },
    [disabled, setText, wrapTag]
  );

  const handleFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setFocused(true);
      if (onFocus) {
        onFocus(e);
      }
    },
    [onFocus]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (disabled) {
        return;
      }

      onKeyDown?.(e);
      if (e.defaultPrevented) {
        return;
      }

      if (e.key === "Enter") {
        if (wrapTextOnBlur) {
          wrapTag(text);
        } else {
          onChange(tags, text);
        }
      } else if (e.key === "Backspace" && e.currentTarget.selectionStart === 0) {
        const currentTag = tags[tags.length - 1];
        if (currentTag) {
          onChange(tags.slice(0, tags.length - 1), text);
        }
      } else if (e.key === "Home" && e.currentTarget.selectionStart === 0) {
        scrollableArea.current!.scrollLeft = 0;
      } else if (e.key === "End") {
        scrollableArea.current!.scrollLeft = scrollableArea.current!.scrollWidth;
      }
    },
    [disabled, tags, text, wrapTag, wrapTextOnBlur, onChange, onKeyDown]
  );

  const focus = useCallback(() => input.current!.focus(), []);

  const removeTag = useCallback((removedTag: string) => onChange(tags.filter((tag) => tag !== removedTag)), [
    tags,
    onChange,
  ]);

  return (
    <TagAutoCompleteContainer
      ref={container}
      className={className}
      onClick={focus}
      onFocus={onFocusIn}
      onBlur={onBlurOut}
      {...props}
    >
      {before}

      <ScrollableArea ref={scrollableArea} disabled={disabled}>
        {tags.length > 0 && (
          <Tags>
            {tags.map((tag) => (
              <TagItem key={tag} tag={tag} disabled={disabled} hideDelete={readOnly} onDelete={removeTag} />
            ))}
          </Tags>
        )}

        {children}

        <TagAutoCompleteComponent
          ref={input}
          disabled={disabled}
          id={id}
          name={name}
          placeholder={tags.length > 0 ? "" : placeholder}
          readOnly={readOnly}
          spellCheck={spellCheck}
          suggestions={filteredSuggestions}
          tabIndex={0}
          value={text}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
        />
      </ScrollableArea>

      {after}
    </TagAutoCompleteContainer>
  );
};

export const TagAutoCompleteContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background: ${({ theme }) => theme.colors.inputBackground};
  padding: 0 8px;
  height: 25px;
  box-sizing: border-box;
`;
TagAutoCompleteContainer.defaultProps = {
  theme: DefaultTheme,
};

export const TagAutoCompleteScrollableArea = styled.div<{
  disabled: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex: 1;
  overflow: hidden;
  cursor: ${({ disabled }) => (disabled ? "default" : "text")};
`;
TagAutoCompleteScrollableArea.defaultProps = {
  theme: DefaultTheme,
};

const Tags = styled.div`
  display: inline-flex;
  flex: 0 0 auto;
  min-width: 0;
  gap: 5px;
`;

export const TagItem = styled(Tag)`
  &:last-child {
    margin-right: 5px;
  }
`;

export const TagInput = styled(AutoCompleteInput)`
  border: none;
  outline: none;
  padding: 0;
  font-size: 10pt;
  width: auto;
  flex: 0 0 auto;

  &:focus::placeholder {
    color: transparent;
  }

  &:placeholder-shown {
    flex: 1;
    width: 100%;
  }
`;

export const TagAutoCompleteMatch = styled(AutoCompleteMatch)`
  max-width: 200px;
  width: auto;
  background: ${({ theme }) => theme.colors.toolbarBackground};
  color: ${({ theme }) => theme.colors.toolbarForeground};
  float: left;
`;

export const TagInputContainer = styled(AutoCompleteContainer)`
  flex: ${({ placeholder }) => (placeholder ? "1" : "0 0 auto")};
`;

export const StyledTagAutoComplete = styled(AutoComplete).attrs({
  containerComponent: TagInputContainer,
  matchComponent: TagAutoCompleteMatch,
  inputComponent: TagInput,
})`
  height: 23px;
`;

export default TagAutoComplete;
